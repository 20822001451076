<div class="container-fluid select-template-container"> 
    <div class="row">
        <div class="col-md-6 data-entry-header">Select a {{this.dataText}}</div>
        <div class="col-md-6 text-right">
            <button class="btn btn-default data-entry-cancel" (click)="redirectToDataEntryList()"> Cancel </button>
            <button class="btn btn-success data-entry-btn" (click)="redirectToIntakeForm()" [disabled]="!selectedForm">
                Continue
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 data-entry-icon-row">
            <img src="assets/img/carbon-design-data-entry.svg" class="data-entry-icon" />
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 data-entry-icon-text">
            Create a new {{this.dataText}} by first choosing a form template below
        </div>
        <div class="col-md-3"></div>
    </div>
    
    <div class="row data-entry-row">
        <div class="col-md-3"></div>
        <div class="col-md-2 data-entry-label">
            <span>Select a form template:</span>
        </div>
        <div class="col-md-4">
            <div class="data-entry-input">
                <ng-select 
                            [items]="intakeForms" 
                            bindLabel="name" bindValue="id"
                            [(ngModel)]="selectedForm"
                            placeholder="Select a template">
                </ng-select>
                <br/>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>