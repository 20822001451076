import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StixConfigService } from '../shared/services/stixConfig.service';
import { ProcessDefinition, WorkflowService } from '../shared/services/workflow.service';
import { RivirHttp } from "../shared/services/rivir-http.service";

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.css']
})
export class DataEntryComponent implements OnInit {

  intakeForms: ProcessDefinition[];
  selectedForm: string;
  dataText: string;

  constructor(
    private workflowService: WorkflowService,
    private router: Router,
    public stixConfigService: StixConfigService
  ) { }

  async ngOnInit(): Promise<void> {
    this.dataText = this.stixConfigService.dataEntry_singular;
    this.intakeForms = await this.workflowService.getIntakeForms();
  }

  redirectToIntakeForm() : void{
    sessionStorage.removeItem("rivir_api_token")
    sessionStorage.setItem( "rivir_api_token", RivirHttp.rivirApiToken)
    const dataIntakeForm = this.intakeForms.find(form => form.id == this.selectedForm);
    this.router.navigate([`/data-entry/${dataIntakeForm.name}`]);
  }

  redirectToDataEntryList() : void {
    this.router.navigate(["/data-entry-list"]);
  }

}
