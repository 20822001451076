<div class="row">
  <div class="col-sm-4 m-t-xl tree-view">
    <div ui-jq="nestable" class="dd" style="padding:0 20px">
      <ol class="dd-list">
        <li class="dd-item" data-id="2" *ngFor="let indicator of indicators">
          <button *ngIf="!indicator.collapse" data-action="collapse" (click)="indicator.collapse = !indicator.collapse"
            [attr.aria-expanded]="!indicator.collapse" aria-controls="collapseBasic" type="button">Collapse</button>
          <button *ngIf="indicator.collapse" data-action="expand" (click)="indicator.collapse = !indicator.collapse"
            [attr.aria-expanded]="!indicator.collapse" aria-controls="collapseBasic" type="button">Expand</button>
          <div class="dd-handle main-item"><a
              (click)="indicator.collapse = !indicator.collapse">{{indicator.description}}</a></div>
          <ol class="dd-list" id="collapseBasic" [collapse]="indicator.collapse" *ngIf="indicator.sightings?.length > 0">
            <li class="dd-item" (click)="sightingSelected(sighting)" *ngFor="let sighting of indicator.sightings">
              <div class="dd-handle child-item item-{{sighting == selectedSighting? 'selected':''}}">
                {{sighting.CreatedDateTime | date: 'short'}}
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>

  <div *ngIf="!loaded" class="loader">Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div class="col-sm-8 m-t-lg sighting-view" *ngIf="selectedSighting && loaded">
    <div class="row m-b-sm">
      <div class="i-label col-sm-12">
        <h3 style="font-size: 20px;">
          <b>{{label_indicator}}:</b>
          <a *ngIf="selectedSighting.algoViewable" (click)="goToIndicatorPage(selectedSighting.indicatorId)"
            title="Click to open {{label_indicator}}'s Page">{{selectedSighting.indicatorId}}</a>
          <span *ngIf="!selectedSighting.algoViewable"> {{selectedSighting.indicatorId}} - </span>
          {{selectedSighting.indicatorName}}
        </h3>
      </div>
    </div>
    <div class="row col-sm-12" style="font-size: 15px;">

      <div class="row">
        <div class="i-label col-sm-4"><b>{{label_sighting}} Run Date</b></div>
        <div class="col-sm-8">
          {{selectedSighting.CreatedDateTime | date: 'short'}}
        </div>
      </div>
      <div class="row">
        <div class="i-label col-sm-4"><b>Type</b></div>
        <div class="col-sm-8">
          <span *ngFor="let type of selectedSighting.indicatorTypes; let i = index">{{type}}
            <span *ngIf="i < selectedSighting.indicatorTypes?.length-1">, </span>
          </span>
          <span *ngIf="selectedSighting.indicatorTypes?.length <= 0">--</span>
        </div>
      </div>
      <div class="row">
        <div class="i-label col-sm-4"><b>Classification</b></div>
        <div class="col-sm-8">

          <span *ngFor="let class of selectedSighting.classification; let i = index">{{class}}
            <span *ngIf="i < selectedSighting.classification?.length-1">, </span>
          </span>

        </div>
      </div>
      <div class="row">
        <div class="i-label col-sm-4 m-t-xs"><b>Recommended {{label_coa}}</b></div>
        <div class="col-sm-8 coa-link">

          <span class="m-r-md" *ngIf="selectedSighting.recommendedCOAs?.length > 0">
            <span *ngFor="let coa of selectedSighting.recommendedCOAs; let i = index">{{coa}}
              <span *ngIf="i < selectedSighting.recommendedCOAs?.length-1">, &nbsp;</span>
            </span>
          </span>

          <ng-container *ngIf="!hasCaseManagement">
            <button mat-button *ngIf="permission_execute_CoA" class="idta-start-button" (click)="startCOA('')">
              Start {{label_coa}}
            </button>
          </ng-container>

          <ng-container *ngIf="hasCaseManagement">
            <button mat-button *ngIf="permission_execute_CoA" class="idta-start-button" (click)="startCaseCOA()">
              Start {{label_coa}}
            </button>
          </ng-container>

        </div>
      </div>

    </div>

    <div class="row col-sm-12 m-t-lg" *ngIf="selectedSighting">
      <app-threat-actor-supporting-data [sighting]="selectedSighting" [threatActorName]="threatActor.name">
      </app-threat-actor-supporting-data>
    </div>

    <div class="row col-sm-12 m-t-md" *ngIf="threatActor && selectedSighting">
      <app-coa-list-threat-actor [threatActor]="threatActor" [sighting]="selectedSighting">
      </app-coa-list-threat-actor>
    </div>

  </div>
</div>