<h1 mat-dialog-title>Session Timeout</h1>

<div class="container idle-dialog-container">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-1">
            <i class="glyphicon glyphicon-warning-sign"></i>
        </div>
        <div class="col-md-9>">
            Your session is about to expire due to inactivity.
        </div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            You will be logged out automaticallly in <span class="time-remaining">   {{ timeRemaining$ | async | date:'mm:ss' }} </span> minutes
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button class="btn btn-default" 
    [mat-dialog-close]="true" 
    style="margin-right:20px; width:100px;" >Log Out</button>
    <button class="btn btn-primary" 
    style="background-color:#286090; width: 100px;" 
    [mat-dialog-close]="false">Continue</button>
</mat-dialog-actions>
