import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { EnvService } from "../../app/env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";

import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";

import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";



export class DataEntry {
 public _id? : string;
 public threatActorId? : string;
 public taId? : string; 
 public dataEntryId? : string;
 public name: string;
 public status: string;
 public createdByUserId?: string; 
 public createdByFullName? : string;
 public updatedByUserId? : string;
 public updatedByFullName? : string;
 public createdDateTime?: Date;
 public updatedDateTime?: Date;
 public formattedUpdatedDateTime? : string;
 public index? : number;
 public instanceId? : string;
 public deploymentId: string;
 public form : any;
 public error?: string;
}

export class DataEntryListResult {
    public results : DataEntry[];
    public count : number; 
    public criteria: any;
}

@Injectable()
export class DataEntryListService {

    constructor(
        private http: HttpClient, 
        private env : EnvService, 
        private cookieService: CookieService,
    ){

    }

    distinctDataEntries() : Observable<Array<string>> {

        const distinctDataEntries = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm/distinct`;
        return this.http
                    .get<Array<string>>( distinctDataEntries, RivirHttp.getHttpOptions())
                    .pipe(catchError(RivirHttp.handleError));
    }

    searchDataEntries( formName : string, matchString : string, customColumns : string, sort : any, pageNumber: number, resultsPerPage : number, fields: any  ) : Observable<DataEntryListResult> {

        const params = new URLSearchParams( {
            formName, 
            matchString, 
            customColumns, 
            sort :  JSON.stringify( sort ),
            resultsPerPage: resultsPerPage.toString(), 
            pageNumber : pageNumber.toString(), 
            fields:  JSON.stringify( fields )
        });


        const searchDataEntriesUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm?${params.toString()}`;
        return this.http
                    .get<DataEntryListResult>( searchDataEntriesUrl, RivirHttp.getHttpOptions())
                    .pipe(catchError(RivirHttp.handleError));

    }

    filterDataEntries( filter ){
        const params =new URLSearchParams( {
            filter : JSON.stringify( filter )
        });

        const searchDataEntriesUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm/filter?${params.toString()}`;
        return this.http
                    .get<DataEntryListResult>( searchDataEntriesUrl, RivirHttp.getHttpOptions())
                    .pipe(catchError(RivirHttp.handleError));
    }

    public async saveDataEntry( dataEntry : DataEntry ) : Promise<DataEntry> {
        
        // Set the User MetaData 
        dataEntry.createdByUserId = dataEntry.createdByUserId ? dataEntry.createdByUserId : this.cookieService.get("email");
        dataEntry.createdByFullName = dataEntry.createdByFullName ? dataEntry.createdByFullName : `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`;
        dataEntry.updatedByUserId = this.cookieService.get("email");
        dataEntry.updatedByFullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`;
        dataEntry.status = dataEntry.status ? dataEntry.status : "Open";


        const saveDataEntryUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm/save`;
        return this.http.post<DataEntry>(saveDataEntryUrl, { dataEntryForm: dataEntry }, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();
      }

    public completeDataEntry( dataEntry : DataEntry ){
        const completeDataEntryUrl = `${this.env.rivirApiBaseUrl}/courseofactions/survey/intakeForm/complete`;
        return this.http.post<any>(completeDataEntryUrl, { dataEntry }, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError)).toPromise();
      }
    
    public IsJsonString(str){
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
      }
}

export class DataEntryListDataSource implements DataSource<DataEntry> {

    private dataEntrySubject = new BehaviorSubject<DataEntry[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public countSubject = new BehaviorSubject<number>(0);

    public count$ = this.countSubject.asObservable();

    constructor(private dataEntryListService: DataEntryListService) {

    }

    /**
     * Runs the search based on the search criteria parameters and updated Observables for cases and count to update
     * the Material Table
     * @param threatActorId Threat Actor ID
     * @param search Seach Keywords
     * @param sort Sort (Column  ASC/DESC)
     * @param page Page Index
     * @param pageSize Page Size
     */
    public loadingDataEntryList(
        formName : string, 
        matchString: string, 
        customColumns: string,
        sort: any = { formattedUpdatedDateTime : -1 },
        pageNumber = 0,
        resultsPerPage = 3,
        fields = { dataentryform : 0 }
    ) {

        if ( sort.formattedUpdatedDateTime ){
            const sortOrder = sort.formattedUpdatedDateTime;
            delete sort.formattedUpdatedDateTime;
            sort.updatedDateTime = sortOrder;
        }

        this.loadingSubject.next(true);
        this.dataEntryListService
            .searchDataEntries(            
                formName,
                matchString,
                customColumns,
                sort,
                pageNumber,
                resultsPerPage,
                fields)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false)),
            )
            .subscribe((dataEntryListResult: DataEntryListResult) => {
                let { results, count } = dataEntryListResult;

                // Adding an index for alternating colors
                results = results.map((dataEntry: DataEntry, index: number) => {
                    dataEntry.index = index;
                    dataEntry.formattedUpdatedDateTime = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      }).format( new Date(dataEntry.updatedDateTime));
                    return dataEntry;
                });

                this.dataEntrySubject.next(results);
                this.countSubject.next(count);
            });
    }

    public connect(collectionViewer: CollectionViewer): Observable<DataEntry[]> {
        return this.dataEntrySubject.asObservable();
    }

    public disconnect(collectionViewer: CollectionViewer): void {
        this.dataEntrySubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }
}
