<h2 class="row col-sm-12 m-b-lg" *ngIf="indicator">
    <div class="id-label" style="position: absolute;top: 2px;"><i class="indicator-icon bg-dark"></i></div>
    <div style="padding-left: 55px;">
        <span class="id-label ">{{indicator.name}}</span> : {{indicator.description}}
    </div>
</h2>

<div class="row col-sm-12" *ngIf="indicator">
    <div class="col-sm-12" style="margin-left: 40px;">
        <button *ngIf="!indicator_scheduled && permission_scheduleExecute"
            class="btn btn-schedule btn-addon m-b-md m-t-xl" style="margin:0;" (click)="scheduleIndicator()">
            <i style="font-size: 16px;opacity: 0.8;" class="fa fa-calendar"></i>Schedule To Execute</button>
        <span *ngIf="indicator_scheduled && !schedule_verify_remove">
            <span style="margin:0;padding-right:10px;font-size:15px;">{{schedule_label}}</span>
            <button *ngIf="permission_scheduleExecute" class="btn btn-default m-b-md m-t-xl"
                style="width:125px; margin:0;" (click)="scheduleIndicator()">Edit Schedule</button> &nbsp;
            <button *ngIf="permission_scheduleExecute" class="btn btn-default m-b-md m-t-xl"
                style="width:185px; margin:0;" (click)="scheduleVerifyMessageShow(true)">Stop and Delete
                Schedule</button>
        </span>
        <span *ngIf="schedule_verify_remove && indicator_scheduled && permission_scheduleExecute">
            <span style="margin:0;padding-right:10px;font-size:15px;">Are you sure you want to delete this schedule
                ?</span>
            <button class="btn btn-success m-b-md m-t-xl" style="margin:0; width:100px;" (click)="stopScheduleIndicator()"> Yes
            </button>
            <button class="btn btn-default m-b-md m-t-xl" style="margin:0;" (click)="scheduleVerifyMessageShow(false)">
                Cancel </button>
        </span>
    </div>


</div>
<div class="row col-sm-12 m-t-{{indicator_scheduled? 'md':'sm'}}" *ngIf="indicator">
    <div class="col-lg-5 col-md-12 col-sm-12 details m-b-sm">
        <div class="row">
                <div  *ngIf="permission_activateAlgo" class="i-label col-sm-6 activate">
                        <div class="btn-group" role="group">
                            <button type="button" (click)="indicator.active? '':switchActivation()" title="{{indicator.active? '':'Click to Reactivate '+label_indicator}}"
                             class="btn btn-{{indicator.active? 'success':'default'}}">Activate</button>
                            <button type="button" (click)="indicator.active? switchActivation():''"  title="{{indicator.active? 'Click to De-Activate '+label_indicator:''}}" 
                            class="btn btn-{{indicator.active? 'default':'danger'}}">Deactivate</button>
                        </div>
                    </div>
            <div *ngIf="!canExecute">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"  style="color: orange"></i>
                <span class="i-label" style="margin-left: 5px;">{{ label_indicator }} is incomplete </span>
            </div>
            <div *ngIf="canExecute">
                <a class="i-label execute {{indicator.status}} col-sm-5" *ngIf="indicator.active && permission_executeAlgo" (click)="executeIndicator()"
                        title="{{indicator.status != 'in-progress'?'Click to Execute '+label_indicator:''}}"
                        >
                    <div *ngIf="indicator.status != 'in-progress'"><i class="fa fa-play"></i>Execute</div> 
                    <div *ngIf="indicator.status == 'in-progress'"><i class="fa fa-pause"></i>Abort</div> 
                </a>
            </div>
        </div>

        <div class="row" *ngFor="let label of labels; let i = index">
            <div class="i-label col-sm-4">{{label['name']}}:</div>
            <div class="col-sm-8" *ngIf="!isArray(indicator[label['prop']])">
                {{label['prop'] == 'CreatedDateTime'? (indicator[label['prop']] | date: 'MMM dd, y, h:mm a') :
                (indicator[label['prop']])}}
            </div>
            <div *ngIf="isArray(indicator[label['prop']])" class="col-sm-8">
                <span *ngFor="let el of indicator[label['prop']]; let i = index">{{el}}
                    <span class="comma" *ngIf="i < indicator[label['prop']].length-1">, </span>
                </span>
            </div>
        </div>
        <div class="row col-sm-12">
            <button *ngIf="permission_editAlgo" class="btn btn-default m-b-md m-t-xl"
                (click)="editAlgorithm()">Edit</button>
        </div>

    </div>
    <app-indicator-run-dates [indicator]="indicator" (refresh)="onRefresh()"></app-indicator-run-dates>
</div>