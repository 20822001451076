import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { AppComponent } from "./app.component";
import {
  AppRoutingModule,
  routingComponents
} from "./app.routes";
import { CoreModule } from "./core/core.module";

// bootstrap imports
import { AlertModule } from "ngx-bootstrap/alert";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { McBreadcrumbsModule } from "./breadcrumbs";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrModule } from "ngx-toastr";

import { FilterPipe } from "./shared/pipes/filter.pipe";

import { CookieService } from 'ngx-cookie-service';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppSettingService } from "./shared/services/appsetting.service";
import { BulkAssignmentService } from "./shared/services/bulk.assignment.service";
import { MessageService } from "./shared/services/message.service";
import { SightingService } from "./shared/services/sighting.service";
import { SurveyService } from "./shared/services/survey.service";
import { StixConfigService } from "./shared/services/stixConfig.service";
import { WorkflowService } from "./shared/services/workflow.service";
import { CoaDetailsComponent } from "./coa/coa-details/coa-details.component";
import { CoAService } from "./coa/coa.service";
import { CaseService } from "./case/case-service";
import { DataEntryListService } from "./data-entry-list/data-entry-list.service";
import { LoggingService } from "./core/logging.service";
import { StartupService } from "./core/startup.service";
import { UserProfileService } from "./core/userProfile.service";
import { IndicatorService } from "./indicators/indicators.service";
import { AlgorithmService } from "./indicators/nerdy-algorithm-list/nerdy-algorithm-list.service";
import { SightingLogComponent } from "./indicators/sighting-log/sighting-log.component";
import { SightingReportComponent } from "./indicators/sighting-report/sighting-report.component";
import { SearchService } from "./search/search.service";
import { TasksService } from "./tasks/tasks.service";
import { ThreatActorService } from "./threat-actor/threat-actor.service";
import { HelpService } from "./core/help.service";
import { TableauService } from "./core/tableau.service";

import { AuthGuard } from "./shared/auth.guard";

import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MaterialExampleModule} from './material.module';

import { GoogleMapsModule } from '@angular/google-maps';

import { AngularEditorModule } from "@kolkov/angular-editor";
import { BulkStatusComponent } from "./bulk-status/bulk-status.component";
import { SurveyComponent } from './survey/survey.component';
import { TaskDocumentComponent } from './tasks/task-documents/task-documents.component';
import { TaskNoteComponent } from './tasks/task-notes/task-notes.component';
import { DocumentService } from './shared/services/document.service';
import { MayanDocumentService } from "./shared/services/mayan.document.service";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HelpfulLinksComponent } from './helpful-links/helpful-links.component';
import { AlertService } from "./shared/services/alert.service";

import { CaseListingComponent } from './case/case-listing/case-listing.component';
import { CaseCountsComponent } from './case/case-counts/case-counts.component';
import { CasesGridComponent } from './case/cases-grid/cases-grid.component';
import { CaseDetailComponent } from './case/case-detail/case-detail.component';
import { CaseStartComponent } from './case/case-start/case-start.component';
import { CaseCoasComponent } from './case/case-coas/case-coas.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TaskDetailsComponent } from './tasks/task-details/task-details.component';
import { CaseHistoryComponent } from './case/case-history/case-history.component';
import { CaseCoaHistoryComponent } from './case/case-coa-history/case-coa-history.component';

import { OrderByPipeModule } from "./shared/pipes/order-by.pipe";
import { EnvServiceProvider } from "./env.service.provider";
import { DataEntryComponent } from './data-entry/data-entry.component';
import { DataEntryFormComponent } from './data-entry-form/data-entry-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CoaFileUploadDialogComponent } from './coa-file-upload-dialog/coa-file-upload-dialog.component';
import { TaskDocHistoryComponent } from './tasks/task-doc-history/task-doc-history.component';
import { RivirHeaderComponent } from './redesign/rivir-header/rivir-header.component';
import { TableauComponent } from './tableau/tableau.component';
import { ServiceCheckComponent } from './admin-settings/service-check/service-check.component';
import { IndicatorEditComponent } from './indicator-wizard/indicator-edit/indicator-edit.component';
import { IndicatorWizardParentComponent } from './indicator-wizard/indicator-wizard-parent/indicator-wizard-parent.component';
import { IndicatorWizDetailsComponent } from './indicator-wizard/indicator-wiz-details/indicator-wiz-details.component';
import { IndicatorWizNewItemDialogComponent } from './indicator-wizard/indicator-wiz-new-item-dialog/indicator-wiz-new-item-dialog.component';
import { IndicatorWizRecCoaComponent } from './indicator-wizard/indicator-wiz-rec-coa/indicator-wiz-rec-coa.component';
import { IndicatorWizAutostartComponent } from './indicator-wizard/indicator-wiz-autostart/indicator-wiz-autostart.component';
import { IndicatorWizReviewComponent } from './indicator-wizard/indicator-wiz-review/indicator-wiz-review.component';
import { IndicatorWizVisualsComponent } from './indicator-wizard/indicator-wiz-visuals/indicator-wiz-visuals.component';
import { IndicatorWizVisualsUnsetConfirmDialogComponent } from "./indicator-wizard/indicator-wiz-visuals-unset-confirm-dialog/indicator-wiz-visuals-unset-confirm-dialog.component";
import { IndicatorWizVisualsViewSelectDialogComponent } from "./indicator-wizard/indicator-wiz-visuals-view-select-dialog/indicator-wiz-visuals-view-select-dialog.component";
import { IndicatorWizNavigationComponent } from './indicator-wizard/indicator-wiz-navigation/indicator-wiz-navigation.component';
import { IndicatorWizDiagramComponent } from './indicator-wizard/indicator-wiz-diagram/indicator-wiz-diagram.component';
import { ReportpreviewComponent } from './report/reportpreview/reportpreview.component';
import { JsonDiffDialogComponent } from './json-diff-dialog/json-diff-dialog.component';
import { DataEntryListComponent } from './data-entry-list/data-entry-list.component';

import { SurveyModule } from "survey-angular-ui";
import { SurveyCreatorModule } from 'survey-creator-angular';
import { MatDialogCoaExecuteComponent } from './mat-dialog-coa-execute/mat-dialog-coa-execute.component';
import { MatDialogObAdminEditComponent } from './mat-dialog-ob-admin-edit/mat-dialog-ob-admin-edit.component';
import { MatDialogAlgoScheduleviewComponent } from './mat-dialog-algo-scheduleview/mat-dialog-algo-scheduleview.component';
import { MatDialogIdleTimeoutComponent } from './mat-dialog-idle-timeout/mat-dialog-idle-timeout.component';
import { MatDialogEditThreatorComponent } from './mat-dialog-edit-threator/mat-dialog-edit-threator.component';
import { IndicatorWizardParametersComponent } from './indicator-wizard/indicator-wizard-parameters/indicator-wizard-parameters.component';
import { MatDialogIndicatorParametersComponent } from './mat-dialog-indicator-parameters/mat-dialog-indicator-parameters.component';
import { DataEntryGridComponent } from './data-entry-grid/data-entry-grid.component';




export function startupServiceFactory(
  startupService: StartupService,
) {
  return () => startupService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SafeHtmlPipe,
    FilterPipe,
    CoaDetailsComponent,
    SightingLogComponent,
    SightingReportComponent,
    BulkStatusComponent,
    SurveyComponent,
    ContactUsComponent,
    HelpfulLinksComponent,
    TaskDetailsComponent,
    CaseListingComponent,
    CaseCountsComponent,
    CasesGridComponent,
    CaseDetailComponent,
    CaseStartComponent,
    CaseCoasComponent,
    ConfirmDialogComponent,
    TaskNoteComponent,
    TaskDocumentComponent,
    CaseHistoryComponent,
    CaseCoaHistoryComponent,
    DataEntryComponent,
    DataEntryFormComponent,
    FileUploadComponent,
    CoaFileUploadDialogComponent,
    TaskDocHistoryComponent,
    RivirHeaderComponent,
    TableauComponent,
    ServiceCheckComponent,
    IndicatorEditComponent,
    IndicatorWizardParentComponent,
    IndicatorWizDetailsComponent,
    IndicatorWizNewItemDialogComponent,
    IndicatorWizRecCoaComponent,
    IndicatorWizAutostartComponent,
    IndicatorWizReviewComponent,
    IndicatorWizVisualsComponent,
    IndicatorWizVisualsUnsetConfirmDialogComponent,
    IndicatorWizVisualsViewSelectDialogComponent,
    IndicatorWizNavigationComponent,
    IndicatorWizDiagramComponent,
    ReportpreviewComponent,
    JsonDiffDialogComponent,
    DataEntryListComponent,
    MatDialogCoaExecuteComponent,
    MatDialogObAdminEditComponent,
    MatDialogAlgoScheduleviewComponent,
    MatDialogIdleTimeoutComponent,
    MatDialogEditThreatorComponent,
    IndicatorWizardParametersComponent,
    MatDialogIndicatorParametersComponent,
    DataEntryGridComponent
  ],

  imports: [
    SurveyModule,
    CoreModule,
    AlertModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    CommonModule,
    GoogleMapsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    McBreadcrumbsModule.forRoot(),
    NgxPaginationModule,
    NgIdleKeepaliveModule.forRoot(),
    MaterialExampleModule,
    FlexLayoutModule,
    MatNativeDateModule,
    AngularEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    OrderByPipeModule,
    SurveyCreatorModule

  ],
  providers: [
    AppSettingService,
    DocumentService,
    MayanDocumentService,
    UserProfileService,
    AlgorithmService,
    WorkflowService,
    IndicatorService,
    ThreatActorService,
    SearchService,
    CoAService,
    TasksService,
    MessageService,
    LoggingService,
    StartupService,
    SightingService,
    AlertService,
    SurveyService,
    StixConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    AuthGuard,
    BulkAssignmentService,
    CaseService,
    DataEntryListService,
    HelpService,
    TableauService,
    CookieService,
    EnvServiceProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }