<div *ngIf="failedToLoadSurvey">
    <div class="alert alert-danger" role="alert">
        Failed to load {{stixConfig.coa_singular}}. Please contact your System Administrator.
    </div>
</div>

<ng-container *ngIf="!failedToLoadSurvey">
    <div class="container-fluid survey-header" [hidden]="!showPagination">
        <div class="row">
            <div class="col-md-4">
                <ng-select 
                    [items]="pages" 
                    bindLabel="pageName" bindValue="pageNumber"
                    [(ngModel)]="selectedPageNumber"
                    (change)="getPage($event)"
                    placeholder="Select Page">
                </ng-select>
            </div>
            <div class="col-md-4 header-nav-page-desc" >
                    Page {{selectedPageNumber + 1 }} of {{numberOfPages}} 
            </div>
            <div class="col-md-4 header-nav-buttons">
                <div *ngIf="model">
                    <ul class="ngx-pagination">
                        <li class="pagination-previous" *ngIf="!model.isFirstPage">
                            <a class="page-link" (click)="prevPage();">{{ model.pagePrevText }}</a></li>
                        <li class="pagination-previous disabled" *ngIf="model.isFirstPage">
                            <span>{{ model.pagePrevText }}</span></li>
                        <li class="pagination-next" *ngIf="!model.isLastPage">
                            <a class="page-link" (click)="nextPage();">{{ model.pageNextText }}</a></li>
                        <li class="pagination-next disabled" *ngIf="model.isLastPage">
                            <span>{{ model.pageNextText }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="model">
        <survey [model]="model"></survey>
    </div>
    <div>
        <div class="container-fluid survey-footer" *ngIf="model">
            <div class="row">
                <div class="col-md-12" style="text-align:right; margin-bottom:10px;">
                    <ul class="ngx-pagination" *ngIf="!(model.isFirstPage && model.isLastPage)">
                        <li class="pagination-previous" *ngIf="!model.isFirstPage">
                            <a class="page-link" (click)="prevPage();">{{ model.pagePrevText }}</a></li>
                        <li class="pagination-previous disabled" *ngIf="model.isFirstPage">
                            <span>{{ model.pagePrevText }}</span></li>
                        <li class="pagination-next" *ngIf="!model.isLastPage">
                            <a class="page-link" (click)="nextPage();">{{ model.pageNextText }}</a></li>
                        <li class="pagination-next disabled" *ngIf="model.isLastPage">
                            <span>{{ model.pageNextText }}</span>
                        </li>
                    </ul>
                    <button type="button" class="btn btn-primary btn-survey-save" (click)="save()"
                        style="min-width: 100px; margin-left:14px;">Save</button>
                    <button type="button" class="btn btn-primary btn-survey-complete" (click)="complete()"
                        style="min-width: 100px; margin-left:12px;">Complete</button>
                </div>
            </div>
        </div>
    </div>
    <div class="spinner-container container" *ngIf="!model">
        <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-3" style="padding:25px 0;">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</ng-container>