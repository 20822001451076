<div class="container-fluid indicator-execute-container" >
    <div class="container-fluid ind-param-header" *ngIf="!surveyIsLoading">
        <div class="row">
            <div class="col-md-12 text-right ind-param-col">
                <button class="btn btn-success btn-indicator-execute" (click)="complete()" [disabled]="disableSaveSubmit">Submit</button>
            </div>
        </div>
    </div>
    
    <div>
        <div class="container-fluid survey" *ngIf="!surveyIsLoading">
            <ul class="nav nav-tabs" *ngIf="model.pageCount > 1">
                <li class="nav-item" *ngFor="let page of pages; let i = index;" [ngClass]="{'active' : page.isActive}">
                    <a class="nav-link" href="#" (click)="getPage($event, i);">
                        {{ page.localizableStrings.title.values.default }}</a>
                </li>
            </ul>
        </div>
    </div>
    <div> 
        <div [hidden]="surveyIsLoading">
            <survey [model]="model"></survey>
        </div>
        <div class="container-fluid survey" *ngIf="!surveyIsLoading">
            <div class="row">
                <div class="col-md-12 data-entry-form-pagination-row">
                    <ul class="ngx-pagination" *ngIf="!(model.isFirstPage && model.isLastPage)">
                        <li class="pagination-previous" *ngIf="!model.isFirstPage">
                            <a class="page-link" (click)="prevPage();">{{ model.pagePrevText }}</a></li>
                        <li class="pagination-previous disabled" *ngIf="model.isFirstPage">
                            <span>{{ model.pagePrevText }}</span></li>
                        <li class="pagination-next" *ngIf="!model.isLastPage">
                            <a class="page-link" (click)="nextPage();">{{ model.pageNextText }}</a></li>
                        <li class="pagination-next disabled" *ngIf="model.isLastPage">
                            <span>{{ model.pageNextText }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="spinner-container container" *ngIf="surveyIsLoading">
        <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-3 data-entry-form-loader">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    </div>