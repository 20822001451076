<h2 mat-dialog-title>Edit Name</h2>
<mat-dialog-content>
<div class="container" style="width:600px" *ngIf="isEditing">
        <div class="row">
                <div class="col-md-4" style="margin-top: 16px;">
                        Name:
                </div>
                <div class="col-md-8">
                        <mat-form-field appearance="outline" style="width:100%">
                                <input matInput placeholder="{{data.existingThreatActorName}}" [(ngModel)]="updatedThreatActorName"/>
                        </mat-form-field>
                </div>
        </div>
</div>
<div class="container" style="width:600px" *ngIf="!isEditing">
        <div class="row">
                <div class="col-md-12"> 
                        Are you sure you would like to change the name from "{{data.existingThreatActorName}}" to "{{updatedThreatActorName}}"
                </div>
        </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="confirm()" *ngIf="isEditing" cdkFocusInitial class="btn save-threat-actor-name-btn" [disabled]="!updatedThreatActorName">Save</button>
  <button mat-button (click)="close()" *ngIf="!isEditing" cdkFocusInitial class="btn save-threat-actor-name-btn">Ok</button>
</mat-dialog-actions>
