import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

import * as moment from "moment";

import { UserProfile } from "../../shared/models/userProfile";  
import { RivirHttp } from "../../shared/services/rivir-http.service";
import { KeycloakService } from "../../core/keycloak/keycloak.service";
import { MessageService } from '../../shared/services/message.service';
import { PermissionService } from '../../core/permission.service';
import { UserProfileService } from '../../core/userProfile.service';
import { Message } from '../../shared/models/message';
import { EnvService } from '../../env.service';
import { StixConfigService } from '../../shared/services/stixConfig.service';
import { AppSettingService } from '../../shared/services/appsetting.service';

@Component({
  selector: 'rivir-header',
  templateUrl: './rivir-header.component.html',
  styleUrls: ['./rivir-header.component.css']
})
export class RivirHeaderComponent implements OnInit {

  public headerTextColor : string;
  public headerImage: string;
  public currentUserProfile: UserProfile;
  public profileProfile: string;
  public notifications: Message[];
  public showSettings: boolean
  public hasCaseManagement: boolean;
  public hasSearch: boolean;
  public hasReport: boolean;
  public hasDataEntry: boolean;
  public currentRoute: string;
  public newMessageCount: number;
  public environment: any;

  constructor(
    private kc: KeycloakService,
    private cookieService: CookieService,
    private messageService: MessageService,
    private permissionsService: PermissionService,
    private userProfileService: UserProfileService,
    private toastr: ToastrService,
    private router: Router,
    private env: EnvService,
    public stixConfig: StixConfigService,
    public appSettingService: AppSettingService,
  ){ 
    this.router.events.subscribe(this.logUserOutIfTokenInvalid);
    this.hasCaseManagement = this.env.hasCaseManagement;
    this.hasDataEntry = this.env.hasDataEntry;
    this.hasReport = this.env.reportBar;
    this.hasSearch = this.env.searchBar;
  }

  ngOnInit(): void {

    // TODO This and other styles need to come from a Template Behavior Subject for White listing
    this.headerTextColor = "#FFF"; 
    this.setHeaderImage();

    this.currentUserProfile = JSON.parse(this.cookieService.get("userProfile")) as UserProfile;
    this.profileProfile = `${this.currentUserProfile.profilePicUrl}?access_token=${RivirHttp.rivirApiToken}`;

    this.getNotifications();

    this.getPermissions();

    this.getTooltip();
    
  }

  async setHeaderImage() {
    this.environment = await this.appSettingService.getAppSetting('environment').toPromise();
    if (this.environment.results.environment == "silverstay"){
      this.headerImage = "../../../assets/img/RIViR_logo_h_new-Silverstay.png";
    }
    else{
      this.headerImage = "../../../assets/img/RIViR_logo_h_new.png";
    }
  }

  public getNotifications() {
    if ( this.currentUserProfile ){
      this.messageService
          .getUsersLatestMessage( this.currentUserProfile.email )
          .then( ( notifications ) => {

            this.notifications = this.formatNotifications( notifications ); 
          });

      this.messageService
          .getCountOfNewMessages( this.currentUserProfile.email )
          .then (( count ) => {
            this.newMessageCount = count;
          });
    }
  }

  public async getTooltip() {
    this.userProfileService
      .getSuccessfulLogin(this.userProfileService.userEmail)
      .subscribe((response) => {
        if ( response.successfulLogin ){
          const loginDateTime = new Date(response.successfulLogin.CreatedDateTime).toLocaleDateString() + " " + new Date(response.successfulLogin.CreatedDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          this.toastr.info(`Last Successful Login: <br/> ${loginDateTime} <br/> Unsuccessful Login Attempts: ${response.unsuccessfulAttemptsCount}`, '', { closeButton: true, enableHtml: true });
        }
      });
  }


  public async getPermissions() {

    const permissionsPromises = [
      this.permissionsService.getPermission("User Accounts"),
      this.permissionsService.getPermission("System Settings"),
      this.permissionsService.getPermission("Notification Settings"),
      this.permissionsService.getPermission("Role Permissions")
    ];

    const [canViewUsersAccounts, canViewSystemSettings, canViewNotificationSettings, canViewRolePermissions] = await Promise.all(permissionsPromises);
    this.showSettings = canViewSystemSettings || canViewUsersAccounts || canViewNotificationSettings || canViewRolePermissions;
  }

  public onLogout() {
    this.kc.logout( "RIViR Header" );
  }

    /**
   * Run on route change to check if the user's token is still valid by calling the
   * UserInfo method.  If a error is returned the user is logged out
   * @param val Router event 
   */
     public logUserOutIfTokenInvalid = async (val) => {
      this.currentRoute = window.location.pathname.replace('/', '');
      let tokenResponse = '';
      try {
        if (val instanceof NavigationStart) {
          tokenResponse = await this.kc.getToken();
          await this.kc.loadUserInfo();
        }
      }
      catch (err) {
        // this.kc.logout();
        console.log( `Failed to get userInfo, Response when getting token from Keycloak : ${tokenResponse}` );
      }
    }

    public formatNotifications ( notifications ) {

      const keywordMapping = [
         { keyword : "coa_singular", stixReplacement: this.stixConfig.coa_singular},
         { keyword : "coa_plural", stixReplacement: this.stixConfig.coa_plural},
         { keyword : "indicator_singular", stixReplacement: this.stixConfig.indicator_singular},
         { keyword : "indicator_plural", stixReplacement: this.stixConfig.indicator_plural},
         { keyword : "sighting_singular", stixReplacement: this.stixConfig.sighting_plural},
         { keyword : "threatActor_singular", stixReplacement: this.stixConfig.threatActor_plural},
         { keyword : "visualization_singular", stixReplacement: this.stixConfig.visualization_singular},
         { keyword : "visualization_plural", stixReplacement: this.stixConfig.visualization_plural},
         { keyword : "Course of Action", stixReplacement: this.stixConfig.coa_singular}
      ];

      for( const notification of notifications ) {

        notification.isNew = notification.status !== 'Seen';
        notification.timePassed = moment(notification.createDtime).fromNow();

        for (const keywordReplacement of keywordMapping ){

          notification.type = notification.type ? 
                              notification.type.replace( new RegExp(keywordReplacement.keyword, 'g'), keywordReplacement.stixReplacement ) :
                              notification.type;
          notification.body = notification.body ? 
                              notification.body.replace( new RegExp(keywordReplacement.keyword, 'g'), keywordReplacement.stixReplacement ) :
                              notification.body;
        }
      }

      return notifications;
    }

    async clearNotifications(){
      
      await this.messageService.markAllAsSeen().toPromise();
      this.newMessageCount = await this.messageService.getCountOfNewMessages( this.currentUserProfile.email );
    }
    

}
