<div class="container-fluid" *ngIf="model">
    <indicator-wiz-navigation [stepNumber]="5"  [parentLocalSaveFunction]="saveBoundCallback"></indicator-wiz-navigation>
    <div class="row">
        <div class="col-md-4">
            <div class="container-fluid" style="height: 700px;">
                <div class="row">
                    <div class="col-sm-12">
                        <label class="header-label" for="description">
                            Optional step. Choose one or many {{label_coas}} that starts automatically when this {{label_indicator}} is executed.
                        </label>

                    </div>
                    <div class="col-sm-12">
                        <button class="btn btn-primary btn-addon" (click)="addNewAutoStartCoa($event)">Add Autostart
                            {{label_coa}}
                            <i class="fa fa-plus" style="opacity: 0.7;"></i>
                        </button>
                    </div>
                </div>

                <div class="entries-container">
                    <div *ngFor="let autoCoa of model.indicator.autoStartCoursesOfAction;  let i=index" class="row m-t-md" (click)="showPreview( autoCoa, i )" [ngClass]="{'selected-autostart' : selectedIndex === i}"> 
                        <div *ngIf="autoCoa.showRemovePrompt" class="container-fluid autostart-cancel-prompt" > 
                            <div class="row">
                                <div class="col-md-6">
                                    Are you sure you want to delete this Autostart {{label_coa}}?
                                </div>
                                <div class="autostart-cancel-prompt-buttons col-md-6">
                                    <button class="btn btn-primary m-r-xs" (click)="removeAutoStartCoa(autoCoa)"> Yes
                                    </button>
                                    <button class="btn btn-secondary" (click)="cancelRemovePrompt(autoCoa)"> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid"
                            [ngClass]="{'autostart-disable': autoCoa.showRemovePrompt, '': !autoCoa.showRemovePrompt}">
                            <div class="row">
                                <div class="col-md-12 delete_autostartcoa">
                                    <a (click)="showRemovePrompt(autoCoa)"><mat-icon>highlight_off</mat-icon> <span>Delete</span></a>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-12">
                                    <label>{{label_coas}}:</label>
                                    <ng-select [items]="allProcessDefinitions" bindLabel="name" bindValue="key"
                                        placeholder="Select one or multiple {{label_coas}}.." [multiple]="true"
                                        [closeOnSelect]="false" [(ngModel)]="autoCoa.courseOfActions"
                                        [id]="'autoStartCoa_' + i" [name]="'autoStartCoa_' + i">
                                    </ng-select>
                                    <div *ngIf="autoCoa.coaIsInvalid" class="alert alert-danger">
                                        {{label_coas}} is required
                                    </div>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-12">
                                    <label class="pad-top">Assign To:</label>
                                    <ng-select [items]="allUsers" [(ngModel)]="autoCoa.user" bindLabel="nameAndEmail"
                                        bindValue="email" placeholder="Select Assignee" [id]="'autoStarUser_' + i"
                                        [name]="'autoStarUser_' + i">
                                    </ng-select>
                                    <div *ngIf="autoCoa.userIsInvalid" class="alert alert-danger">
                                        Assign is required
                                    </div>
                                </div>
                            </div>
                            <div class="row autostart-row">
                                <div class="col-md-12">
                                    <label class="pad-top">Due In (Days):</label>
                                    <input class="form-control" type="number" min="0"
                                        [attr.disabled]="autoCoa.showRemovePrompt ? '' : null" [name]="'daysOut_' + i"
                                        [(ngModel)]="autoCoa.daysOut">
                                    <div *ngIf="autoCoa.dayOutIsInvalid" class="alert alert-danger">
                                        Days out is required
                                    </div>
                                </div>
                            </div>
                            <div class="horizontal-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row" style="height:600px;">
                <div class="nav-tabs-alt m-b-lg">
                    <ul class="nav nav-tabs">
                      <ng-container *ngFor="let preview of recommendedCoas">
                        <li  [ngClass]="{'active': preview.active}">
                            <a data-toggle="tab" (click)="updateDiagram( preview)">{{preview.name}}</a>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                <div class="tab-content" style="height: 100%">
                    <indicator-wiz-diagram [deploymentId]="deploymentId"></indicator-wiz-diagram>
                </div>
            </div>
        </div>
    </div>
</div>