import { Component, OnInit } from "@angular/core";
import { ActivatedRoute} from '@angular/router';
import { SightingService } from "../../shared/services/sighting.service";

import { Subscription, interval } from 'rxjs';

@Component({
  selector: "app-sighting-log",
  templateUrl: "./sighting-log.component.html",
  styleUrls: ["./sighting-log.component.css"],
})
export class SightingLogComponent implements OnInit {

  public log : string;
  public sightingName: string;
  public localeSightingName : string;
  public sightingStatus : string;
  public logCollection : Array<any> = [];
  public autoRefresh: Subscription;

  constructor(private route: ActivatedRoute, private sightingService: SightingService) { }

  async ngOnInit() {

    this.route.params.subscribe(async (params) => 
    {
      this.sightingName = params.name;
      await this.reloadLog();

      const observable = interval(1500);
      this.autoRefresh = observable.subscribe(async (val) => { 
        await this.reloadLog();
      });
  
    })
  }

  ngOnDestroy() {
    this.autoRefresh.unsubscribe();
  }

  async reloadLog(){

    const sightings  = await this.sightingService.getSightingsByName( this.sightingName ).toPromise();

    if (sightings && sightings.length ){
      const sighting = sightings.pop();

      if ( !this.localeSightingName ){
        const sightingDate = new Date( sighting.CreatedDateTime );
        this.localeSightingName = `${sighting.algorithmName}--${sightingDate.toLocaleDateString()}, ${sightingDate.toLocaleTimeString()}`;
      }

      this.log = sighting.log;
      if ( sighting.logCollection && sighting.logCollection.length ){
        
        // Only refresh the collection if the there is a new item 
        if (  this.logCollection.length !== sighting.logCollection.length){
          this.logCollection = sighting.logCollection.sort( ( a, b ) =>  a.UpdatedDateTime - b.UpdatedDateTime );
        }
      }
      else {
        if ( this.logCollection.length == 0 || this.logCollection[0].message !== sighting.log ){
          this.logCollection.push( { message: sighting.log, type: "info" });
        }
        
      }
      this.sightingStatus = sighting.status;
    }
  }
}
