import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { UserProfileService } from '../../core/userProfile.service';
import { IndicatorService } from '../../indicators/indicators.service';
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";

import { MatDialog } from "@angular/material/dialog";
import { MatDialogIndicatorParametersComponent } from '../../mat-dialog-indicator-parameters/mat-dialog-indicator-parameters.component';

@Component({
  selector: 'indicator-wiz-navigation',
  templateUrl: './indicator-wiz-navigation.component.html',
  styleUrls: ['./indicator-wiz-navigation.component.css']
})
export class IndicatorWizNavigationComponent implements OnInit {

  @Input() public stepNumber: number;
  @Input() public showChangeButton: boolean;
  @Input() public parentLocalSaveFunction: () => void;
  @Input() public parentLocalChangeFunction: () => void;
  @Input() public parentUploadFunction: ( args: any ) => void;
  public model: IndicatorWizardModel;
  public allSteps: StepDetails[];
  public currentStep: StepDetails;
  public executeDisabled: boolean;
  private autostartsInvalid: any;

  constructor(public stixConfig: StixConfigService,
              private indicatorWizardService: IndicatorWizardService,
              private indicatorService: IndicatorService,
              private userService: UserProfileService,
              private toastr : ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              public dialog: MatDialog) { 
    this.allSteps = [
      { header: `${this.stixConfig.indicator_singular} Script`, nextStepName: `${this.stixConfig.indicator_singular} Details`},
      { header: "Edit Details", nextStepName: "Parameters"},
      { header: "Edit Parameters", nextStepName: stixConfig.visualization_plural},
      { header: `Edit ${stixConfig.visualization_plural}`, nextStepName: this.stixConfig.coa_plural },
      { header: `Edit Recommended ${this.stixConfig.coa_plural}`, nextStepName: "Autostart"},
      { header: `Edit Autostart ${this.stixConfig.coa_plural}`, nextStepName: "Review" },
      { header: `Review`, nextStepName: null }
    ];

  }

  ngOnInit(): void {
    this.currentStep = this.allSteps[ this.stepNumber ];

    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();
      this.autostartsInvalid = this.model.indicator.autoStartCoursesOfAction && this.model.indicator.autoStartCoursesOfAction.length ? 
      this.model.indicator.autoStartCoursesOfAction.find( x => !x.user ) || this.model.indicator.autoStartCoursesOfAction.find( x => x.daysOut <= 0 ) :
      false;

      this.executeDisabled = !this.isExecutable();
    });

    this.indicatorWizardService.navigation.subscribe( async (event : RivirStepperSelectionEvent) => {
      if ( event ){
        this.autostartsInvalid = this.model.indicator.autoStartCoursesOfAction && this.model.indicator.autoStartCoursesOfAction.length ? 
        this.model.indicator.autoStartCoursesOfAction.find( x => !x.user ) || this.model.indicator.autoStartCoursesOfAction.find( x => x.daysOut <= 0 ) :
        false;
        
        if ( !event.duplicateNavEvent  ) { 
          event.duplicateNavEvent = true;
          this.model.validationMessage = await this.indicatorService.validateIndicator( this.model.indicator.name, this.model.indicator.script);

          this.executeDisabled = !this.isExecutable();
        }
      }
    });

    this.executeDisabled = !this.isExecutable();
  }

  isExecutable() {
    // Check if name, script, and description are provided
    if (!this.model.indicator.name ||
        !this.model.indicator.script ||
        !this.model.indicator.description) {
      return false;
    }
    // Check if classifications exist and have length
    if (!this.model.indicator.classifications ||
        !this.model.indicator.classifications.length) {
      return false;
    }
    // Check if there is a script validation error message
    if (this.model.validationMessage) {
      return false;
    }
    // Check if autostarts are valid
    if (!!this.autostartsInvalid) {
      return false;
    }
    
    return true;
  }

  change(){
    this.parentLocalChangeFunction();
  }

  back(){
    this.parentLocalSaveFunction();
    this.model.currentStep = this.stepNumber - 1;
    this.indicatorWizardService.changeData( this.model );
  }

  async next(){
      this.parentLocalSaveFunction();
        this.model.validationMessage = await this.indicatorService.validateIndicator( this.model.indicator.name, this.model.indicator.script);
      this.executeDisabled = !this.isExecutable();

      if ( !this.model.validationMessage ) {
      this.model.currentStep = this.stepNumber + 1;
      this.indicatorWizardService.changeData( this.model );
    }
  }
  
  async save() {
    this.parentLocalSaveFunction()
    const savedIndicator = await this.indicatorService.saveWizardIndicator( this.model.indicator, this.userService.fullName);

    if ( savedIndicator ){ 
      this.toastr.info( `Successfully saved ${savedIndicator.name} at ${savedIndicator.UpdatedDateTime}`);
      this.leaveWizard();
    }
    else {
      this.toastr.error( `Failed to save [${this.model.indicator.name}] `);
    }
  }

  leaveWizard(){

    // Clear out wizard
    this.indicatorWizardService.refreshModel();


    if ( this.model.indicator && this.model.indicator.id  ) {
      this.router.navigate( ["../../"],  { relativeTo: this.route });
    }
    else {
      this.router.navigate( ["../"],  { relativeTo: this.route });
    }   
  }

  async execute(){
    const savedIndicator = await this.indicatorService.saveWizardIndicator( this.model.indicator, this.userService.fullName);

    if ( savedIndicator ) {

      if ( this.model.indicator.surveyJson ){
        const dialogRef = this.dialog.open(MatDialogIndicatorParametersComponent, {
          data: {
            surveyJson : this.model.indicator.surveyJson
          }
        });

        dialogRef.afterClosed().subscribe(
          async parameterResult => {
            this.executeIndicator( savedIndicator, parameterResult.modelData)
          }
        )
      }
      else {
        this.executeIndicator( savedIndicator, null);
      }
    }
    else {
      this.toastr.error( `Failed to save [${this.model.indicator.name}] `);
    }
  }

  async executeIndicator( savedIndicator : any, parameters : any ) {
    await this.indicatorService.executeAlgorithm(savedIndicator, this.userService.fullName, parameters).toPromise();
    const updatedDateTime = new Date(savedIndicator.UpdatedDateTime).toLocaleDateString() + 
    " " + new Date(savedIndicator.UpdatedDateTime).toLocaleTimeString();
    this.toastr.success( `Successfully saved and executing ${savedIndicator.name} at ${updatedDateTime}. <br/> ${savedIndicator.name} is running now`, '', { closeButton: true, enableHtml: true });

    this.router.navigate( ["../../"],  { relativeTo: this.route });
  }

  public fileSelected(fileInput) {
    this.parentUploadFunction( fileInput );
  }
}

export class StepDetails {
  header: string;
  nextStepName: string
}

class RivirStepperSelectionEvent extends StepperSelectionEvent{
  duplicateNavEvent: boolean;
}