import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { coa } from "../shared/models/coa";

import { StixConfigService } from "../shared/services/stixConfig.service";
import { ProcessDefinition, WorkflowService } from '../shared/services/workflow.service';
import { PermissionService } from "../core/permission.service";

import { UserProfileService } from "../core/userProfile.service";
import { CoAService } from "../coa/coa.service";
import { UserProfile } from "../shared/models/userProfile";




@Component({
  selector: 'app-mat-dialog-coa-execute',
  templateUrl: './mat-dialog-coa-execute.component.html',
  styleUrl: './mat-dialog-coa-execute.component.css'
})
export class MatDialogCoaExecuteComponent {

  public permissions : CoaExecutePermissions;
  public coaUsers: UserProfile[] = [];
  public processDefinitions: ProcessDefinition[] = [];
  public dueDate: string;
  public selectedProcessDefinition: ProcessDefinition;
  public assignee: UserProfile;
  public modalTitle: string;
  public state: string;
  public successMessage: string;
  public errorMessage: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CoaExecuteData,
    private coaService: CoAService,
    private userProfileService: UserProfileService,
    private workflowService: WorkflowService,
    private permissionService: PermissionService,
    public stixConfig: StixConfigService,
    private dialogRef: MatDialogRef<MatDialogCoaExecuteComponent>
  ) { 
  }

  public async ngOnInit() {
    
    // Make all the REST call to start the dialog
    const [ canExecuteCoa, canUpdateDueDate, canReassign, processDefinitions, activeUsers ] = await Promise.all([
      this.permissionService.getPermission("Start Course Of Action"),
      this.permissionService.getPermission("Update Course of Action Due Date"),
      this.permissionService.getPermission("Re-assign Course of Action"),
      this.workflowService.getProcessDefinitions(),
      this.userProfileService.getActiveUsers().toPromise()
    ]);

    // Set the Component variables for the template
    this.permissions = { canExecuteCoa, canUpdateDueDate, canReassign };
    if ( this.data.recommendedCoa && this.data.recommendedCoa.length ){
      this.processDefinitions = processDefinitions.filter( x => this.data.recommendedCoa.includes( x.name) );
    }
    else {
      this.processDefinitions = processDefinitions;
    }
    this.coaUsers = activeUsers.map( user => { 
                                                user.nameAndEmail = `${user.name} ${user.email}`;
                                                return user;
                                              });

    // Set the title
    if ( this.data?.payload ){
      this.state = "EXECUTE";
    }
    else if ( this.data?.courseOfAction ){
      this.state = "SET_DUE_DATE";
    }
    else if (this.data?.currentTask ){
      this.state = "REASSIGN";
    }

    // Set the title
    if ( this.data?.payload ){
      this.modalTitle = `Execute ${this.stixConfig.coa_singular}`;
    }
    else if ( this.data?.courseOfAction ){
      this.modalTitle = `Edit ${this.stixConfig.coa_singular} Due Date`;
      if (this.data?.courseOfAction?.coaDueDate){
        this.dueDate = new Date(this.data.courseOfAction.coaDueDate).toISOString().split('T')[0];
      }
    }
    else if (this.data?.currentTask ){
      this.modalTitle = `Reassign ${this.data.currentTask.Name}`;
    }
  }

  public async executeCourseOfAction() {

    this.data.payload.courseOfActionName = { value: this.selectedProcessDefinition.name, type: "String" };
    this.data.payload.coaDueDate = { value: new Date(this.dueDate).toISOString(), type: "String" };

    const startProcessResponse = await this.workflowService.startProcessWithPayload(this.selectedProcessDefinition.key, this.data.payload, this.assignee.email).toPromise();
    if ( startProcessResponse && startProcessResponse.status !== "Error"){
      this.state = "SUCCESS";
      this.successMessage = `Start ${this.stixConfig.coa_singular} Process (${this.selectedProcessDefinition.name}): SUCCESS`;
    }
    else {
      console.error( startProcessResponse );
      this.state = "ERROR";
      this.errorMessage = `Failed to start ${this.stixConfig.coa_singular} Process (${this.selectedProcessDefinition.name})`;
      
    }
  }

  public async reassignCoa() {
    
    const reassignResponse = await this.workflowService.claimUserTask( this.data.currentTask.id, this.assignee.email ).toPromise();
    
    if ( reassignResponse.status.status === "SUCCESS" ){
      this.dialogRef.close({ status: "SUCCESS", assignee: this.assignee });
    }
    else {
      console.error( reassignResponse );
      this.dialogRef.close( { status: "ERROR", error: reassignResponse } );
    }

  }

  public async updateDueDate(){
    this.data.courseOfAction.coaDueDate = new Date(this.dueDate).toISOString();
    const dueDateResponse = await this.coaService.updateDuedate(this.data.courseOfAction.instanceId, this.dueDate, this.userProfileService.userEmail ).toPromise();
    this.dialogRef.close( { status: dueDateResponse.status, coaDueDate : this.dueDate } );
  }
}

export interface CoaExecuteData{
  recommendedCoa : any;
  payload: any;
  currentTask: any;
  modelName: string;
  courseOfAction: coa;
}

class CoaExecutePermissions {
  canExecuteCoa: boolean;
  canUpdateDueDate : boolean;
  canReassign : boolean;
}