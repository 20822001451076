<div class="map" *ngIf="apiLoaded | async">
  <google-map [center]="center" [zoom]="zoom" class="map" [height]="250" width="100%">
    <map-marker #marker="mapMarker" [position]="center" [options]="markerOptions" (mapClick)="openInfoWindow(marker)">
    </map-marker>
    <map-info-window>
      <div class="map-info-name"> {{ name }} </div>
      <div class="map-info-address"> {{ address }} </div>
      <div class="map-info-link">
        <a href="{{googleRedirectUrl}}" target="_blank">Open in Google Maps</a>
      </div>
    </map-info-window>
  </google-map>
</div>