import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { McBreadcrumbsConfig } from "../breadcrumbs";
import { ClickElsewhereDirective } from "../shared/click-elsewhere.directive";
//import { PageDeniedMessageComponent } from "../shared/pageDeniedMessage";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { AppRoutingModule } from "../app.routes";
import { RivirAPI } from "../rivirapi/rivirapi.sdk";
import { KeycloakService } from "./keycloak/keycloak.service";
import { NotificationsPageComponent } from "./notifications-page/notifications-page.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { PermissionService } from "./permission.service";
import { UserProfileService } from "./userProfile.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
  ],
  declarations: [NotificationsComponent, NotificationsPageComponent,
    ClickElsewhereDirective ],
  exports: [ClickElsewhereDirective],
  providers: [KeycloakService, UserProfileService, RivirAPI, PermissionService],
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, breadcrumbsConfig: McBreadcrumbsConfig, stixLabel: StixConfigService) {

    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only");
    }

    breadcrumbsConfig.postProcess = (x) => {
      const y = x;
      y.forEach((bc) => {
        if (bc.text.indexOf(" ") == -1) {
          bc.text = stixLabel[bc.text] || bc.text;
        } else {
          const texts = bc.text.split(" ");
          const strings = [];
          texts.forEach((t) => { strings.push(stixLabel[t] || t); });
          bc.text = strings.join(" ");
        }
      });
      return y;
    };
  }
}
