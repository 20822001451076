import { Component, OnInit } from '@angular/core';
import { SurveyCreatorModel } from "survey-creator-core";
import { setLicenseKey } from "survey-core";

import { StixConfigService } from "../../shared/services/stixConfig.service";

import { IndicatorService } from "../../indicators/indicators.service";
import { IndicatorWizardModel, IndicatorWizardService } from "../indicator-wizard.service";

@Component({
  selector: 'indicator-wizard-parameters',
  templateUrl: './indicator-wizard-parameters.component.html',
  styleUrl: './indicator-wizard-parameters.component.css'
})
export class IndicatorWizardParametersComponent implements OnInit {

  public surveyCreatorModel: SurveyCreatorModel;
  public saveBoundCallback: () => void;
  public model: IndicatorWizardModel;

  constructor(
    private indicatorWizardService: IndicatorWizardService, 
    private indicatorService: IndicatorService,
    public stixConfig: StixConfigService
  ) {
    setLicenseKey(
      "YTRkYmY0MjktZDg1NS00MzAzLTg2NWEtMGI4M2QzZjIwNzJmOzE9MjAyNS0wMS0xNywyPTIwMjUtMDEtMTcsND0yMDI1LTAxLTE3"
    );
  }
  async ngOnInit() {



    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);

    await this.indicatorService.getTClassificationsList().toPromise();
    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();

      const creatorOptions = {
        showLogicTab: true,
        isAutoSave: true
      };
      const creator = new SurveyCreatorModel(creatorOptions);
      creator.JSON = this.model.indicator.surveyJson;
      creator.saveSurveyFunc = () => {
        this.saveLocalDataToModel();
      }
      this.surveyCreatorModel = creator;
    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();
      }
    });
  }

  saveLocalDataToModel() {
    this.model.indicator.surveyJson = this.surveyCreatorModel.getSurveyJSON();

    this.model.questions = [];
    if ( this.model.indicator?.surveyJson?.pages ){
      for ( const page of this.model.indicator.surveyJson.pages ){
        this.model.questions= this.model.questions.concat( page.elements );
      }
    }
  }
}
