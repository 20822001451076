import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { KeycloakService } from "./core/keycloak/keycloak.service";
import { StartupService } from "./core/startup.service";
import { RivirAPI } from "./rivirapi/rivirapi.sdk";

import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { EnvService } from "./env.service";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogIdleTimeoutComponent } from "./mat-dialog-idle-timeout/mat-dialog-idle-timeout.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [RivirAPI],
})
export class AppComponent {

  public model: any = {};
  public loading: boolean = false;
  public error: string = "";
  public sessionIdleConfig: number;
  public hasSearch: boolean;
  public hasCaseManagement: boolean;
  public hasDataEntry: boolean;
  public hasReport: boolean;
  public isDialogOpen = false;

  constructor(private router: Router, 
              private startup: StartupService, 
              private kc: KeycloakService,
              private dialog: MatDialog,
              private idle: Idle, 
              private keepalive: Keepalive, 
              private env: EnvService) {

    this.sessionIdleConfig = this.env.sessionIdleTimeout || 30;
    if (this.sessionIdleConfig >= 2) {
      this.setSessionIdle();
    }
  }

  public ngOnInit() {
    if (!this.startup.initialized) {
      console.error("Application configuration failed");
      this.router.navigate(["error"], { replaceUrl: true });
    }
    this.hasSearch = this.env.searchBar;
    this.hasCaseManagement = this.env.hasCaseManagement;
    this.hasDataEntry = this.env.hasDataEntry;
    this.hasReport = this.env.reportBar;
  }

  public setSessionIdle() {
    // Session Idle timeout Configuration
    const refreshToken = Math.round(this.sessionIdleConfig / 3);
    const userTimeout = Math.round(this.sessionIdleConfig / 3);
    const userIdleTime = this.sessionIdleConfig - userTimeout; //this.sessionIdleConfig - userTimeout;

    // how long can they be inactive before considered idle, in seconds
    this.idle.setIdle(userIdleTime * 60);

    // how long can they be idle before considered timed out, in seconds
    this.idle.setTimeout(userTimeout * 60);

    // will ping at this interval while not idle, in seconds
    this.keepalive.interval(refreshToken * 60);

    // provide sources that will "interrupt" aka provide events indicating the user is active
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    

    this.idle.onTimeoutWarning.subscribe((countdown) => {

      if (countdown != null && countdown >= (userTimeout * 60)) {

        if (!this.isDialogOpen){
          this.isDialogOpen = true;
          const dialogRef = this.dialog.open(MatDialogIdleTimeoutComponent, {
            data : {
              seconds : countdown
            }
          });

          dialogRef.afterClosed().subscribe((confirmed)=>{

            if ( !confirmed ) {
              // Reset the idle
              this.idle.watch();
              this.isDialogOpen = false;
            }
            else {
              this.kc.logout("User logged out using idle timeout dialog")
            }
          });
        }
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.kc.logout("Log out user because of of inactivity");
    });

    this.keepalive.onPing.subscribe(() => {
      this.kc.getToken()
        .catch((err) => {
          console.error( err );
          this.kc.logout("Keep Alive On Ping");
        });
    });

    this.idle.watch();
  }
}
