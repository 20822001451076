import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { catchError } from 'rxjs/operators';

import { EnvService } from  "../../env.service";
import { AppSetting } from "../models/appsetting";
import { RivirHttp } from "./rivir-http.service";

import * as moment from "moment";

export class ServiceStatus {
  service: string;
  dateUpdated: Date;
  age: number;
  status: string;
  dateUpdatedDesc: string;
  ageDesc: string;
  icon: string;
}

@Injectable()
export class AppSettingService {
  private apiUrl: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.apiUrl = this.env.rivirApiBaseUrl + "/appsettings";
   }

  public getAppSetting(appKey: string) {
    const url: string = this.apiUrl + "/getAppSetting";
    const params = { appKey };

    return this.http.post(url, params, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getStorageSetting() {
    return this.http
              .get( `${this.apiUrl}/storage`, RivirHttp.getHttpOptions())
              .pipe(catchError(RivirHttp.handleError));
  }

  public updateAppSetting(userName: string, setting: AppSetting) {
    const url = this.apiUrl + "/updateAppSetting";

    setting.threatActorIdFields = setting.threatActorIdFields.replace(/\s/g, "").trim();

    const params = {
      userName: userName,
      appKey: setting.appKey,
      appVersion: setting.appVersion,
      appEnvironment: setting.appEnvironment,
      appName: setting.appName,
      appDescription: setting.appDescription,
      threatActorIdFields: setting.threatActorIdFields,
      logConfig: JSON.stringify(setting.logConfig || ""),
      stixConfig: setting.stixConfig,
      notificationConfig: setting.notificationConfig,
      searchCollection: setting.searchCollection
    };

    return this.http.post(url, params, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public updateStixObject(stixObject) {
    const params = `?where={"appKey" : "${this.env.appKey}"}`;
    const url = this.apiUrl + "/update" + params;
    return this.http.post(url, { stixConfig: stixObject }, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public async getServiceStatus(){
    const url = `${this.apiUrl}/status`;
    let status = await this.http.get<ServiceStatus[]>(url, RivirHttp.getHttpOptions())
                .pipe(catchError(RivirHttp.handleError)).toPromise();

    const currentDate = new Date().getTime();
    const ONE_MINUTE = 60 * 1000;
    const FIVE_MINUTES = 5 * 60 * 1000;

    status = status.map( s => {

      const timeDifferental = currentDate - new Date(s.dateUpdated).getTime();
      // If dateUpdated more than 5 minutes error
      if ( timeDifferental > FIVE_MINUTES ) {
        s.status = "error";
        s.icon = "report";
      }
      // If dateUpdated more than a minute ago
      else if ( timeDifferental > ONE_MINUTE ) {
        s.status = "warning";
        s.icon = "warning";
      }
      else {
        s.status = "ready";
        s.icon = "thumb_up_alt"
      }

      s.dateUpdatedDesc = moment( s.dateUpdated ).calendar();
      s.ageDesc = this.msToTime( s.age * 1000 );
      
      return s; 
    });
    return status;
  }

  private msToTime(s : number) {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;
  
    return hrs + ' Hours, ' + mins + ' Mins, ' + secs + ' Sec';
  }

}
