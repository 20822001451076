import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";


import _orderBy from "lodash-es/orderBy";
import * as moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from "@angular/material/dialog";

import { JsonDiffDialogComponent } from "../../json-diff-dialog/json-diff-dialog.component";
import { MatDialogEditThreatorComponent } from "../../mat-dialog-edit-threator/mat-dialog-edit-threator.component"; 


import { ThreatActorService } from "../threat-actor.service";
import { EnvService } from "../../env.service";
import { CaseService } from '../../case/case-service';
import { StixConfigService } from "../../shared/services/stixConfig.service";

@Component({
  selector: "app-threat-actor-details",
  templateUrl: "./threat-actor-details.component.html",
  styleUrls: ["./threat-actor-details.component.css"],
})
export class ThreatActorDetailsComponent implements OnInit {
  public threatActor = null;
  public observableData = [];
  public labels = [];
  public indicators = [];
  public indicators_queue = [];
  public loaded = false;
  public obDisplayData = [];
  public sightingFromRoute = null;
  public tabs = ["Indicators", "Cases"];
  public hasCaseManagement: boolean;
  public threatActorName: string;

  constructor(
    private threatActorService: ThreatActorService,
    private route: ActivatedRoute,
    private router: Router,
    public stixConfig: StixConfigService,
    private caseService: CaseService,
    private toastr: ToastrService,
    private env: EnvService,
    public dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.loadPage();
  }

  public loadPage() : void {
    this.hasCaseManagement = this.env.hasCaseManagement;

    this.route.queryParams.subscribe((queryParams) => {
      this.sightingFromRoute = queryParams.sighting;

      this.route.params.subscribe((params) => {
        const threatActorId_params = params.ta_id;
        this.threatActorService
          .getThreatActorDetails(threatActorId_params)
          .subscribe((threatActor) => {
            const self = this;

            self.threatActor = threatActor;
            self.threatActor.address = threatActor.address;
            self.observableData = threatActor.observableData;
            this.threatActorName = threatActor.threatActorName || "";

            // First at the  address and threat actor type
            if (threatActor.address) {
              self.obDisplayData.push({
                name: "Address",
                value: threatActor.address,
                isString: true
              });
            }

            if (threatActor.type) {
              self.obDisplayData.push({
                name: this.stixConfig.threatActor_singular + " Type",
                value: threatActor.type,
                isString: true
              });
            }

            

            // Remove all the observable data that is hidden or excluded properties
            const propertiesToExclude = ["_id", "courseofaction_id", "coajson", "courseofaction_version" ];
            self.observableData = self.observableData.filter( x => x.metaData && !x.metaData.hidden );
            self.observableData = self.observableData.filter( y => !propertiesToExclude.includes(y.name.toLowerCase()));

            // Format the response is there is a format string
            self.observableData = self.observableData.map((x) => {

              if ( Array.isArray( x.value ) ){
                if ( x.value.every(i => typeof i === "string") )
                {
                  x.value = x.value.join( ',' );
                  x.isString = true;
                }
                else {
                  x.isArray = true;
                }
                
              }
              else if ( typeof x.value === 'object' )
              {
                x.isObject = true;
              }
              else {
                x.isString = true;
                // convert to a string before formatting 
                x.value = x.value.toString();

                if (x?.metaData?.fieldType === "datetime") {
                  const dateValue = moment(x.value.trim().replace("Z", ""));
                  if (dateValue.toString() !== "Invalid Date") {
                    x.value = dateValue.format(x.metaData.momentFormat);
                  }
                }
                else if (x?.metaData?.fieldType === "number") {

                  const numericValue = parseFloat(x.value.trim());
                  x.value = !isNaN(numericValue) ? numericValue.toLocaleString(x.metaData.locale, x.metaData.options) : null;
                }
              }

              return x;
            });

            // convert all empty string sections to null 

            self.observableData = self.observableData.map( ob => {
              if ( ob.metaData ){
                ob.metaData.section = ob.metaData.section === null || ob.metaData.section === undefined || ob.metaData.section.trim() === "" ? null : ob.metaData.section;
              }
              return ob;
            });

            // Order the Reference Data(Observable Data) by Sort property
            self.observableData = _orderBy(self.observableData, [
              "metaData.section",
              "metaData.order",
            ]);

            // Add the observable data
            let currentSection = null;
            self.observableData.forEach(function (ob) {
              // If the Observable data is in a new section add it to the display array
              // so the header can show before this section
              if (ob.metaData.section && ob.metaData.section != currentSection) {
                currentSection = ob.metaData.section;
              }

              // Add the display object for the observable data
              self.obDisplayData.push({
                name: ob.metaData.description || ob.name,
                value: ob.value,
                helpText: ob.metaData.helpText,
                isString: ob.isString,
                isObject: ob.isObject,
                isArray: ob.isArray
              });
            });

            this.loaded = true;
          });
      });
    });
  }

  public async startCase(): Promise<any> {

    const availableCoas: any[] = await this.caseService.getTrimmedCoas(this.threatActor.taId).toPromise();

    if (availableCoas && availableCoas.length) {
      this.router.navigate(["/case/start", this.threatActor.taId]);
    }
    else {
      this.toastr.warning(
        `Please start at least one ${this.stixConfig.coa_singular} in order to create a case`
      );
    }


  }

  public openArrayModal( obDisplayData: any ) : void {

    this.dialog.open(JsonDiffDialogComponent, {
      data: {
        modelItemKey : obDisplayData.name,
        modalItemOriginal : obDisplayData.value,
        modalItemUpdated :null,
        modalOriginalIsArray : true,
        modalUpdatedIsArray : false,
        modalOriginalColumns : this.getColumnNames(obDisplayData.value),
        modalUpdatedColumns : null,
        showOneTable : true,
        taId: this.threatActor.taId
      }
    });
  }


  public getColumnNames(valueArray: any[]) {
    let columnNames = [];
    if (valueArray && valueArray.length > 0) {
      for (let x = 0; x < valueArray.length; x++) {
        columnNames = columnNames.concat(Object.keys(valueArray[x]));
      }

    }
    return [... new Set(columnNames)];
  }

  public editThreatActorName(){

    const dialogRef = this.dialog.open(MatDialogEditThreatorComponent, {
      data: {
        existingThreatActorName : this.threatActor.name,
        threatActorId : this.threatActor.id
      }
    });

    dialogRef.afterClosed().subscribe( editResponse => {
      if ( editResponse.status === "SUCCESS" ){
        window.location.reload();
      }
    });
  }
}
