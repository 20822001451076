import { Component, OnInit } from '@angular/core';

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IndicatorWizardModel, IndicatorWizardService, RecommendCoaTabs } from "../indicator-wizard.service";
import { ProcessDefinition } from '../../shared/services/workflow.service';
import { UserProfileService } from '../../core/userProfile.service';

import _isEqual from "lodash-es/isEqual";


@Component({
  selector: 'indicator-wiz-autostart',
  templateUrl: './indicator-wiz-autostart.component.html',
  styleUrls: ['./indicator-wiz-autostart.component.css']
})
export class IndicatorWizAutostartComponent implements OnInit {

  public allProcessDefinitions: ProcessDefinition[];
  public allUsers: any[];
  public model: IndicatorWizardModel;
  public label_coa: string;
  public label_coas: string;
  public label_indicator: string;
  public recommendedPreviews : RecommendCoaTabs[];
  public recommendedCoas : ProcessDefinition[] = [];
  public selectedIndex : number;
  public saveBoundCallback: () => void;
  public deploymentId: string;

  constructor(
    private indicatorWizardService: IndicatorWizardService, 
    public stixConfig: StixConfigService,
    private userService: UserProfileService,
  ) { 
    this.label_coa = this.stixConfig.coa_singular;
    this.label_coas = this.stixConfig.coa_plural;
    this.label_indicator = this.stixConfig.indicator_singular;
  }

  async ngOnInit() {

    this.saveBoundCallback = this.saveLocalDataToModel.bind(this);
    this.allProcessDefinitions = await this.indicatorWizardService.getAllProcessDefintions();
    let activeUsers = await this.userService.getActiveUsers().toPromise();

    activeUsers = activeUsers.map(au => {
      au.nameAndEmail = `${au.name} (${au.email})`;
      return au;
    })
    this.allUsers = activeUsers.sort((a, b) => a.name.localeCompare(b.name));
    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => {

      // Keep a local copy of the model
      this.model = indicatorWizardModel ? indicatorWizardModel : new IndicatorWizardModel();
    });

    this.indicatorWizardService.navigation.subscribe( event => {
      if ( event )  {
        this.saveLocalDataToModel();
      }
    });

  }

  public addNewAutoStartCoa(event) {
    if (!this.model.indicator.autoStartCoursesOfAction || !this.model.indicator.autoStartCoursesOfAction.length) {
      this.model.indicator.autoStartCoursesOfAction = [];
    }
    this.model.indicator.autoStartCoursesOfAction = [...this.model.indicator.autoStartCoursesOfAction, new CourseOfActionAutoStart()];
    event.preventDefault();
  }

  public showRemovePrompt(aCoa) {
    aCoa.showRemovePrompt = true;
    event.preventDefault();
  }

  public cancelRemovePrompt(aCoa) {
    aCoa.showRemovePrompt = false;
    event.preventDefault();
  }

  public removeAutoStartCoa(aCoa) {
    this.model.indicator.autoStartCoursesOfAction = this.model.indicator.autoStartCoursesOfAction.filter(
      (x) => x !== aCoa,
    );
    this.deploymentId = null;
    this.recommendedCoas = [];
  }
  
  async showPreview( selectedAutoStart, index ){

    const  selectedCoas = this.allProcessDefinitions.filter( c => selectedAutoStart.courseOfActions.includes( c.key ));
    
    if ( this.selectedIndex !== index ){
      this.selectedIndex = index;

      setTimeout( async () => {
        await this.loadBPMNViewer( selectedCoas, true );
      }, 100);
    }
    else {
      this.selectedIndex = index;
      await this.loadBPMNViewer( selectedCoas, false );
    }
  }

  async loadBPMNViewer( selectedCoas, clickedOnNewSection ) {

    try {
      let activeTab = null;
      // Check which ones is active
      if ( clickedOnNewSection  && selectedCoas.length !== 0  ){
        activeTab = selectedCoas[ selectedCoas.length - 1 ].name;
      }
      else if ( _isEqual( this.recommendedCoas, selectedCoas )  && this.recommendedCoas.length !== 0 ) {
        // This is an initial page load, set the first tab to be active
        activeTab = this.recommendedCoas[ this.recommendedCoas.length - 1 ].name;
      }
      else if ( this.recommendedCoas.length < selectedCoas.length  ){

        // this means a user has selected a coa, set that one to be active 
        const addedCoas = selectedCoas.filter(  x => !this.recommendedCoas.includes( x ));
        activeTab = addedCoas && addedCoas.length ? addedCoas.pop().name : 'Not Found';
        
      }
      else {
        // this means at least one has been removed

        // if check to see if empty, if so set to 'Not Found'
        if ( selectedCoas.length === 0 ){
          this.deploymentId = null;
        }
        else {

          
          // if the active one was removed, set the first one to be active 
          const currentActiveTab = this.recommendedCoas.find( a => a.active ).name;
          const removedCoas = this.recommendedCoas.filter( y => !selectedCoas.includes( y ));
          const removedActiveCoa = removedCoas.find(  z => z.name === currentActiveTab );
          if ( removedActiveCoa) {
            activeTab = selectedCoas[0].name;
          }
          else {
            // else leave the active tab to be the current active tab
            activeTab = currentActiveTab;
          }
        }
      }

      if ( selectedCoas.length ){
        this.deploymentId = selectedCoas[selectedCoas.length - 1].deploymentId;  
      }


      // Set the recommended coas 
      this.recommendedCoas = selectedCoas;

      for( const rc of this.recommendedCoas){
        if ( rc.name === activeTab ){
          rc.active = true;
          activeTab = "Setting to be Junk, so other tabs with the same name don't get set again";
        }
        else {
          rc.active = false;
        }
      }
    }
    catch( bpmnViewerError ){
      console.error( bpmnViewerError );
    }
  }

  
  updateDiagram( preview ){
    this.deploymentId = preview.deploymentId;
  }

  async saveLocalDataToModel() {
    const allProcessDefinitions = await this.indicatorWizardService.getAllProcessDefintions();
    if ( this.model.indicator.autoStartCoursesOfAction ){

      this.model.indicator.autoStartCoursesOfAction = this.model.indicator.autoStartCoursesOfAction.map( a => {

        if ( a.courseOfActions && a.courseOfActions.length ){

          const coaNameArray = [];
          for (const autoStartCoaId of a.courseOfActions){
            const coa = allProcessDefinitions.find( x => x.key === autoStartCoaId )
            if ( coa ){
              coaNameArray.push( coa.name );
            }
          }

          a.coasDesc = coaNameArray.join(  ", " );
        }
        else {
          a.coasDesc = "No Course of Action Selected";
        }

        return a;
      });
    }
  }
}

export class CourseOfActionAutoStart {
  // Model Properties
  public courseOfActions: string[] = [];
  public user = "";
  public daysOut = 0;
  public showRemovePrompt?: boolean;

  // View Model Properties
  public showCancelPrompt = false;
  public coaIsInvalid = false;
  public userIsInvalid = false;
  public dayOutIsInvalid = false;

  // Needs to be an Array to work with ng2-select even though it's singular
  public selectedUser: any[][];
  public selectedCourseOfAction: any[][];
  public coasDesc: string;

  public constructor(init?: Partial<CourseOfActionAutoStart>) {
      Object.assign(this, init);
  }
}