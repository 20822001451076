<div class="log-container">
  <h2> {{ localeSightingName }} </h2>
  <h3 > Status: <span class="status-{{sightingStatus}}">{{ sightingStatus }}</span> </h3>
  <div class="log-console">
      <div *ngFor="let logItem of logCollection">
        <div class="{{logItem.type}}">
          {{ logItem.message }}
        </div>
    </div>
  </div>
</div>