
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { CoAService } from "../../coa/coa.service";
import { PermissionService } from "../../core/permission.service";
import { TasksService } from "../tasks.service";
import * as moment from 'moment';
import { SightingService } from '../../shared/services/sighting.service';
import { AppSettingService } from '../../shared/services/appsetting.service';
import { EnvService } from "../../env.service";
import { SurveyService } from '../../shared/services/survey.service';
import { CoaFileUploadDialogComponent } from "../../coa-file-upload-dialog/coa-file-upload-dialog.component";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { WorkflowService } from "../../shared/services/workflow.service";
import { ToastrService } from "ngx-toastr";
import { MatDialogCoaExecuteComponent } from "../../mat-dialog-coa-execute/mat-dialog-coa-execute.component";
import { ThreatActorService } from "../../threat-actor/threat-actor.service";
import { SearchService } from "../../search/search.service";
declare var window: any;


@Component({
  selector: "app-task-details",
  templateUrl: "./task-details.component.html",
  styleUrls: ["./task-details.component.css"],
})
export class TaskDetailsComponent implements OnInit {
  public pageTitle: string = "Task Details";
  public instanceId: string;
  public courseOfAction;
  public loaded = false;
  public permission_reassignCoA: boolean = false;
  public taskId: String;
  public task: any = {};
  public userEmail: string = this.cookieService.get("email");
  public retrying: boolean = false;
  public label_coa: string;
  public label_threatActor: string;
  public label_sighting: string;
  public label_indicator: string;
  public taskName: string = "";
  public indicatorDescription: string;
  public indicatorName: string;
  public runDate: string;
  public showPending: boolean;
  public showReassign: boolean;
  public isPending: boolean = false;
  public pendingButtonText: string;
  public currentTask: any;
  public enableTabContent = false;
  public statusSubject: Subject<string> = new Subject<string>();
  public hideAssignToMeButton = false;
  public coaObsDataRows: any = [];

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    public stixConfig: StixConfigService,
    private coaService: CoAService,
    private permissions: PermissionService,
    private router: Router,
    private sightingService: SightingService,
    private cookieService: CookieService,
    private appSettingService: AppSettingService,
    private surveyService: SurveyService,
    private env: EnvService,
    public dialog: MatDialog,
    public workflowService: WorkflowService,
    public toastr: ToastrService,
    private threatActorService : ThreatActorService,
    private searchService : SearchService
  ) {
    this.tasksService.taskUpdated$.subscribe(task => {
      this.task = task;
    });
  }



  public ngOnInit(): void {
    const self = this;

    this.label_coa = this.stixConfig.coa_singular;
    this.label_threatActor = this.stixConfig.threatActor_singular;
    this.label_sighting = this.stixConfig.sighting_singular;
    this.label_indicator = this.stixConfig.indicator_singular;

    this.route.params.subscribe((params) => {
      this.instanceId = params.instanceId;
      self.getCourseOfActionDetails();
    });
  }

  public async getSightingRunDate(sightingName) {

    const sightings = await this.sightingService.getSightingsByName(sightingName).toPromise();
    const currentSighting = sightings && sightings.length > 0 ? sightings.pop() : null;
    const momentDate = currentSighting ? moment(currentSighting.CreatedDateTime) : null;
    const sightingRunDate = momentDate && momentDate.isValid() ? momentDate.format('LLL') : "";

    return sightingRunDate;
  }

  public async getCourseOfActionDetails() {
    this.taskId = "";
    let obsDataRows: any = [];

    // Get the Threat Actor
    const coa = await this.coaService.getCoursesOfActionById(this.instanceId).toPromise();

    // Get the MetaData for the Threat Actor
    const threatActorType = await this.threatActorService.getThreatActorType(coa.threatActorId).toPromise();
    const observableMetaData = await this.searchService.getObservableMetaData( threatActorType.type );
    const metaDataToDisplayOnCoa = observableMetaData.filter( x => x.displayOnCOA );
    const metaDataTypes = await this.searchService.getMetaDataTypes();

    // Add the Reference Data to the page
    const latestTask = coa.tasks[ coa.tasks.length - 1];
    if ( latestTask ){
      for( const key of Object.keys( latestTask) ){

        const metaData = metaDataToDisplayOnCoa.find( x => x.name === key )
        if ( metaData ){

          if ( !metaData.metaDataType ){
            obsDataRows.push({  name: key, value: latestTask[key] });
          }
          else {
             obsDataRows.push({  name: key, 
                                 value: this.searchService.getFormattedReferenceDataValue( latestTask[key], metaData.metaDataType, metaDataTypes ) });
          }
        }
      }
    }

    this.coaObsDataRows = obsDataRows;
    this.indicatorName = coa.indicatorName;
    this.indicatorDescription = coa.indicatorDescription;

    // User the sighting name to generate the date time 
    this.runDate = await this.getSightingRunDate(coa.sightingName);
    // Hide/Show Assign to me button
    if (coa.assignee == this.userEmail){
      this.hideAssignToMeButton = true;
    }
    else{
      this.hideAssignToMeButton = false;
    }

    this.task = {
      name: coa.taskName,
      assignee: coa.assignee,
      assigneeFullName: coa.assigneeFullName,
      id: coa.taskId
    }
    this.courseOfAction = coa || {};
    this.loaded = true;

    await this.setupPendingAndReassign(coa, coa.assignee);
  }

  public reAssign() {

    const dialogRef = this.dialog.open(MatDialogCoaExecuteComponent, {
      data: {
        currentTask: this.task
      }
    });

    dialogRef.afterClosed().subscribe( async (assignmentResponse) => {
      if (assignmentResponse?.status === "SUCCESS") {

        await this.setupPendingAndReassign(this.courseOfAction, assignmentResponse.assignee.email);

        // Check if assigned to the current person logged in
        if (assignmentResponse.assignee.email === this.cookieService.get("email")){
          this.hideAssignToMeButton = true;
          this.enableTabContent = true;
          this.task.assigneeFullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`
          this.task.assignee = this.userEmail;
        }
        else {
          this.hideAssignToMeButton = false;
          this.enableTabContent = false;
          this.task.assigneeFullName = `${assignmentResponse.assignee.firstName} ${assignmentResponse.assignee.lastName}`
          this.task.assignee = assignmentResponse.assignee.email;
        }

        // Raise message to alert the user
        this.toastr.success(`Reassigned to ${assignmentResponse.assignee.firstName} ${assignmentResponse.assignee.lastName}`);
      }
      else if ( assignmentResponse?.status === "ERROR" ){
        this.toastr.error("Failed to reassign");
      }
    });
  }

  public assignToMe() {

    this.workflowService
    .claimUserTask(this.task.id, this.userEmail)
    .subscribe(async (res) => {
      if (res.status.status == "SUCCESS") {
        
        this.toastr.success("Assignment confirmed");
        this.hideAssignToMeButton = true;
        this.enableTabContent = true;
        this.task.assigneeFullName = `${this.cookieService.get("firstName")} ${this.cookieService.get("lastName")}`
        this.task.assignee = this.userEmail;

        this.setupPendingAndReassign(this.courseOfAction, this.cookieService.get('email'));
      } else {
        this.toastr.success("COA Assign Failed");
      }
    });
  }

  public goToIndicatorPage(indicatorId) {
    this.router.navigate(["/indicators", indicatorId]);
  }

  public goToThreatactorPage() {
    const queryParams = { queryParams: { sighting: this.courseOfAction.sightingName } };
    this.router.navigate(["threatActor", this.courseOfAction.threatActorId], queryParams);
  }

  /**
   * Hides/ Shows the Pending and Re-assign buttons based on the state of the coa 
   * and if the coa is configured to have the pending button
   * @param coa Course of Action
   */
  public async setupPendingAndReassign(coa, currentAssignee) {

    // Get the permision to determine whether the current user can re-assign a course of action
    this.permission_reassignCoA = await this.permissions.getPermission("Re-assign Course of Action");

    // First check the this.env variables to see if the Pending is should be available
    let appSettings : any = await this.appSettingService.getAppSetting(this.env.appKey).toPromise();
    appSettings = appSettings.results ? appSettings.results : appSettings;
    const isCoaConfiguredForPending = appSettings.pendingCoas && appSettings.pendingCoas.includes(coa.name);

    // check if the user has normal status (i.e non pending status)
    this.currentTask = coa.tasks && coa.tasks.length ? coa.tasks[coa.tasks.length - 1] : {};
    let isNormalStatus = true;
    if (this.currentTask.Status && this.currentTask.Status.toLowerCase() === "pending") {
      isNormalStatus = false;
    }
    
    // check if the user is assigned to the current course of action 
    const isAssignedToMe = this.cookieService.get("email") === currentAssignee;

    if (isCoaConfiguredForPending) {

      if (isAssignedToMe && isNormalStatus) {

        // Show both buttons and enable 
        this.showReassign = true && this.permission_reassignCoA;
        this.showPending = true;
        this.pendingButtonText = "Set to Pending";
        this.enableTabContent = true;
      }
      else if (isAssignedToMe && !isNormalStatus) {

        // only show pending and disable
        this.showReassign = false;
        this.showPending = true;
        this.pendingButtonText = "Set to Ready";
        this.enableTabContent = false;
      }
      else if (!isAssignedToMe) {

        // show reassign and disable
        this.showReassign = true && this.permission_reassignCoA;
        this.showPending = false;
        this.enableTabContent = false;
      }
    }
    else {
      // The Course of action is not configured for pending, hide the button 
      // only have logic for Ressign
      this.showReassign = this.permission_reassignCoA;
      this.showPending = false;
      this.enableTabContent = isAssignedToMe;
    }
  }

  /**
   * Toggles the status between Pending and the previous status in the course of action
   * and saves that status back to the api
   */
  public async togglePending() {

    if (this.pendingButtonText === "Set to Pending") {


      const coa = await this.coaService.getCoursesOfActionById(this.instanceId).toPromise();
      this.currentTask = coa.tasks[coa.tasks.length - 1];

      this.currentTask.Status = "Pending";
      this.currentTask.isPending = true;
      await this.surveyService.saveTask(this.currentTask, this.currentTask.taskId, this.currentTask.processInstanceId, this.cookieService.get("email"));

      this.enableTabContent = false;
      this.pendingButtonText = "Set to Ready";
      this.showReassign = false;
    }
    else {

      // Get the current version of the coa to get the previous status 
      const coa = await this.coaService.getCoursesOfActionById(this.instanceId).toPromise();
      const nonPendingTasks = coa.tasks.filter(c => c.Status !== "Pending");
      this.currentTask.Status = nonPendingTasks && nonPendingTasks.length ? nonPendingTasks[nonPendingTasks.length - 1].Status : "";
      this.currentTask.isPending = false;
      await this.surveyService.saveTask(this.currentTask, this.currentTask.taskId, this.currentTask.processInstanceId, this.cookieService.get("email"));

      this.enableTabContent = true;
      this.pendingButtonText = "Set to Pending";
      this.showReassign = true && this.permission_reassignCoA;
    }

    // Refresh the Survey
    this.statusSubject.next(this.currentTask.Status);
  }

  public uploadFile() {
    this.dialog.open(CoaFileUploadDialogComponent, {
      data: {
        task : this.task
      },
    });
  }
}
