<div class="modal-header" *ngIf="isModelView">
  <!-- <span class="glyphicon glyphicon-road pull-left" style="margin-top:1em;margin-right:1em;"></span> -->
  <h2 class="modal-title pull-left">{{modelName}}</h2>
</div>
<div class="{{isModelView? 'modal-body': ''}}">
  <div *ngIf="!loaded" class="loader">Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div *ngIf="loaded">
    <div class="container-fluid" *ngIf="courseOfAction">
      <h2 class="row col-sm-12 m-b-lg">
        <span class="id-label model-{{isModelView}}">
          <i class="m-r-sm glyphicon glyphicon-road"></i>{{courseOfAction.name}}</span>
      </h2>
      <div class="main-details">
        <div class="row">
          <div class="col-md-3 coa-details-label">
            {{label_threatActor}} Name:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.threatActorName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Date Initiated:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.dateCreated | date: 'short'}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Initiated By:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.startedBy_fullName || courseOfAction.startedBy}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Assigned To:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.assignee_fullName || courseOfAction.assignee}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Due Date:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.coaDueDate | date: 'M/d/yyyy'}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Current Task:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.taskName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 coa-details-label">
            Status:
          </div>
          <div class="col-md-{{isModelView? '6': '3'}}">
            {{courseOfAction.status}}
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12 coa-details-header">
          <h3 class="hidden">History</h3>
          <h4>History</h4>
        </div>
      </div>
      <div *ngIf="courseOfAction.history.length == 0">
        There are no History for this {{label_coa}}
      </div>
      <div *ngFor="let logItem of courseOfAction.history" class="row">
        <div class="{{isModelView? 'col-md-12': 'col-md-8'}}">

          <div class="panel panel-default" id="activity{{logItem.key}}"
            *ngIf="logItem && logItem.action != 'reassign COA'">
            <div class="panel-heading">
              <a class="user-link" (click)="viewUserProfile(logItem, null)">{{logItem.CreatedByFullName}} </a>
              {{logItem.action}}
              <span class="task-name"> {{logItem.taskName}} </span> - {{logItem.CreateDateTime | date: 'short'}}
            </div>
            <div class="row" *ngIf="logItem.changes.length > 0">
              <div class="col-sm-12">
                <table class="table table-striped b-t b-b changesTable no-footer" role="grid">
                  <thead>
                    <tr role="row">
                      <th *ngFor="let column of columns" class="{{'th-'+column.prop}}">
                        {{column.name}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of logItem.changes" role="row">
                      <ng-container *ngIf="!item.isArray">
                        <td *ngFor="let column of columns" class="{{'td-'+column.prop}}">
                          <span [innerHtml]="item[column.prop]"></span>
                        </td>
                      </ng-container>
                      <ng-container *ngIf="item.isArray">
                        <td class="td-key">
                          <span [innerHtml]="item.key"></span>
                        </td>
                        <td class="td-view-changes" colspan="2">
                          <a (click)="openDiffModal(item)" class="diff-link">View Differences</a>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row no-values" *ngIf="logItem.changes.length == 0 && logItem.taskName !== 'Start Workflow'">No
              Values Updated.</div>
          </div>
          <div class="panel panel-default" *ngIf="logItem && logItem.action == 'reassign COA'"
            id="activity{{logItem.key}}">
            <div class="panel-heading">
              {{label_coa}} has been reassigned from <a class="user-link m-s"
                (click)="viewUserProfile(logItem,'prevAssignee')">
                {{logItem.prevAssigneeFullName}} </a> to <a class="user-link m-s"
                (click)="viewUserProfile(logItem,'newAssignee')">
                {{logItem.newAssigneeFullName}} </a> - {{logItem.CreateDateTime | date: 'short'}}
            </div>
          </div>
        </div>
      </div>

      <div class="row coa-task-docs">
        <task-documents [instanceId]="instanceId" [readOnly]="true">
        </task-documents>
      </div>
    </div>
  </div>
</div>

<app-task-note [isHidden]="isHidden" [instanceId]="instanceId"></app-task-note>