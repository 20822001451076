import { ModuleWithProviders, NgModule } from "@angular/core";
import { Route, RouterModule, Routes } from "@angular/router";
import { EnvServiceFactory } from "./env.service.provider";
import { MainViewComponent } from "./mainview/mainview.component";
import { SightingsViewComponent } from "./sightings-view/sightings-view.component";
import { AccountRequestViewComponent } from "./admin-settings/accountrequestview/accountrequestview.component";
import { BulkStatusComponent } from "./bulk-status/bulk-status.component";
import { CoARoutes, CoARoutingComponents } from "./coa/coa.routes";
import { ErrorsHandler } from "./core/errors-handler/errors-handler.component";
import { NotificationsPageComponent } from "./core/notifications-page/notifications-page.component";
import { CaseListingComponent } from "./case/case-listing/case-listing.component";
import {
  IndicatorsRoutes,
  IndicatorsRoutingComponents,
} from "./indicators/indicators.routes";
import { SightingLogComponent } from "./indicators/sighting-log/sighting-log.component";
import { SightingReportComponent } from "./indicators/sighting-report/sighting-report.component";
import { ReportComponent } from "./report/report.component";
import { ReportpreviewComponent } from "./report/reportpreview/reportpreview.component";
import { SearchRoutes, SearchRoutingComponents } from "./search/search.routes";
import { TasksComponents } from "./tasks/tasks.routes";
import {
  ThreatActorRoutes,
  ThreatActorRoutingComponents,
} from "./threat-actor/threat-actor.routes";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HelpfulLinksComponent } from './helpful-links/helpful-links.component';
import { CaseDetailComponent } from './case/case-detail/case-detail.component';
import { CaseStartComponent } from './case/case-start/case-start.component';
import { DataEntryComponent } from "./data-entry/data-entry.component";
import { DataEntryFormComponent } from "./data-entry-form/data-entry-form.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { TableauComponent } from "./tableau/tableau.component";
import { DataEntryListComponent } from "./data-entry-list/data-entry-list.component";

const env = EnvServiceFactory();

const defaultRoute: Route = env.redirectDashboard.enable
  ? {
    path: "",
    redirectTo: env.redirectDashboard.to,
    pathMatch: "full",
    data: { text: "Dashboard", mobileNav: true },
  }
  :
   {
    path: "",
    component: MainViewComponent,
    pathMatch: "full",
    data: { text: "", breadcrumbs: false, mobileNav: true },
  };

export let routes: Routes = [
  defaultRoute,
  {
    path: "search",
    children: SearchRoutes,
    data: {
      text: "Search",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "search",
      module: "search"
    },
  },
  {
    path: "data-entry",
    component: DataEntryComponent,
    pathMatch: "full",
    data: {
      text: "dataEntry_singular",
    },
  },
  {
    path: "data-entry-list",
    component: DataEntryListComponent,
    pathMatch: "full",
    data: {
      text: "dataEntry_singular",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "plus-sign",
      module: "data-entry"
    },
  },
  {
    path: "data-entry/:processDefintionKey",
    component: DataEntryFormComponent,
    data: { text: "%SLUG%", breadcrumbs: true }
  },
  {
    path: "data-entry/:processDefintionKey/:dataEntryId",
    component: DataEntryFormComponent,
    data: { text: "%SLUG%", breadcrumbs: true }
  },
  {
    path: "indicators",
    children: IndicatorsRoutes,
    data: {
      text: "indicator_plural",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "th-list",
    },
  },
  {
    path: "threatActor",
    children: ThreatActorRoutes,
    data: {
      text: "threatActor_plural",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "user",
    },
  },
  {
    path: "case/listing",
    component: CaseListingComponent,
    pathMatch: "full",
    data: {
      text: "Cases",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "folder-open",
      module: "case-management"
    },
  },
  {
    path: "coa",
    children: CoARoutes,
    data: {
      text: "coa_plural",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "road",
    },
  },
  // Commenting out tasks route but keeping code here in case the route is re-enabled.
  // {
  //   path: "taskList",
  //   children: TasksRoutes,
  //   data: {
  //     text: "Tasks",
  //     nav: true,
  //     mobileNav: true,
  //     breadcrumbs: true,
  //     icon: "tasks",
  //   },
  // },
  // Commenting out sightings route but keeping code here in case the route is re-enabled.
  //
  // {
  //   path: 'sightings', component: SightingsViewComponent, pathMatch: 'full',
  //   data: {text: 'sighting_plural', nav: true, breadcrumbs: true, icon: 'map-marker' }
  // },
  {
    path: "contactus",
    component: ContactUsComponent,
    pathMatch: "full",
    data: { text: "Contact Us", breadcrumbs: true, icon: "tasks" },
  },
  {
    path: "helpfulLinks",
    component: HelpfulLinksComponent,
    pathMatch: "full",
    data: { text: "Helpful Links", breadcrumbs: true, icon: "tasks" },
  },
  {
    path: "contactus",
    component: ContactUsComponent,
    pathMatch: "full",
    data: { text: "Contact Us", breadcrumbs: true, icon: "tasks" },
  },
  {
    path: "helpfulLinks",
    component: HelpfulLinksComponent,
    pathMatch: "full",
    data: { text: "Helpful Links", breadcrumbs: true, icon: "tasks" },
  },
  {
    path: "report",
    data: {
      text: "visualization_plural",
      nav: true,
      mobileNav: true,
      breadcrumbs: true,
      icon: "stats",
      module: "report"
    },
    children: [
      { 
        path: "",  
        component: ReportComponent, 
      },
      { 
      path: "reportpreview/:id", component: ReportpreviewComponent, 
        data: { 
          text: "reportname", 
          breadcrumbs: true,
          icon: "stats"
        }
      }
    ]
  },
  {
    path: "tableau",
    component: TableauComponent,
    pathMatch: "full",
    data: {
      text: "Choose a visualization_singular",
      nav: env.reportBar,
      mobileNav: true,
      breadcrumbs: true,
      icon: "signal",
      module: "tableau"
    },
  },

  {
    path: "error",
    component: ErrorsHandler,
    data: { text: "Error" },
  },
  {
    path: "notificationList",
    component: NotificationsPageComponent,
    pathMatch: "full",
    data: {
      text: "Notification History",
      mobileNav: true,
      mobileNavText: "Alerts",
      breadcrumbs: true,
    },
  },
  {
    path: "accountsettings",
    component: AccountRequestViewComponent,
    pathMatch: "full",
    data: {
      text: "Profile",
      mobileNav: true,
      mobileNavText: "Profile",
      breadcrumbs: true,
      editable: true,
    },
  },

  {
    path: "settings",
    loadChildren: () => import('./admin-settings/module').then(c => c.AdminSettingsModule),
    data: { text: "Settings", breadcrumbs: true },
  },
  {
    path: "case/:caseId",
    component: CaseDetailComponent,
    data: { text: "Case", breadcrumbs: true, module: "case-management" }
  },
  {
    path: "case/start/:taId",
    component: CaseStartComponent,
    data: { text: "Start Case", breadcrumbs: true, module: "case-management" }
  },
  {
    path: "case/edit/:caseId",
    component: CaseStartComponent,
    data: { text: "Edit Case", breadcrumbs: true, module: "case-management" }
  },
  {
    path: "case/courseofaction/:taId/:sightingName",
    component: CaseStartComponent,
    data: { text: "Start coa_singular", breadcrumbs: true, module: "case-management" }
  },
  {
    path: "bulkstatus/:instanceId",
    component: BulkStatusComponent,
    data: { text: "Bulk Status", breadcrumbs: true },
  },
  {
    path: "file-upload",
    component: FileUploadComponent,
    data: { text: "File Upload Demo", breadcrumbs: true },
  },
  { pathMatch: "full", path: "**", redirectTo: "" },
];

if (!env.searchBar){
  routes = routes.filter(r => r.data ? r.data.module !== "search" : true);
}

if (!env.reportBar){
  routes = routes.filter(r => r.data ? r.data.module !== "report" : true);
}

if (!env.hasCaseManagement) {
  routes = routes.filter(r => r.data ? r.data.module !== "case-management" : true);
}

if (!env.hasDataEntry) {
  routes = routes.filter(r => r.data ? r.data.module !== "data-entry" : true);
}

if (!env.tableau) {
  routes = routes.filter(r => r.data ? r.data.module !== "tableau" : true);
}


export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot( routes );

export const routingComponents = [
  IndicatorsRoutingComponents,
  CoARoutingComponents,
  ThreatActorRoutingComponents,
  SearchRoutingComponents,
  MainViewComponent,
  TasksComponents,
  SightingsViewComponent,
  ReportComponent,
  ErrorsHandler,
  BulkStatusComponent,
];

export const routingEntryComponents = [
  SightingLogComponent,
  SightingReportComponent,
];

@NgModule({
  imports: [routing],
  exports: [RouterModule],
})
export class AppRoutingModule { }