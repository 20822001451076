import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { EnvService } from "../../../app/env.service"
import { CourseOfActionDetails } from "../../shared/models/CourseOfActionDetails";
import { SightingService } from "../../shared/services/sighting.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { MatDialog } from "@angular/material/dialog";
import { JsonDiffDialogComponent } from "../../json-diff-dialog/json-diff-dialog.component";

@Component({
  selector: "app-coa-details",
  templateUrl: "./coa-details.component.html",
  styleUrls: ["./coa-details.component.css"],
})
export class CoaDetailsComponent implements OnInit {
  public courseOfActionId: string;
  public courseOfAction: CourseOfActionDetails;
  public loaded = false;
  public columns: any[];
  public permission_reassignCoA = false;
  public label_threatActor: string;
  public label_coa: string;
  public sortKey = "key";
  public asc = true;
  public activityId: string;
  public instanceId: string;
  public statusMessage: string;
  public closeBtnName: string;
  public modelName: string;
  public isHidden = true;

  @Input() public coaInstanceId: string;
  @Input() public isModelView: boolean;

  constructor(
    private route: ActivatedRoute,
    private userService: UserProfileService,
    private router: Router,
    public stixConfig: StixConfigService,
    private sightingService: SightingService,
    private permissions: PermissionService,
    private env: EnvService,
    public dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.route.params.subscribe((params) => {
      this.label_threatActor = this.stixConfig.threatActor_singular;
      this.label_coa = this.stixConfig.coa_singular;

      // Get the Course of Action Details using the id from the url
      this.courseOfActionId = params.id;
      this.instanceId = params.id;
      this.activityId = params.activityId;
      this.getCourseOfActionDetails();
    });

    // Columns for History changed values.
    this.columns = [
      { prop: "key", name: "Field" },
      { prop: "original", name: "Original Value" },
      { prop: "updated", name: "Updated Value" },
    ];

    this.permissions.getPermission("Re-assign Course of Action").then((p) => {
      this.permission_reassignCoA = p;
    });
  }

  public ngAfterViewInit(): void {
    try {
      if (this.activityId) {
        setTimeout(() => {
          document.querySelector("#activity" + this.activityId).scrollIntoView();
        }, 500);

      }
    } catch (e) {
      console.log(e);
     }
  }

  public getCourseOfActionDetails() {
    this.userService.getActiveUsers().subscribe((users) => {
      this.sightingService
        .getCourseOfActionDetails(this.courseOfActionId)
        .subscribe((courseOfAction) => {
          if (courseOfAction.history) {
            this.formatCourseOfActionDetails(courseOfAction, users);
          } else {
            // Dont refine.. this.coaInstanceId only works after the first call subscribes
            this.sightingService
              .getCourseOfActionDetails(this.coaInstanceId)
              .subscribe((courseOfAction) => {
                this.formatCourseOfActionDetails(courseOfAction, users);
              });
          }
        });
    });
  }

  /**Format the values for Original and updated.  Converts Due dates to MM/DD/YYY string, coverts bools to Yes/No and
   * replaces carraige returns with <br/> so that the values can be displayed as HTML
   * @param logEntryKey Key Description for the Log Entry
   * @param logEntryValue Value for original or updated
   * @returns formated values for original or updated
   */
  public formatLogValue(logEntryKey: string, logEntryValue: any) {

    const islogEntryKeyDate = logEntryKey && (logEntryKey.toLowerCase()).indexOf("date") !== -1;
    if (islogEntryKeyDate && Date.parse(logEntryValue)) {
      logEntryValue = moment(logEntryValue).format("L");
    } else if (logEntryKey && logEntryKey.endsWith("Match")) {
      if (logEntryValue === "false" || logEntryValue === false) {
        logEntryValue = "No Match";
      } else if (logEntryValue === "true" || logEntryValue === true) {
        logEntryValue = "Match";
      }
    } else {
      if (logEntryValue == "true" || logEntryValue === true) {
        logEntryValue = "Yes";
      } else if (logEntryValue == "false" || logEntryValue === false) {
        logEntryValue = "No";
      } else if (typeof logEntryValue === "object" &&  !Array.isArray(logEntryValue) && logEntryValue) {

        let objectEntries = "";
        for (const [key, value] of Object.entries(logEntryValue)) {
          objectEntries += `<div> ${key} : ${value} </div>`;
        }
        logEntryValue = objectEntries;
      } else if ( typeof logEntryValue === 'string' || logEntryValue instanceof String ) {
        logEntryValue = logEntryValue.toString().replace(/(\r\n|\n|\r)/g, "<br />");
      }
    }

    return logEntryValue;
  }

  public formatCourseOfActionDetails(courseOfAction, users) {
    this.courseOfAction = courseOfAction;

    // Clear out "Start Workflow" Task and other tasks without a task name from history except due date history.
    this.courseOfAction.history = this.courseOfAction.history.filter(
      (x) =>
        (x.taskName && x.taskName !== "Start Workflow") ||
        (x.action &&
          (x.action.indexOf("Due Date") != -1 ||
            x.action.indexOf("reassign COA") != -1)),
    );

    // Format the values for original and updates
    this.courseOfAction.history.forEach((historyItem) => {
      historyItem.changes.map((logEntry) => {

        // Refactor the logic to determine isArray
        logEntry.isArray = (logEntry.original && logEntry.original.constructor === Array && logEntry) ||
          (logEntry.updated && logEntry.updated.constructor === Array);

        if (logEntry.isArray) {
          if (logEntry.original && logEntry.original.length > 0 && typeof (logEntry.original[0]) === "string") {
            logEntry.isArray = false;
          }
          else if (logEntry.updated && logEntry.updated.length > 0 && typeof (logEntry.updated[0]) === "string") {
            logEntry.isArray = false;
          }
        }

        logEntry.original = this.formatLogValue(
          logEntry.key,
          logEntry.original,
        );
        logEntry.updated = this.formatLogValue(logEntry.key, logEntry.updated);

      });
    });

    // Check to see we should get the status from the reference data in the course
    // of action instead of top-level status
    courseOfAction.status_original = courseOfAction.status;
    if (this.env.courseOfActionStatus) {
      const lastTask = courseOfAction.tasks[courseOfAction.tasks.length - 1];
      courseOfAction.status = lastTask[this.env.courseOfActionStatus]
        ? lastTask[this.env.courseOfActionStatus]
        : courseOfAction.status;
    }

    this.getUsersFullNameFromEmails(courseOfAction, users);

    // remove duplicates
    courseOfAction = this.removeDuplicates(courseOfAction);

    this.loaded = true;
  }

  public removeDuplicates(courseOfAction) {
    const trimmedList: any[] = [];
    let i: number;
    trimmedList.push(courseOfAction.history[0]);
    for (i = 1; i < courseOfAction.history.length; i++) {

      if ((courseOfAction.history[i].changes.length === 1) &&
        (courseOfAction.history[i - 1].changes.length === 1)) {

        const currentChange = courseOfAction.history[i].changes;
        const previousChange = courseOfAction.history[i - 1].changes;

        if ((currentChange[0].key === previousChange[0].key) &&
          (currentChange[0].updated === previousChange[0].updated) &&
          (currentChange[0].original === previousChange[0].original)) {
          console.log("Removed duplicate entry from history");
        }
        else {
          trimmedList.push(courseOfAction.history[i]);
        }
      }
      else {
        trimmedList.push(courseOfAction.history[i]);
      }
    }
    courseOfAction.history = trimmedList;
    return courseOfAction;
  }

  public viewUserProfile(logItem, prop) {
    const userEmail = prop
      ? logItem[prop]
      : logItem.CreatedBy || logItem.StartedBy;
    if (userEmail) {
      this.userService.getUserProfileByEmail(userEmail).subscribe((res) => {
        const user = res[0] || res || null;
        if (user.id) {
          this.router.navigate(["userProfile", user.id], {
            relativeTo: this.route,
          });
        }
      });
    }
  }

  /**
   * Creates Full Name properties based on the email addresses
   * @param courseOfAction Course of Action
   * @param users List of all users
   */
  public getUsersFullNameFromEmails(courseOfAction, users) {
    if (courseOfAction.assignee) {
      this.courseOfAction.assignee_fullName = this.getFullName(
        users,
        courseOfAction.assignee,
      );
    }
    if (courseOfAction.startedBy) {
      this.courseOfAction.startedBy_fullName = this.getFullName(
        users,
        courseOfAction.startedBy,
      );
    }
    this.courseOfAction.history.forEach((x) => {
      // Set made the change
      if (x.CreatedBy) {
        x.CreatedByFullName = this.getFullName(users, courseOfAction.CreatedBy);
      } else if (x.StartedBy) {
        x.CreatedByFullName = this.getFullName(users, courseOfAction.StartedBy);
      }

      // If this is a re-assignment set update the new fields so that it will
      x.prevAssigneeFullName = x.action === "reassign COA" &&
        !x.prevAssigneeFullName &&
        x.prevAssignee ? this.getFullName(users, x.prevAssignee) : "";

      x.newAssigneeFullName = x.action === "reassign COA" &&
        !x.newAssigneeFullName &&
        x.newAssignee ? this.getFullName(users, x.newAssignee) : "";

      // If this is a re-assignment set update the new fields so that it will
      // show differently on the UI
      if (
        x.changes &&
        x.changes.length === 1 &&
        x.changes[0].key === "assignee"
      ) {
        x.action = "reassign COA";
        x.prevAssigneeFullName = this.getFullName(users, x.changes[0].original);
        x.newAssigneeFullName = this.getFullName(users, x.changes[0].updated);
      }
    });
  }

  /**
   * Get's the user's full name from their email address, if user is not found returns the email address by default
   * @param users List of users
   * @param email Email address
   */
  public getFullName(users, email) {
    let user = null;
    if (email) {
      user = users.find((u) => u.email == email);
    }

    return user ? `${user.firstName} ${user.lastName}` : email;
  }

  public getImageUrl(fileType) {
    const baseUrl = this.env.rivirApiBaseUrl.replace("/api", "");

    let imageUrl = baseUrl + "/images/document-icon.png";

    switch (fileType.toLowerCase()) {
      case "pdf":
        imageUrl = baseUrl + "/images/pdf-icon.png";
        break;
      case "doc":
      case "docx":
      case "rtf":
        imageUrl = baseUrl + "/images/word-icon.png";
        break;
      case "xls":
      case "xlsx":
      case "csv":
        imageUrl = baseUrl + "/images/excel-icon.png";
        break;
      case "ppt":
      case "pptx":
        imageUrl = baseUrl + "/images/ppt-icon.png";
        break;
      case "tar":
      case "rar":
      case "7z":
      case "zip":
        imageUrl = baseUrl + "/images/zip-icon.png";
        break;
      case "jpeg":
      case "gif":
      case "png":
      case "tiff":
        imageUrl = baseUrl + "/images/photo-icon.png";
        break;
    }
    return imageUrl;
  }

  public openDiffModal(item: any) {


    this.dialog.open(JsonDiffDialogComponent, {
      data: {
        modelItemKey : item.key,
        modalItemOriginal : item.original,
        modalItemUpdated : item.updated,
        modalOriginalIsArray : (item.original && item.original.constructor === Array),
        modalUpdatedIsArray : (item.updated && item.updated.constructor === Array),
        modalOriginalColumns : this.getColumnNames(item.original),
        modalUpdatedColumns : this.getColumnNames(item.updated),
        taId: this.courseOfAction.taId
      }
    });

  }

  public getColumnNames(valueArray: any[]) {
    let columnNames = [];
    if (valueArray && valueArray.length > 0) {
      for (let x = 0; x < valueArray.length; x++) {
        columnNames = columnNames.concat(Object.keys(valueArray[x]));
      }

    }
    return [... new Set(columnNames)];
  }
}
