<div class="content add-margin">
  <div class="row">
    <div class="col-1 col-sm-2 col-md-1 col-lg-1">
      <h4 style="padding-left:25px;">Search:</h4>
    </div>
    <div class="col-11 col-sm-10 col-md-11 col-lg-11">
      <input type="text" id="userSearch" class="form-control input-styles" [(ngModel)]="searchItem" (keydown.enter)="getWorkbooks()" placeholder="Enter keywords"/>
    </div>
  </div>
</div>

<br/><br/><br/>
      <div *ngIf="!loaded" class="loader">
        Loading
        <span class="loader__dot">.</span>
        <span class="loader__dot">.</span>
        <span class="loader__dot">.</span>
      </div>
      <div class="content" *ngIf="!isWorkBookSelected">
        <div class="row">
          <div class="col-3 col-sm-6 col-md-4 col-lg-3 align-items-center justify-content-center" *ngFor="let i of allWorkBooks;let index = index" (click)="select(i.workBookId)">
  
            <mat-card class="example-card" style="height:335px;" [style.border]="selected==i?'2px solid #27b7e0':'#ffffff'" (click)="selected=i"> 
  
              <mat-card-header>
                <mat-card-title class="mat-card-size">

                </mat-card-title>
                <mat-card-content class="reporting-mat-content">
                  <div>
                    <img class="card-img-top dashboard-image" [src]="i.previewImage" lt="Workbook Image">
                    <br/><br/>
                    <b>{{i.workbookName}}</b>
                    <br/>
                    {{i.parentProject.name}}
                    <br/>
                    {{i.lastUpdatedDate}}
                  </div>
                </mat-card-content>
                <mat-card-subtitle>
              </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <br/>
              </mat-card-content>
            </mat-card>
            <br/>
          </div>
        </div>
    </div>
    
  
  
  
  
  
  
  
  