import { Component, Renderer2, Inject } from "@angular/core";

import { Model } from "survey-core";
import { DefaultLight } from "survey-core/themes/default-light";

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { SurveyService } from "../shared/services/survey.service";

@Component({
  selector: 'app-mat-dialog-indicator-parameters',
  templateUrl: './mat-dialog-indicator-parameters.component.html',
  styleUrl: './mat-dialog-indicator-parameters.component.css'
})
export class MatDialogIndicatorParametersComponent {

  model: any;
  surveyIsLoading: boolean;
  disableSaveSubmit: boolean;
  public pages: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IndicatorParametersDialogData,
    private dialogRef: MatDialogRef<MatDialogIndicatorParametersComponent>,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private surveyService: SurveyService
  ){

    this.model = new Model( this.data.surveyJson );

    // Set the primary background to be same color as the buttons before applying the theme
    DefaultLight.cssVariables["--sjs-primary-backcolor"] = 'rgba(51, 122, 183, 1)';
    DefaultLight.cssVariables["--sjs-editorpanel-backcolor"] = "#f3f3f3";
    this.model.applyTheme( DefaultLight );
    this.model.onAfterRenderPage.add(( sender, { htmlElement, page } ) => {
      let dynamicScriptToAddToThePage = null;
      for (const pageElement of page.jsonObj.elements){
          if (pageElement.type === 'html'){
            let parser = new DOMParser();
            const doc = parser.parseFromString(pageElement.html, "text/html");
            const scriptElementsFromHtml = doc.getElementsByTagName('script');
            if (scriptElementsFromHtml && scriptElementsFromHtml.length){
              dynamicScriptToAddToThePage = scriptElementsFromHtml[0].innerText;
            }
          }
      }

      if ( dynamicScriptToAddToThePage ){
        dynamicScriptToAddToThePage = ` debugger; \n ${dynamicScriptToAddToThePage}`;
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.text = dynamicScriptToAddToThePage;
        this.renderer2.appendChild(this._document.body, s);
      }
      this.surveyIsLoading = false;
    });

    this.model.showCompletedPage = false;
    this.model.showNavigationButtons = false;
    this.model.showPageTitles = false;
    this.pages = this.model.pages;

  }

  close(){
    this.dialogRef.close({message: "SUCCESS"});
  }

  public nextPage() {
    this.model.nextPage();
  }

  public prevPage() {
    this.model.prevPage();
  }

  public getPage($event, page) {
    $event.stopPropagation();
    $event.preventDefault();
    this.model.currentPageNo = page;
  }

  public complete(){
    const isPageValid = !this.model.hasErrors(true, true);
      
    if (isPageValid) {
      let modelData = this.surveyService.deDuplicateValues(this.model.data);
      modelData = this.surveyService.replaceNonUnicodeCharacters( modelData );

      this.dialogRef.close({modelData});
    }
  }

}

export interface IndicatorParametersDialogData {
  surveyJson: any; 
}
