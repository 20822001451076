import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { IndicatorWizardModel, IndicatorWizardService } from '../indicator-wizard.service';
import { AlgorithmService } from  '../../indicators/nerdy-algorithm-list/nerdy-algorithm-list.service';



@Component({
  selector: 'app-indicator-wizard',
  templateUrl: './indicator-wizard-parent.component.html',
  styleUrls: ['./indicator-wizard-parent.component.css'],
  providers: [
  ],
})
export class IndicatorWizardParentComponent implements OnInit {
  indicatorId: string;
  @ViewChild('stepper') private stepper: MatStepper;
  private initialPageLoad = true;


  constructor(
    private indicatorService: AlgorithmService,
    private indicatorWizardService: IndicatorWizardService,
    private route: ActivatedRoute,
    public stixConfig: StixConfigService 
    ) { }

  async ngOnInit() {

    this.indicatorId = await this.getIndicatorId();

    if ( this.indicatorId ) {
      this.initialPageLoad = true;
      const indicator = await this.indicatorService.getAlgorithm( this.indicatorId ).toPromise();
      const indicatorWizardModel = new IndicatorWizardModel( indicator );
      this.indicatorWizardService.changeData( indicatorWizardModel );
      
    }
    else {
      this.initialPageLoad = false;
      const indicatorWizardModel = new IndicatorWizardModel();
      this.indicatorWizardService.changeData( indicatorWizardModel );
    }

    this.indicatorWizardService.currentData.subscribe( indicatorWizardModel => { 

      if ( (indicatorWizardModel.currentStep !== null &&  indicatorWizardModel.currentStep !== undefined) && 
            this.stepper && 
           this.stepper.selectedIndex != indicatorWizardModel.currentStep ) {
        this.stepper.selectedIndex = indicatorWizardModel.currentStep;
      }
    } );
  }

  selectionChange( event : StepperSelectionEvent ) {
    if ( event.previouslySelectedIndex !== event.selectedIndex && !this.initialPageLoad ){
      this.indicatorWizardService.navigationChanged( event );
    }

    this.initialPageLoad = false;
  }

  async getIndicatorId(): Promise<string> {

    return new Promise(( resolve ) => {
      this.route.params.subscribe(async (params) => {
        resolve( params.indicatorId );
      });
    });
  }


}
