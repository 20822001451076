import { AfterViewInit, Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { EnvService } from "../env.service";
import { StixConfigService } from "../shared/services/stixConfig.service";
import { PermissionService } from "../core/permission.service";
import { TABLE_COLUMNS_LIST_SEARCHABLE } from "../threat-actor/threat-actor-list/threat-actor-list-columns";
import { SearchService } from "./search.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements AfterViewInit {

  public rows = [];
  public original_rows = [];
  public columns = [];
  public tableTitle: string;
  public p: number;
  public limitRowsOptions = [50, 100, 150, 200];
  public limitRows: any;
  public tableInfo: string = "";
  public sortKey = "updateddatetime";
  public asc = false;
  public loaded = false;
  public totalRecords: number;
  public totalAllRecords: number;
  @Input() public recommendedCOAs;
  @Input() public sightingParams;
  public searcher: {} = {};
  public searcherSubject: Subject<any> = new Subject<any>();
  public searchableColumns: any[] = [];
  public lockFilters: boolean = false;
  public searchMetaData: any[] = [];
  public perm_accessCamunda = false;
  private eDVTypeName = "EDV";
  public searchFailed = false;
  public intervalId: any;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    public stixConfig: StixConfigService,
    private permissions: PermissionService,
    private env: EnvService) {
    this.tableTitle = "Search List";
    this.p = this.searchService.criteria.pageNumber;
    this.limitRows = this.searchService.criteria.pageLimit;
    this.sortKey = this.searchService.criteria.sortBy;
    this.asc = this.searchService.criteria.asc;
    if (this.searchService.criteria.advanceFilter) {
      const advanceFilter = this.searchService.criteria.advanceFilter;
      Object.keys(advanceFilter).forEach((prop) => {
        this.searcher[prop] = advanceFilter[prop];
      });
    }
  }

  public async ngAfterViewInit() {

    this.loaded = false;

    // Get the Searchable columns from the Observable Metadata
    this.searchMetaData = await this.searchService.getSearchableDataFields(this.eDVTypeName).toPromise();
    for (const field of this.searchMetaData) {
      this.searchableColumns.push({ prop: field.name, name: field.description || field.name });
    }

    // Initial load for the Search table
    this.updateTable();

    this.intervalId = setInterval(() => {
      this.updateTable(); 
    }, 3000);


  }

  public ngOnInit() {
    this.searcherSubject
      .pipe(debounceTime(800))
      .subscribe((params) => {
        this.searchService.modifyAdvanceFilter(params.prop, params.string);
        this.p = 1;
        this.updateTable();
      });
    this.permissions.getPermission("Access Camunda").then((p) => { this.perm_accessCamunda = p; });

  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public filterChange(prop, string) {
    if (this.searcher && Object.keys(this.searcher).length <= 4) {
      this.searcherSubject.next({ prop, string });
    }
    if (!string) {
      delete this.searcher[prop];
    }
  }

  public filterClick(prop) {
    // lock other filter fields if exceeded 4 filters at a time.
    if (!this.searcher[prop] && Object.keys(this.searcher).length >= 4) {
      this.lockFilters = true;
      alert("You have exceeded the maximum number of filters");
    } else {
      this.lockFilters = false;
    }
  }

  public updateTable() {
    this.searchService.criteria.pageNumber = this.p;
    this.searchService.criteria.pageLimit = parseInt(this.limitRows);
    this.searchService.criteria.sortBy = this.sortKey;
    this.searchService.criteria.asc = this.asc;
    this.fetchThreatActorList();
  }

  public convertAllKeysToLowerCase( searchRows ){

    const formattedItems = [];
    for ( const mixedCaseObject  of searchRows ){
      const mixedCaseKeys = Object.keys( mixedCaseObject );
      const lowerCaseKeyObject = {};

      for ( const mixedCaseKey of mixedCaseKeys ){

        lowerCaseKeyObject[ mixedCaseKey.toLowerCase() ] = mixedCaseObject[ mixedCaseKey ];
      }

      formattedItems.push( lowerCaseKeyObject );
    }

    return formattedItems;
  }

  public async fetchThreatActorList() {

    try { 

    const { count, items } = await this.searchService.getSearchableItems().toPromise();

    // First convert all the keys to lowercase, this is needed because when Reporting from Postgres all keys are
    // are converted to LowerCase while in MongoDB they are MixedCase
    let formattedItems = this.convertAllKeysToLowerCase( items );

    // Get the Searchable columns by getting the defaults and the ones set with ObservableMetaData
    this.columns = this.stixConfig.stixLabeling(TABLE_COLUMNS_LIST_SEARCHABLE).concat(this.searchableColumns);
    this.columns = this.columns.map( x=> {
      x.prop = x.prop.toLowerCase();
      return x;
    })

    // Format fields for the Threat Actors
    formattedItems = formattedItems && formattedItems.map((threatActor) => {

      threatActor.assigneefullname = threatActor.assigneefullname || threatActor.assignee;
      threatActor.instanceid = threatActor.processinstanceid;
      threatActor.processinstanceid = threatActor.processinstanceid.substring(0, 8).toUpperCase(); /// <-- temp
      if (threatActor.workflowcompleted && threatActor.workflowcompleted == "true") {
        threatActor.workflowcomplete = true;
      }
      else{
        threatActor.workflowcomplete = (!threatActor.status || threatActor.status === this.env.workflowClosedStatus);
      }

      Object.keys(threatActor).forEach((prop) => {

        // send back a list of props that have date ISO format values for server filter.
        const propertyData = this.columns.find((x) => x.prop === prop);
        if (propertyData) {
          const isISOFormat = threatActor[prop] && moment(threatActor[prop], moment.ISO_8601, true).isValid() && threatActor[prop].toString().indexOf(":") != -1;
          if (threatActor[prop] && isISOFormat) {

            if (prop == "updateddatetime") {
              threatActor[prop] = moment(threatActor[prop]).format("MM/DD/YYYY, h:mm:ss a");
            }
            else {
              // Remove the Z
              threatActor[prop] = threatActor[prop].replace('Z', '');
              threatActor[prop] = moment(threatActor[prop]).format('L');
            }
          }
        }
      });

      return threatActor;
    });
    this.rows = formattedItems;
    this.totalRecords = count || 0;
    this.original_rows = this.rows;
    this.updateTabelInfo();
    this.loaded = true;
    }
    catch( searchError ){
      this.searchFailed = true;
    }
  }

  public itemClick(columnName, item) {

    if (columnName === "taid") {
      if (this.sightingParams) {
        this.router.navigate([item.threatactorid], { relativeTo: this.route, queryParams: { sighting: this.sightingParams } });
      } else {
        this.router.navigate([item.threatactorid], { relativeTo: this.route });
      }
    }
    if (columnName == "processinstanceid" && !item.workflowcomplete && this.perm_accessCamunda) {
      this.router.navigate(["search/task", item.instanceid]);
    }
  }

  public getHoverTitle(prop, item) {
    if (prop == "processinstanceid" && !item.workflowcomplete && this.perm_accessCamunda) {
      return "Click to open " + this.stixConfig.coa_singular + " Current Task";
    }
    if (prop == "taid") {
      return "Click to open " + this.stixConfig.threatActor_singular + "Details";
    }
  }

  public updateTabelInfo() {
    const startRange = this.totalRecords ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.totalRecords ? this.totalRecords : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + this.totalRecords + " entries";

  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTable();
  }

  public isArray(obj: any) {
    return Array.isArray(obj);
  }

}
