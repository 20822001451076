import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { CookieService } from 'ngx-cookie-service';

import { ToastrService } from "ngx-toastr";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import _get from "lodash-es/get";

import * as moment from "moment";

import { EnvService } from "../../../app/env.service";
import { RivirAPI, RivirAPIFilter } from "../../rivirapi/rivirapi.sdk";
import { UserProfile } from "../../shared/models/userProfile";
import { AlertService } from "../../shared/services/alert.service";
import { RivirHttp } from "../../shared/services/rivir-http.service";
import { SightingService } from "../../shared/services/sighting.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
declare var $: any;

@Component({
  selector: "app-accountrequestview",
  templateUrl: "./accountrequestview.component.html",
  styleUrls: ["./accountrequestview.component.css"],
})
export class AccountRequestViewComponent implements OnInit {
  public errorMessage: string;
  public ascending: boolean;
  public userProfile: UserProfile = new UserProfile();
  public uploadUrl: string = "";
  public permission_changeAccountType = false;
  public permission_accessUserAccount = false;
  public editable = false;
  public loaded = false;
  public fileToUpload: File = null;
  public customStyle = {
    selectButton: {
      "color": "#333",
      "text-decoration": "none",
      "border": "1px solid transparent",
      "border-radius": "4px",
      "background-color": "buttonface",
    },
  };

  public accountTypeOptions = [];

  public manager;

  public managerOptions = [];

  public managedUserOptions = [];

  public accessToken = RivirHttp.rivirApiToken;

  public statusOptions = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
    { id: 3, name: "Pending" },
    { id: 4, name: "Denied" },
    { id: 5, name: "Expired" },
  ];

  public currentUserStatus = "Requested";
  public uploadFileName = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rivirApi: RivirAPI,
    private http: HttpClient,
    private permissions: PermissionService,
    public toastr: ToastrService,
    public vcr: ViewContainerRef,
    private userProfileService: UserProfileService,
    private sightingService: SightingService,
    private alertService: AlertService,
    private cookieService: CookieService,
    private env: EnvService
  ) { 
    this.uploadUrl = `${this.env.rivirApiBaseUrl}/profilePicture/${this.env.uploadContainer}/upload`;
   }

   public fileSelected(fileInput) {
    const allowedFileTypes = ["jpeg", "jpg", "png", "gif"];

    const file = fileInput.target.files[0];
    const fileExtension: string = file.name.split(".").pop().toLowerCase();
    this.errorMessage = null;

    if (fileInput.target.files.length > 0) {
      if (!allowedFileTypes.includes(fileExtension)) {
         this.errorMessage = `File extension "${fileExtension}" is not permitted.`;
       } 
      else {
        if (fileInput.target.files && fileInput.target.files[0]) {
           // update file and metadata
           this.fileToUpload = fileInput.target.files[0];
           this.uploadFileName = fileInput.target.files[0].name;

           const formData: FormData = new FormData();
           formData.append("uploadFile", file, file.name);
            this.http
              .post(this.uploadUrl, formData, RivirHttp.getHttpOptions())
              .pipe(catchError(RivirHttp.handleError))
              .subscribe(
                (data: any) => {
                  const fileName = _get(data, "result.files.uploadFile[0].name", "");
                  this.userProfile.profilePicUrl = fileName ?
                    `${this.env.rivirApiBaseUrl}/profilePicture/${this.env.uploadContainer}/download/${fileName}` :
                    "./images/user.png";
                },
                (error) => {
                  console.error(error);
                  $(".img-ul-file-upload").hide();
                },
              );
         }
      }
    }
  }

  public onUploadFinished(event) {
    $(".img-ul-file-upload").hide();
    if (event.file) {
      const file: File = event.file;
      const formData: FormData = new FormData();
      formData.append("uploadFile", file, file.name);
      this.http
        .post(this.uploadUrl, formData, RivirHttp.getHttpOptions())
        .pipe(catchError(RivirHttp.handleError))
        .subscribe(
          (data: any) => {
            const fileName = _get(data, "result.files.uploadFile[0].name", "");
            this.userProfile.profilePicUrl = fileName ?
              `${this.env.rivirApiBaseUrl}/profilePicture/${this.env.uploadContainer}/download/${fileName}` :
              "./images/user.png";
          },
          (error) => {
            console.error(error);
            $(".img-ul-file-upload").hide();
          },
        );
    }
  }

  public onRemoved(event) {
    this.userProfile.profilePicUrl = "./images/user.png";
    $(".img-ul-file-upload").show();
  }

  public updateProfile(): void {
    // First check to see if the user has selected a role that requires a manager and
    // they have a manager selected.  If so alert the user and stop processing
    const selectedAccountType = this.accountTypeOptions.find(
      (x) => this.userProfile.accountType == x.name,
    );
    if (!this.permission_accessUserAccount && selectedAccountType.requiresManager.toLowerCase() == "yes" &&
      (this.userProfile.manager == "0" || !this.userProfile.manager)) {
      this.toastr.error(
        `The selected role ${selectedAccountType.name
        } require a Manager to be selected before saving`,
      );
    } else {
      // If the status status has changed send an alert stating the change
      if (this.userProfile.status != this.currentUserStatus) {
        const data = {
          oldStatus: this.userProfile.status, 
          currentStatus: this.currentUserStatus
        };

        if(data.oldStatus == "Pending" && data.currentStatus == "Active") {
          this.alertService.sendStatusChangeNotification(this.userProfile.id, { oldStatus: this.userProfile.status, currentStatus: this.currentUserStatus });
        }
      }

      // The if statements of just for the boolean variables but the actual status is storied
      // in the userProfile.status column.  Keeping the boolean columns for legacy data.  Can be removed after data is removed
      this.userProfile.status = this.currentUserStatus;
      if (this.currentUserStatus == "Active") {
        this.userProfile.verified = true;
        this.userProfile.active = true;
      } else if (
        this.currentUserStatus == "Denied" ||
        this.currentUserStatus == "Inactive"
      ) {
        this.userProfile.verified = true;
        this.userProfile.active = false;
      } else if (this.currentUserStatus == "Expired") {
        this.userProfile.active = false;
      } else if (this.currentUserStatus == "Pending") {
        this.userProfile.verified = false;
        this.userProfile.active = false;
      }

      if (this.manager) {
        this.userProfile.managerEmail = this.manager.email;
      }

      this.userProfile.name =
        this.userProfile.firstName + " " + this.userProfile.lastName;

      // Update the RIVIR user profile
      this.userProfileService.saveUserProfile(this.userProfile, "", false);
      this.toastr.success("Account Profile Setting have been saved.");
      this.updatePasswordExpirationDate();
    }
  }

  public cancel(): void {
    this.router.navigate(["/"]);
  }

  public ngOnInit() {
    this.permissions.getPermission("User Accounts").then((p) => {
      this.permission_accessUserAccount = p;
      this.permission_changeAccountType = p;

      if (this.permission_changeAccountType) {
        this.sightingService
          .getPermissions("Requires Manager", false)
          .subscribe((dbPermissions) => {
            dbPermissions.sort((a, b) => a.role.localeCompare(b.role));
            dbPermissions.forEach((perm, index) => {
              this.accountTypeOptions.push({
                id: index,
                name: perm.role,
                requiresManager: perm.access,
              });
            });
          });
      }

      this.route.params.subscribe((params) => {
        this.editable = this.route.snapshot.data.editable;

        // Get the current Id from the query string
        let userProfileId;

        if (params.id && params.id !== this.cookieService.get("userProfileId")) {
          //// User profile is NOT the logged in user profile
          userProfileId = params.id;
        } else {
          //// User profile is the logged in user profile
          userProfileId = this.cookieService.get("userProfileId");
          this.permission_accessUserAccount = true;
        }

        if (this.permission_accessUserAccount) {
          this.sightingService.checkForImagesContainer();

          this.userProfileService
            .getUsersProfile(userProfileId)
            .subscribe((dbUserProfile) => {
              this.userProfile = dbUserProfile;

              this.updatePasswordExpirationDate();

              if (this.userProfile.status) {
                this.currentUserStatus = this.userProfile.status;
              } else {
                // Determine the status from the boolean variables, using the method
                // we can only calculate three statuses (Not including Verified)
                if (this.userProfile.verified && this.userProfile.active) {
                  this.currentUserStatus = "Active";
                } else if (
                  this.userProfile.verified &&
                  !this.userProfile.active
                ) {
                  this.currentUserStatus = "Denied";
                } else {
                  this.currentUserStatus = "Pending";
                }
              }
              this.loaded = true;

              // Get manager info
              if (
                this.userProfile.manager &&
                this.userProfile.manager !== "0"
              ) {
                const filter: RivirAPIFilter = new RivirAPIFilter(null);
                filter.setFilterType("where");
                filter.addExpression({ id: this.userProfile.manager });

                this.rivirApi
                  .getRequestWithPath("userprofiles", filter)
                  .pipe(catchError(RivirHttp.handleError))
                  .subscribe((rivirResponse) => {
                    const userProfiles = rivirResponse.getData();

                    if (userProfiles != null && userProfiles.length > 0) {
                      let idx = 0;

                      for (idx = 0; idx < userProfiles.length; idx++) {
                        const userProfile = userProfiles[idx];
                        const profile = userProfile.getJSONObject();
                        const activeFlag = profile.active;

                        if (activeFlag) {
                          this.manager = profile;

                          // If the user doesn't have a custom Manager Email Address,
                          // default to use the current email address set for the manager
                          if (!this.userProfile.customManagerEmailAddress) {
                            this.userProfile.customManagerEmailAddress = this.manager.email;
                          }
                        }
                      }
                    }
                  });
              } else {
                this.manager = { id: "0", name: "No Manager" };
              }

              // Get any users we manage
              const filter: RivirAPIFilter = new RivirAPIFilter(null);
              filter.setFilterType("where");
              filter.addExpression({ accountType: "Manager" });

              this.rivirApi
                .getRequestWithPath("userprofiles", filter)
                .subscribe((rivirResponse) => {
                  const userProfiles = rivirResponse.getData();

                  if (userProfiles != null && userProfiles.length > 0) {
                    let idx = 0;
                    const profileRows = [];

                    for (idx = 0; idx < userProfiles.length; idx++) {
                      const userProfile = userProfiles[idx];
                      const profile = userProfile.getJSONObject();
                      const activeFlag = profile.active;

                      if (activeFlag) {
                        profileRows.push(profile);
                      }
                    }

                    this.managedUserOptions.push({
                      id: "0",
                      name: "No Manager",
                    });
                    profileRows.sort((a, b) => a.id.localeCompare(b.role));
                    profileRows.forEach((user, index) => {
                      this.managedUserOptions.push(user);
                    });
                  }
                });
            });
        } else {
          this.loaded = true;
        }
      });
    });
  }

  public updatePasswordExpirationDate() {
    // Dispay Password Expiration date
    const mCurrentDate = moment();
    const mExpirationDate = moment(this.userProfile.passwordExpirationDate);
    const daysUntilExpired = mExpirationDate.diff(mCurrentDate, "days");

    if (daysUntilExpired > 0) {
      this.userProfile.dayUntilExpiration = `Password expires in ${daysUntilExpired} days`;
    } else {
      this.userProfile.passwordExpired = true;
      this.userProfile.dayUntilExpiration = `Password expired ${Math.abs(
        daysUntilExpired,
      )} days ago`;
    }
  }

  public updateUrl(event: Event): void {
    this.userProfile.profilePicUrl = "./images/user.png";
  }

  public handleException(error: any) {
    console.error(error);
    return Observable.throw(error || "Server Error");
  }

  public setCustomManagerEmail(event: Event) {
    this.userProfile.customManagerEmailAddress = this.managedUserOptions.find(
      (x) => x.id === event,
    ).email;
  }

  /**
   * Resets the Password for the current user profile, also makes a susequent call to set the password expiration
   * dates and then update the UI with new value for days until expiration
   */
  public resetPassword() {
    if (
      this.userProfile.status == "Active" ||
      this.userProfile.status == "Expired"
    ) {
      this.userProfileService
        .resetPassword(this.userProfile.ssoId)
        .subscribe((data) => {
          if (data == "SUCCESS") {
            this.toastr.success(
              `An email has been sent to ${this.userProfile.email
              } to reset their password`,
            );
          } else {
            console.error(data);
            this.toastr.error(
              `Failed to send reset password notification to ${this.userProfile.email
              }`,
            );
          }
        });
    } else {
      this.toastr.error(
        `Only user accounts with an "Active" or "Expired" status can reset their password.`,
      );
    }
  }
}