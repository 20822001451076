import { BulkAssignmentComponent } from "../bulk-assignment/bulk-assignment.component";
import { TaskDetailsComponent } from "../tasks/task-details/task-details.component";
import { ThreatActorDetailsComponent } from "../threat-actor/threat-actor-details/threat-actor-details.component";
import { IndicatorDetailComponent } from "./indicator-detail/indicator-detail.component";
import { IndicatorRunDatesComponent } from "./indicator-detail/indicator-run-dates/indicator-run-dates.component";
import { IndicatorThreatActorsComponent } from "./indicator-threat-actors/indicator-threat-actors.component";
import { IndicatorsDetailsThreatActorComponent } from "./indicators-details-threat-actor/indicators-details-threat-actor.component";
import { IndicatorsListComponent } from "./indicators-list/indicators-list.component";
import { GroupsAssignmentComponent } from '../bulk-assignment/groups-assignment/groups-assignment.component';
import { CoaDetailsComponent } from '../coa/coa-details/coa-details.component';
import { SightingLogComponent } from "./sighting-log/sighting-log.component";
import { SightingReportComponent } from "./sighting-report/sighting-report.component";
import { IndicatorWizardParentComponent } from "../indicator-wizard/indicator-wizard-parent/indicator-wizard-parent.component";

const IndicatorsRoutes = [
  {
    path: "",
    component: IndicatorsListComponent,
  },
  {
    path: "indicator-wizard",
    component: IndicatorWizardParentComponent,
    data: { text: "Add indicator_singular", breadcrumbs: true },
  },
  {
    path: ":id",
    data: {
      text: "indicator_singular Details",
      breadcrumbs: true,
    },
    children: [
      {
        path: "",
        component: IndicatorDetailComponent,
      },
      {
        path: "indicator-wizard",
        children: [
        {
          path: ":indicatorId",
          component: IndicatorWizardParentComponent,
          data: { text: "Edit indicator_singular", breadcrumbs: true },
        }]
      },
      {
        path: "sighting/:name",
        data: {
          text: "threatActor_plural",
          breadcrumbs: true,
        },
        children: [
          {
            path: "",
            component: IndicatorThreatActorsComponent,
          },
          {
            path: "bulkassignment",
            component: BulkAssignmentComponent,
            data: {
              text: "BulkAssign",
              breadcrumbs: true,
              // nav: environment.bulkAssignment,
              icon: "transfer",
            },
          },
          {
            path: ":ta_id",
            data: {
              text: "threatActor_singular Details",
              breadcrumbs: true,
            },
            children: [
              {
                path: "",
                component: ThreatActorDetailsComponent,
              },
              {
                path: "task/:instanceId",
                component: TaskDetailsComponent,
                data: { text: "coa_singular Task", breadcrumbs: true },
              },
              {
                path: "coa/:id",
                component: CoaDetailsComponent,
                data: { text: "coa_singular", breadcrumbs: true },
              }
            ],
          },
        ],
      },
      {
        path: "log/:name",
        component: SightingLogComponent,
        data: {
          text: "indicator_singular Log",
          breadcrumbs: true,
        },
      },
      {
        path: "sightingReport/:name/:visualizations",
        component: SightingReportComponent,
        data: {
          text: "sightingreportname",
          breadcrumbs: true,
        },
      },
    ],
  },
  {
    path: "sighting/:name",
    data: {
      text: "threatActor_plural",
      breadcrumbs: true,
    },
    children: [
      {
        path: "",
        component: IndicatorThreatActorsComponent,
      },
      {
        path: ":ta_id",
        component: ThreatActorDetailsComponent,
        data: {
          text: "threatActor_singular Details",
          breadcrumbs: true,
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
  },
];
export const IndicatorsRoutingComponents = [
  IndicatorsListComponent,
  IndicatorDetailComponent,
  IndicatorRunDatesComponent,
  IndicatorThreatActorsComponent,
  IndicatorsDetailsThreatActorComponent,
  BulkAssignmentComponent,
  GroupsAssignmentComponent,
  SightingLogComponent,
  SightingReportComponent,
];

export { IndicatorsRoutes };
