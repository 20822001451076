import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-mat-dialog-idle-timeout',
  templateUrl: './mat-dialog-idle-timeout.component.html',
  styleUrl: './mat-dialog-idle-timeout.component.css'
})
export class MatDialogIdleTimeoutComponent {
  
  public timeRemaining$: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: IdleDialogData){
  }

  public async ngOnInit() {

    if (this.data.seconds){
      this.timeRemaining$ = timer(0, 1000).pipe(
        map(n => (this.data.seconds - n) * 1000),
        takeWhile(n => n >= 0),
      );
    }
  }
}

export interface IdleDialogData{
  seconds: number
}