import { Component, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StixConfigService } from "../shared/services/stixConfig.service";
import { ThreatActorService } from "../threat-actor/threat-actor.service";


@Component({
  selector: 'app-mat-dialog-edit-threator',
  templateUrl: './mat-dialog-edit-threator.component.html',
  styleUrl: './mat-dialog-edit-threator.component.css'
})
export class MatDialogEditThreatorComponent {

	updatedThreatActorName: string;
	isEditing: boolean = true;
	
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ThreatActorEditData,
		public stixConfig: StixConfigService,
		private dialogRef: MatDialogRef<MatDialogEditThreatorComponent>,
		private threatActorService: ThreatActorService
	){
	
	}

	confirm(){
		this.isEditing = false;
	}

	async close(){
		const updateThreatActorResponse = await this.threatActorService.updateThreatActorName( this.data.threatActorId, this.updatedThreatActorName).toPromise();
		console.log( updateThreatActorResponse );
		this.dialogRef.close( { status : "SUCCESS", threatActorName: this.updatedThreatActorName } );
	}
}

export interface ThreatActorEditData{
	existingThreatActorName : string;
	threatActorId : string;

}
