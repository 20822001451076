import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';

import { EnvService } from "../env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { MetaDataType, ObservableMetaData } from "../shared/models/observableMetadata";

@Injectable()
export class SearchService {
  public criteria : any = {
    pageNumber: 1,
    pageLimit: 50,
    sortBy: "updateddatetime",
    asc: false,
    filterBy: "",
    advanceFilter: null
  };
  public dateFormatFields: string[] = [];

  private _observableDataUrl: string;
  private _EDVMedicalReview: string;

  constructor(private _http: HttpClient, private env: EnvService) {
    this._observableDataUrl = this.env.rivirApiBaseUrl + "/observableDataMetadata";
    this._EDVMedicalReview = this.env.rivirApiBaseUrl + "/EDVMedicalReviews";
  }

  public getSearchableItems(): Observable<any> {

    this.criteria.pageLimit = (typeof this.criteria.pageLimit === 'string' || this.criteria.pageLimit instanceof String) ?
                              parseInt(this.criteria.pageLimit) : this.criteria.pageLimit;
                              
    const params = "?criteria=" + encodeURIComponent(JSON.stringify(this.criteria));

    return this._http.get<any>(this._EDVMedicalReview + "/search" + params, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getSearchableDataFields(type): Observable<any> {
    const query = `?filter={"where" : {"and" : [{"isSearchable": "true"}, {"type": "${type}"}]}}`;
    return this._http.get<any>(this._observableDataUrl + query, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public getCsv(statusFilter): Observable<any> {
    const urlParams = (statusFilter) ? "?statusFilter=" + statusFilter : "";
    return this._http.get<any>(this._EDVMedicalReview + "/csv" + urlParams, RivirHttp.getHttpOptions())
      .pipe(catchError(RivirHttp.handleError));
  }

  public modifyAdvanceFilter(prop, string) {
    if (!this.criteria.advanceFilter) {
      this.criteria.advanceFilter = {};
    }
    if (string) {
      this.criteria.advanceFilter[prop] = string.trim();
    }
    if (this.criteria.advanceFilter[prop] && !string) {
      delete this.criteria.advanceFilter[prop];
    }

    if (Object.keys(this.criteria.advanceFilter).length < 1) {
      this.criteria.advanceFilter = null;
    }
  }

  public filterInDateFormat(string) {
    const startDate = moment(string);
    if (startDate) {
      const endDate = moment(startDate, "DD-MM-YYYY")
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds')
      .toDate();
      if (string.length <= 4) {
        moment(endDate).add(1, 'year');
      }
      return [moment(startDate, moment.ISO_8601).toDate(), endDate.toISOString()];
    } else {
      return [];
    }
  }

  public async getObservableMetaData( threatActorType : string ) : Promise<ObservableMetaData[]>{
    let url = `${this.env.rivirApiBaseUrl}/observableDataMetadata/`;

    if ( threatActorType ) {
      url += `?filter={ "where" : { "type" : "${threatActorType}"} }`;
    }
    let observableMetaData = await this._http.get<ObservableMetaData[]>(url, RivirHttp.getHttpOptions()).toPromise();
    return observableMetaData;
  }

  public async saveObservableMetaData( id : string, observableMetatData : ObservableMetaData) : Promise<void> {
    const url = `${this.env.rivirApiBaseUrl}/observableDataMetadata/${id}/replace`;
    await this._http.post(url, observableMetatData, RivirHttp.getHttpOptions()).toPromise();

    // Clear the cache on the API (Not sure why we are doing this?)
    const clearCacheUrl = `${this.env.rivirApiBaseUrl}/cache/clear`;
    await this._http.post(clearCacheUrl, null, RivirHttp.getHttpOptions()).toPromise(); 
  }

  public filterObservableMetaData( filter : any ) : Observable<ObservableMetaData[]>{
    let url = `${this.env.rivirApiBaseUrl}/observableDataMetadata/?filter={ "where" : ${JSON.stringify(filter)}}`;
    return this._http.get<ObservableMetaData[]>(url, RivirHttp.getHttpOptions());
  }

  public async getMetaDataTypes( ) : Promise<MetaDataType[]>
  {
    const getTypesUrl = `${this.env.rivirApiBaseUrl}/observableDataMetadata/types`;
    return await this._http.get<MetaDataType[]>(getTypesUrl, RivirHttp.getHttpOptions()).toPromise();
  }

  public getFormattedReferenceDataValue( referenceDataValue : string, referenceDataType : string, metaDataTypes: MetaDataType[]) : string{

    const metaDataType = metaDataTypes.find( x => x.name.toLowerCase() === referenceDataType.toLowerCase() );
    
    if ( metaDataType.fieldType  === "datetime" ){
      const dateValue = moment(referenceDataValue.trim().replace("Z", ""));
      if (dateValue.toString() !== "Invalid Date") {
        referenceDataValue= dateValue.format(metaDataType.momentFormat);
      }
    }
    else if ( metaDataType.fieldType === "number") {

      const numericValue = parseFloat(referenceDataValue.trim());
      referenceDataValue = !isNaN(numericValue) ? numericValue.toLocaleString(metaDataType.locale, metaDataType.options) : null;
    }

    return referenceDataValue
    
  }
}
