import { Component, OnInit } from "@angular/core";

import { DataEntryListService } from './data-entry-list.service';
 import { StixConfigService } from "../shared/services/stixConfig.service";
import { Router } from "@angular/router";

interface DataEntryTab {
  formName: string;
  isActive: boolean;
}

@Component({
  selector: 'app-data-entry-list',
  templateUrl: './data-entry-list.component.html',
  styleUrls: ['./data-entry-list.component.css']
})
export class DataEntryListComponent implements OnInit {

  public dataEntryTabs : Array<DataEntryTab> = [];

  constructor( private dataEntryListService: DataEntryListService, 
                public stixConfig: StixConfigService,
                private router: Router,
    ) { }

  async ngOnInit(): Promise<void> {

    const distinctDataEntries = await this.dataEntryListService.distinctDataEntries().toPromise();
    this.dataEntryTabs = distinctDataEntries.map( x => { return { formName : x, isActive: false }});
    this.dataEntryTabs[0].isActive = true;   
  }

  redirectToAddNewDataEntry(){
    this.router.navigate(["/data-entry" ]);
  }

  public getPage($event, index) {
    for ( let i = 0; i < this.dataEntryTabs.length; i++){
      this.dataEntryTabs[i].isActive = index === i;
    }
  }

}
