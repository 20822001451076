import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { mergeMap, filter, map, toArray } from "rxjs/operators";

import { FilterPipe } from "../../shared/pipes/filter.pipe";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { IndicatorService } from "../indicators.service";
import { Indicator } from "./indicator";
import { TABLE_COLUMNS_IND_LIST } from "./indicator-list-columns";
import { IndicatorWizardService, IndicatorWizardModel } from "../../indicator-wizard/indicator-wizard.service";

import { MatDialog } from "@angular/material/dialog";
import { MatDialogIndicatorParametersComponent } from '../../mat-dialog-indicator-parameters/mat-dialog-indicator-parameters.component';


@Component({
  selector: "app-indicators-list",
  templateUrl: "./indicators-list.component.html",
  styleUrls: ["./indicators-list.component.css"],
  providers: [FilterPipe],
})
export class IndicatorsListComponent implements OnInit {
  public rows: Indicator[] = [];
  public original_rows: Indicator[] = [];
  public columns = [];
  public tableTitle: string;
  public p = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public mobilePageSize = 5;
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo = "";
  public sortKey = "lastRunDate";
  public asc = false;
  public term = "";
  public errorMessage: string;
  public permission_createAlgo = false;
  public permission_executeAlgo = false;
  public permission_activateAlgo = false;
  public loaded = false;
  public searchFilter = "";
  public indicators: Indicator[] = [];
  public label_indicator: string;

  // TODO This is legacy object for code that does not exist anymore. 
  // All associated code should be refactored and deleted
  public sideTable: any = null;
  public template: any = null;

  constructor(
    private indicatorService: IndicatorService,
    private router: Router,
    private route: ActivatedRoute,
    private permissions: PermissionService,
    private userService: UserProfileService,
    private filterPipe: FilterPipe,
    public stixConfig: StixConfigService,
    private indicatorWizardService: IndicatorWizardService,
    public dialog: MatDialog
  ) { }

  public ngOnInit() {

    // Anytime we come back to list, re-create the wizard 
    this.indicatorWizardService.refreshModel();

    if (window.innerWidth < 768) this.limitRows = this.mobilePageSize;
    this.label_indicator = this.stixConfig.indicator_singular;
    this.tableTitle = "List of All " + this.stixConfig.indicator_plural;
    this.permissions.getPermission("Add New Indicator").then((p) => {
      this.permission_createAlgo = p;
    });
    this.permissions.getPermission("Execute Indicator").then((p) => {
      this.permission_executeAlgo = p;
    });
    this.permissions.getPermission("Activate/Deactivate Indicator").then((p) => {
      this.permission_activateAlgo = p;
      this.fetchIndicatorsList();
    });
  }

  public fetchIndicatorsList() {
    this.loaded = false;
    this.indicatorService
      .getIndicatorList()
      .pipe(
        mergeMap((a) => a),
        filter((a) => this.permission_activateAlgo || a.active),
        map((a) => {
          if (a.types) {
            a.type = a.types.join(", ");
          }
          if (a.coursesOfAction) {
            a.coa = a.coursesOfAction.join(", ");
          }
          if (a.classifications) {
            a.classification = a.classifications.join(", ");
          }
          a.execute = a.active;
          if (a.sighting) {
            a.numOfSightings = a.sighting.length;
          }
          if (a.status === "in-progress") {
            a.numOfOccurrence = 1;
            if (this.indicatorService.autoRefresh) {
              this.indicatorService.autoRefresh.unsubscribe();
            }
            this.indicatorService.autoRefreshCalls(a, 1000, 60000).then((r) => {
              this.fetchIndicatorsList();
            });
          }
          const autostartsInvalid = a.autoStartCoursesOfAction && a.autoStartCoursesOfAction.length ? 
                                    a.autoStartCoursesOfAction.find( x => !x.user ) || a.autoStartCoursesOfAction.find( x => x.daysOut <= 0 ) : false;

          if ( !a.name || !a.script || !a.description || !a.classifications || !a.classifications.length || autostartsInvalid ){
            a.status = "incomplete";
          }
          // a.priority = '';
          return a;
        }),
        toArray()
      )
      .subscribe(
        (algorithms) => {
          this.indicatorService
            .getTableColumns(TABLE_COLUMNS_IND_LIST)
            .then((cols) => {
              this.columns = cols;
              this.rows = algorithms;
              this.original_rows = this.rows;
              this.indicators = algorithms;
              this.updateTabelInfo();
              this.loaded = true;
            });
        },
        (error) => {
          this.errorMessage = error as any;
          this.loaded = true;
        },
      );
  }

  public itemClick(columnName, item) {
    if (columnName === "execute") {
      if (item.status === "in-progress") {
        this.indicatorService.abortExecution(item).subscribe(() => {
          item.status = "";
        });
       
      } else if (item.status === "incomplete") {
        this.router.navigate([ item.name, "indicator-wizard", item.id ], { relativeTo: this.route })
      }
      else {

        if ( item.surveyJson ){
          const dialogRef = this.dialog.open(MatDialogIndicatorParametersComponent, {
            data: {
              surveyJson : item.surveyJson
            }
          });
  
          dialogRef.afterClosed().subscribe(
            async parameterResult => {
              this.executeIndicator( item, parameterResult.modelData)
            }
          )
        }
        else {
          this.executeIndicator( item, null);
        }
      }
    }
    if (columnName === "name" || columnName === "description") {
      this.indicatorService.currentIndicator = item;
      this.router.navigate([item.name], { relativeTo: this.route });
    }
    if (columnName === "active" && item.status !== "in-progress") {
      this.switchActivation(item);
    }
  }

  async executeIndicator( indicator, parameters ){
    await this.indicatorService.executeAlgorithm(indicator, this.userService.fullName, parameters ).toPromise();
    indicator.status = "in-progress";
    this.indicatorService.autoRefreshCalls(indicator, 1000, 60000);
  }

  public filterRows() {
    this.rows = this.filterPipe.transform(
      this.original_rows,
      this.term,
      this.columns,
    );
    this.updateTabelInfo();
  }

  public createAlgorithm() {
    this.indicatorWizardService.changeData( new IndicatorWizardModel() );
    this.router.navigate(["indicator-wizard"], { relativeTo: this.route });
  }

  public switchActivation(indicator) {
    indicator.active = !indicator.active;
    indicator.id = null;
    indicator.updateRecord = true;
    indicator.UpdatedDateTime = new Date();
    (indicator.UpdatedBy = this.userService.fullName),
      this.indicatorService.saveIndicator(indicator).subscribe(
        (data) => {
          indicator.execute = !indicator.execute;
        },
        (error) => {
          indicator.active = !indicator.active;
        },
      );
  }

  public getHoverTitle(prop, item) {
    let hoverTitle = "";
    if (item.status !== "in-progress") {
      if (prop == "active") {
        hoverTitle =  !item[prop]
          ? "Click to Reactivate " + this.label_indicator
          : "Click to Deactive " + this.label_indicator;
      }
      if (prop == "execute") {
        if ( item.status === "incomplete"){
          hoverTitle = "Click to review " + this.label_indicator;
        }
        else if ( item.status === "in-progress" ){
          hoverTitle = "Click to abort " + this.label_indicator;
        }
        else {
          hoverTitle = "Click to execute " + item.status;
        }
      }
    }

    return hoverTitle;
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo =
      "Showing " +
      startRange +
      " to " +
      endRange +
      " of " +
      this.rows.length +
      " entries";
  }
  public columnClick(columnName) {
    if (window.innerWidth < 768) return;
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }

  public isArray(obj: any) {
    return Array.isArray(obj);
  }

  subItemClick(prop: string, item: any, subItem: any): void {
    console.log("This method is not implemented");
  }
}
