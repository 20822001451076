import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import * as moment from "moment";
import { EnvService } from "../env.service";
import { RivirHttp } from "../shared/services/rivir-http.service";
import { UserProfileService } from "../core/userProfile.service";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Indicator } from "../indicators/indicators-list/indicator";

@Component({
  selector: 'app-mat-dialog-algo-scheduleview',
  templateUrl: './mat-dialog-algo-scheduleview.component.html',
  styleUrl: './mat-dialog-algo-scheduleview.component.css'
})
export class MatDialogAlgoScheduleviewComponent {

  public uIScheduledMessage: string;
  public frequency: string;
  public startDate: string;
  public startTime: string;
  public untilDate: string;
  public endTime: string;
  
  public frequencyValues: any[] = [];

  public frequency_valid = false;
  public startDate_valid = false;
  public startTime_valid = false;
  public untilDate_valid = true;
  public untilTime_valid = true;
  public todaysDate = moment().format("YYYY-MM-DD");

  private executeApiUrl = "/algorithms/execute";
  private timedJobsQueueApiUrl: string;
  public accessToken = RivirHttp.rivirApiToken;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScheduleViewDialogData,
    private dialogRef: MatDialogRef<MatDialogAlgoScheduleviewComponent>,
    private _http: HttpClient, 
    private userService: UserProfileService,
    private env: EnvService) {
      this.timedJobsQueueApiUrl = this.env.rivirApiBaseUrl + "/timedJobsQueue";
      this.frequencyValues = ["Hourly", "Daily", "Weekly", "Monthly", "Quarterly", "Annually"];
      this.uIScheduledMessage = data.uIScheduledMessage;

      // Parse schedule values from uIScheduledMessage
      //TODO: This is not awesome - a separate API call could be created when adding scheduled run histories, perhaps
      if (this.uIScheduledMessage) {
        let schedule = this.uIScheduledMessage.split(' ');
        this.frequency = schedule[3];
        this.startDate = schedule[8].replace('.','');
        this.startTime = schedule[6].replace('.','');
        this.untilDate = schedule[13].replace('.','');
        this.endTime = schedule[15].replace('.','');

        // Convert schedule values to 24H and YYYY-MM-DD formats for use by date and time picker UI elements
        this.startTime = this.convertTo24HourFormat(this.startTime);

        let startDateISO = new Date(this.startDate).toISOString();
        const startT = startDateISO.indexOf("T");
        startDateISO = startDateISO.substring(0, startT);
        this.startDate = startDateISO.substring(0, 10);

        this.endTime = this.convertTo24HourFormat(this.endTime);

        let untilDateISO = new Date(this.untilDate).toISOString();
        const untilT = untilDateISO.indexOf("T");
        untilDateISO = untilDateISO.substring(0, untilT);
        this.untilDate = untilDateISO.substring(0, 10);
      } else {
        this.frequency = "";
        this.startDate = "";
        this.startTime = "";
        this.untilDate = "";
        this.endTime = "";
      }
  }

  private convertTo24HourFormat(time: string): string {
    // Extract the time components using regex
    const [_, hour, minute, period] = time.match(/(\d{1,2}):(\d{2})\s?(AM|PM)/i) || [];
    
    if (!hour || !minute || !period) {
        throw new Error("Invalid time format");
    }
    
    let hour24 = parseInt(hour, 10);

    if (period.toUpperCase() === "PM" && hour24 < 12) {
        hour24 += 12;
    } else if (period.toUpperCase() === "AM" && hour24 === 12) {
        hour24 = 0;
    }

    // Pad single digit hour with leading zero
    const hourString = hour24.toString().padStart(2, "0");

    return `${hourString}:${minute}`;
  }

  public async saveSchedule() {
    if (this.startDate_valid && this.untilDate_valid && this.startTime) {
      const executeUrl = `${this.executeApiUrl}?name=${encodeURIComponent(this.data.indicator.name)}&currentUser=${encodeURIComponent(this.userService.fullName)}`;
      const label = this.data.indicator.name;
      const start = `${this.startDate} ${this.startTime}`;
      const momentStartDate = moment(start, "YYYY-MM-DD hh:mm a");
      let cron_frequency = [];
      let description = "";
      const endTime = this.untilDate ? moment(`${this.untilDate} ${this.endTime}`, "YYYY-MM-DD hh:mm a").toISOString() : moment(this.data.maxScheduleEndDate, "YYYY-MM-DD").toISOString();

      switch(this.frequency.toLocaleLowerCase()){
        case "hourly":
          cron_frequency.push(`0 ${momentStartDate.minute()} * * * *`);
          description = this.getScheduleDescriptionText("hourly", momentStartDate.toISOString(), endTime)
          break;
        case "daily":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} * * *`);
          description = this.getScheduleDescriptionText("daily", momentStartDate.toISOString(), endTime)
          break;
        case "weekly":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} * * ${momentStartDate.day()}`);
          description = this.getScheduleDescriptionText("weekly", momentStartDate.toISOString(), endTime)
          break;
        case"monthly":
          // const monthday = momentStartDate.date()
          // adjust based on proper last day of month when month is great than 28 days
          if (momentStartDate.date() > 28) {
            // add three entries when month has less days than the scheduled day of month number
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} 1,3,5,7,8,10,12 *`);
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date() > 30 ? 30 : momentStartDate.date()} 4,6,9,11 *`);
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 2 *`);
          } else {
            cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} * *`);
          }
          description = this.getScheduleDescriptionText("monthly", momentStartDate.toISOString(), endTime)
          break;
        case "quarterly":
          // calculate quarter months
          const monthQ1 = momentStartDate.month(); // day of month from 0-11
          const monthQ2 = (momentStartDate.month() + 3) % 12;
          const monthQ3 = (momentStartDate.month() + 6) % 12;
          const monthQ4 = (momentStartDate.month() + 9) % 12;

          // calculate day
          const dayOfMonthMatchQ1 = momentStartDate.date(); // day of month from 1-31
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ1} ${monthQ1} *`);

          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(3, "M"))) {
            const dayOfMonthMatchQ2 = this.getProperDayOfMonth(monthQ2, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ2.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ2} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ2.dayOfMonth} ${monthQ2} *`);
            }
          }
          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(6, "M"))) {
            const dayOfMonthMatchQ3 = this.getProperDayOfMonth(monthQ3, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ3.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ3} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ3.dayOfMonth} ${monthQ3} *`);
            }
          }
          if (moment(this.untilDate).isSameOrAfter(moment(this.startDate).add(9, "M"))) {
            const dayOfMonthMatchQ4 = this.getProperDayOfMonth(monthQ4, dayOfMonthMatchQ1);
            if (dayOfMonthMatchQ4.leapDay) {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} 28,29 ${monthQ4} *`);
            } else {
              cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${dayOfMonthMatchQ4.dayOfMonth} ${monthQ4} *`);
            }
          }

          description = this.getScheduleDescriptionText("quarterly", momentStartDate.toISOString(), endTime)
          break;
        case "annually":
          cron_frequency.push(`0 ${momentStartDate.minute()} ${momentStartDate.hour()} ${momentStartDate.date()} ${momentStartDate.month()} *`);
          description = this.getScheduleDescriptionText("annually", momentStartDate.toISOString(), endTime)
          break;
        default:
          break;
      }

      //cron_frequency.forEach(cronSchedule => {
      for (const cronSchedule of cron_frequency ) {
        const payload = {
          jobId: label.replace(/\s+/g, "-"),
          active: true,
          endpoint: executeUrl,
          method: "POST",
          name: label,
          indicator: this.data.indicator.name,
          frequency: this.frequency.toLocaleLowerCase(),
          cronSchedule: cronSchedule,
          startTime: momentStartDate.toISOString(),
          endTime: endTime,
          description: description,
          type: "indicator",
          createdBy: `"${this.userService.fullName}" <${this.userService.userEmail}>` || "system",
          updated: moment().toISOString()
        };
  
        if (this.frequency) {
          this._http.post(`${this.timedJobsQueueApiUrl}/addRequest?access_token=${this.accessToken}`, { job: payload }).toPromise();
        }  
      }

      this.dialogRef.close();
    }
  }

  /* Make sure month day is valid, otherwise calculate day */
  private getProperDayOfMonth = function (month, day) {
    // const monthsWith31Days = [0, 2, 4, 6, 7, 9, 11];
    const monthsWith30Days = [3, 5, 8, 10];
    let leapDay = false;

    if (monthsWith30Days.includes(month)) { // Apr, Jun, Sep, Nov
      if (day > 30) day = 30;
    } else { // Feb
      if (day > 28) {
        leapDay = true;
        day = 28;
      }
    }
    return {
      dayOfMonth: day,
      leapDay
    }
  }

  private getScheduleDescriptionText = function(type, startTime, endTime) {
    const untilMessage = moment(endTime).format("YYYY-MM-DD") === this.maxScheduleEndDate
        ? ""
        : ` It will Run until ${moment(endTime).format("MM/DD/YYYY")} at ${moment(endTime).format("h:mmA")}.`;

    return (`Scheduled to Run ${type.charAt(0).toUpperCase() +
      type.slice(1)} Starting at ${moment(startTime).format(
        "h:mmA on MM/DD/YYYY"
      )}.${untilMessage}`);
  };

  public datesChanged() {
    const start = `${this.startDate} ${this.startTime}`;
    const checkStartDate = moment(start, "YYYY-MM-DD hh:mm a");

    // Ensure start date is not in the past
    this.startDate_valid = !!(checkStartDate.diff(moment(), "days") >= 0);

    // Ensure start time is at least 10 minutes from the current time
    this.startTime_valid = !!(checkStartDate.diff(moment(), "minutes") >= 10);

    // Ensure end date is at least the same as start date
    const until = `${this.untilDate} ${this.endTime}`;
    const checkUntilDate = moment(until, "YYYY-MM-DD hh:mm a");
    this.untilDate_valid = !!(checkUntilDate.diff(checkStartDate, "days") >= 0 || !this.untilDate);

    // Ensure end time is at least 10 minutes from now if the end date is today
    if (checkUntilDate.diff(checkUntilDate, "days") == 0) {
      this.untilTime_valid = !!(checkUntilDate.diff(moment(), "minutes") >= 10);
    }

    if (this.frequency && this.startDate_valid && this.startTime_valid && 
      this.untilDate_valid && this.untilTime_valid) {
      return true;
    }
    return false;
  }

}

export interface ScheduleViewDialogData {
  indicator : Indicator;
  uIScheduledMessage: string;
  maxScheduleEndDate: string;
}
