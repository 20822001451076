<h1 mat-dialog-title>
    <span *ngIf="state === 'EXECUTE'">Execute {{stixConfig.coa_singular}}</span>
    <span *ngIf="state === 'SET_DUE_DATE'">Edit {{stixConfig.coa_singular}} Due Date</span>
    <span *ngIf="state === 'REASSIGN'">Reassign {{data.currentTask.name}}</span>
</h1>
<div mat-dialog-content>
    <div *ngIf="state === 'SUCCESS'">
        <div class="alert alert-success">{{successMessage}}</div>
    </div>
    <div *ngIf="state === 'ERROR'">
        <div class="alert alert-danger">{{errorMessage}}</div>
    </div>
    <div class="coa-execute-container" 
         *ngIf="state != 'SUCCESS' && state != 'ERROR'"
         [ngClass]="{ 'coa-execute-container': state === 'EXECUTE', 'due-date-container' : state === 'SET_DUE_DATE', 'reassign-container': state === 'REASSIGN' }"
          >
        <div class="row coa-execute-row " *ngIf="state === 'EXECUTE'">
            <div class="col-md-4 coa-execute-label">Select {{stixConfig.coa_singular}}:</div>
            <div class="col-md-8">
                <ng-select 
                  appendTo="body" 
                  [items]="processDefinitions"
                  bindLabel="name"
                  placeholder="Select {{stixConfig.coa_plural}}"
                  [(ngModel)]="selectedProcessDefinition">
              </ng-select>
            </div>
        </div>
        <div class="row coa-execute-row " *ngIf="state === 'REASSIGN'">
            <div class="col-md-4 coa-execute-label">Current Task:</div>
            <div class="col-md-8 coa-execute-label">{{data?.currentTask?.name}}</div>
        </div>
        <div class="row coa-execute-row " *ngIf="state === 'REASSIGN' || state === 'EXECUTE'">
            <div class="col-md-4 coa-execute-label">Assign {{stixConfig.coa_singular}} to:</div>
            <div class="col-md-8">
                  <ng-select 
                  appendTo="body" 
                  [items]="coaUsers" 
                  bindLabel="nameAndEmail"
                  placeholder="Select Assignee"
                  [(ngModel)]="assignee">
              </ng-select>
            </div>
        </div>
        <div class="row coa-execute-row " *ngIf="state === 'EXECUTE'  || state === 'SET_DUE_DATE'">
            <div class="col-md-4 coa-execute-label">Set Due  Date:</div>
            <div class="col-md-8">
                <input type="date" id="coa-execute-due-date" [(ngModel)]="dueDate" name="coa-execute-due-date" 
                [ngClass]="{'coa-execute-datepicker': state === 'EXECUTE', 'due-date-datepicker': state === 'SET_DUE_DATE'} ">
            </div>
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button class="btn btn-default" 
            [mat-dialog-close]="false" 
            *ngIf="state != 'SUCCESS' && state != 'ERROR'"
            style="margin-right:20px; width:100px;" >Cancel</button>
    <button class="btn btn-primary" 
        style="background-color:#286090; width: 100px;" 
        [mat-dialog-close]="true"
        *ngIf="state === 'SUCCESS'">Close</button>
    <button class="btn btn-primary"
           (click)="executeCourseOfAction()" 
           style="background-color:#286090; width: 100px;" 
           *ngIf="state === 'EXECUTE'"
           [disabled]="!(selectedProcessDefinition && assignee && dueDate)">Execute</button>
    <button class="btn btn-primary" 
           (click)="reassignCoa()" 
           style="background-color:#286090; width: 100px;" 
           *ngIf="state === 'REASSIGN'"
           [disabled]="!assignee">Assign</button>
    <button class="btn btn-primary" 
           (click)="updateDueDate()" 
           style="background-color:#286090; width: 100px;" 
           *ngIf="state === 'SET_DUE_DATE'"
           [disabled]="!dueDate">Set Due Date</button>
</mat-dialog-actions>