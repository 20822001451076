import { Component, Input, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import _cloneDeep from "lodash-es/cloneDeep";
import _isEqual from "lodash-es/isEqual";
import * as moment from "moment";

import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

import { EnvService } from "../../../app/env.service";
import { coa } from "../../shared/models/coa";
import { BulkAssignmentService } from "../../shared/services/bulk.assignment.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { TABLE_COLUMNS_COAS } from "../coa-list-columns";
import { CoAService } from "../coa.service";

import { MatDialog } from "@angular/material/dialog";
import { MatDialogCoaExecuteComponent } from "../../mat-dialog-coa-execute/mat-dialog-coa-execute.component";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-all-coa-list",
  templateUrl: "./all-coa-list.component.html",
  styleUrls: ["./all-coa-list.component.css"],
})
export class AllCoaListComponent implements OnInit {
  public pageTitle: "Courses of Action";
  public rows: coa[] = [];
  public original_rows: coa[] = [];
  public columns = [];
  public tableTitle: string = "List of All Courses of Action";
  public p: number = 1;
  public limitRowsOptions = [10, 25, 50, 100];
  public limitRows: number = this.limitRowsOptions[0];
  public tableInfo: string = "";
  public sortKey = "UpdatedDateTime";
  public asc = false;
  public loaded = false;
  public searchFilter: string = "";
  public term: string = "";
  public perm_accessCamunda = false;
  public perm_update_dueDate = false;
  public coaLabel = "COAs";

  public searcher: {} = {};
  public searcherSubject: Subject<any> = new Subject<any>();
  public lockFilters: boolean = false;
  public selectAll: boolean = false;
  public disableBulkAssign: boolean = false;
  public allFilteredIDs: string[] = [];
  public prevFilteredIDsCount = 0;
  public bulkAssignVisible: boolean = false;
  public totalRecords: number;
  public CourseOfActionColumns = TABLE_COLUMNS_COAS;
  @Input() public myStaff: boolean;
  public timeoutInterval: number = 5000;
  public loadRefreshLimit: number = 30;
  public continueToReload: boolean = true;
  public viewOnlyMyCoas: boolean = false;

  constructor(
    private permissions: PermissionService,
    private route: ActivatedRoute,
    private router: Router,
    public stixConfig: StixConfigService,
    private coaService: CoAService,
    private bulkAssignService: BulkAssignmentService,
    private userProfileService: UserProfileService,
    private env: EnvService,
    public dialog: MatDialog,
    public toastr: ToastrService
  ) { }

  public async ngOnInit() {

    // Fix bug where coa service holds on to filter value after changing page
    const routerClear = this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        this.coaService.resetCritiria();
        routerClear.unsubscribe();
      }
    });

    // RID-3382 check if coa visibility is turned on for Grants Management Contract
    await this.checkCoaVisibility();

    this.searcherSubject.pipe(debounceTime(700)).subscribe((params) => {
      this.coaService.modifyFilter(params.prop, params.string);
      if (this.viewOnlyMyCoas){
        this.coaService.modifyFilter("UpdatedBy", this.userProfileService.fullName);
      }
      this.p = 1;
      this.updateTable();
    });

    if (
      this.coaService.activeTab &&
      this.coaService.activeTab.prop == "myStaff"
    ) {
      this.tableTitle = "List of My Staff " + this.stixConfig.coa_plural;
    } else {
      this.tableTitle = "List of All " + this.stixConfig.coa_plural;
    }

    this.coaLabel = this.stixConfig.coa_plural;

    this.permissions.getPermission("Access Camunda").then((p) => {
      this.perm_accessCamunda = p;
    });
    this.permissions
      .getPermission("Update Course of Action Due Date")
      .then((p) => {
        this.perm_update_dueDate = p;
      });
    this.permissions
      .getPermission("Bulk Re-assign Course of Action")
      .then((permission) => {
        if (permission && this.env.bulkAssignment) {
          this.bulkAssignVisible = true;
        }
      });

    this.loaded = false;
    this.columns = this.stixConfig.stixLabeling(this.CourseOfActionColumns);
    this.totalRecords = null;
    this.adjustSightingCritiria();
    this.updateTable();
  }

  public timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public loadCoursesOfAction = async () => {
    // Get the current criteria to know what we are currently searching
    const currentCriteria = _cloneDeep(this.coaService.critiria);

    for (let retries = 0; retries < this.loadRefreshLimit; retries++) {
      // Get the latest Courses of action and set the table
      this.fetchCoursesOfActions();

      // if the criteria has changed don't load it continue to refresh
      if (!_isEqual(this.coaService.critiria, currentCriteria)) {
        break;
      } else {
        await this.timeout(this.timeoutInterval);
        this.fetchCoursesOfActions();
      }
    }
  }
  public ngOnDestroy(): void {
    this.loadRefreshLimit = 0;
  }

  public checkCoaVisibility = async (): Promise<any> => {
    this.permissions.getPermission("View my Courses of Action")
    .then((permission) => {
        this.viewOnlyMyCoas = permission;
    })
  }

  public fetchCoursesOfActions() {
    if (this.viewOnlyMyCoas){
      this.coaService.critiria.filter = {...this.coaService.critiria.filter, UpdatedBy: this.userProfileService.fullName};
    }
    const managerEmail = this.myStaff ? this.userProfileService.userEmail : "";
    this.coaService.coaFilter(this.coaService.critiria, managerEmail).subscribe(
      (response) => {
        this.totalRecords = response.total;
        const formattedCoursesOfAction = response.coas || [];
        this.allFilteredIDs = response.instanceIds;

        /* Turn (Select all) checkbox to false If user is removing filters and getting more records than previous. */
        if (
          (this.prevFilteredIDsCount &&
            this.prevFilteredIDsCount < this.allFilteredIDs.length) ||
          this.allFilteredIDs.length == 0
        ) {
          this.selectAll = false;
        }
        this.prevFilteredIDsCount = this.allFilteredIDs.length;

        this.rows = formattedCoursesOfAction.map((coa) => {
          coa.selected =
            (this.coaService.selected_COAs.indexOf(coa.instanceId) != -1 &&
              !this.isWorkflowClosed(coa)) ||
            false;
        });

        /* send back a list of props that have date ISO format values for server filter. */
        const firstCOA = formattedCoursesOfAction[0] || {};
        Object.keys(firstCOA).forEach((prop) => {
          const propertyData = this.columns.find((x) => x.prop === prop);
          if (propertyData) {
            const isISOFormat =
              firstCOA[prop] &&
              moment(firstCOA[prop], moment.ISO_8601, true).isValid() &&
              firstCOA[prop].indexOf(":") != -1;
            if (firstCOA[prop] && isISOFormat) {
              if (this.coaService.dateFormatFields.indexOf(prop) == -1) {
                this.coaService.dateFormatFields.push(prop);
              }
            }
          }
        });

        /* pick only (the selected threatactors which also are included in the current filter records) for Bulk Assignment. */
        this.disableBulkAssign = !this.coaService.selected_COAs.some((coaId) =>
          this.allFilteredIDs.includes(coaId),
        );

        this.rows = formattedCoursesOfAction;

        if (
          this.coaService.activeTab &&
          this.coaService.activeTab.prop == "myStaff"
        ) {
          this.tableTitle =
            "List of My Staff " +
            this.stixConfig.coa_plural +
            " (" +
            this.totalRecords +
            ")";
        } else {
          this.tableTitle =
            "List of All " +
            this.stixConfig.coa_plural +
            " (" +
            this.totalRecords +
            ")";
        }

        this.original_rows = this.rows;
        this.updateTabelInfo();
        this.loaded = true;
      },
      (err) => {
        this.rows = [];
        this.totalRecords = 0;
        this.updateTabelInfo();
        this.loaded = true;
        this.disableBulkAssign = true;
      },
    );
  }

  public itemClick(columnName, item) {
    if (columnName === "viewHistory") {
      this.router.navigate([item.instanceId], { relativeTo: this.route });
    }

    if (columnName === "taId") {
      const queryParams = { queryParams: { sighting: item.sightingName } };
      this.router.navigate(["threatActor", item.threatActorId], queryParams);
    }

    if (
      columnName == "instanceUIId" &&
      !this.isWorkflowClosed(item) &&
      this.perm_accessCamunda
    ) {
      this.router.navigate(["task", item.instanceId], {
        relativeTo: this.route,
      });
    }
    if (
      columnName == "coaDueDate" &&
      !this.isWorkflowClosed(item) &&
      this.perm_update_dueDate
    ) {
      const dialogRef = this.dialog.open(MatDialogCoaExecuteComponent, {
        data: {
          courseOfAction: item
        }
      });

      dialogRef.afterClosed().subscribe( dueDateResponse => {

        if ( dueDateResponse ){
          if (dueDateResponse.status === "SUCCESS"){
            item.coaDueDate = dueDateResponse.coaDueDate;
            let date = new Date(dueDateResponse.coaDueDate);
            let datePipe = new DatePipe("en-US");
            let formattedDate = datePipe.transform(date.toISOString().substring(0, date.toISOString().length - 1), 'M/dd/yyyy');
            this.toastr.success(  `Due Date successfully updated to ${formattedDate}` );
          }
          else {
            this.toastr.error('Failed to update due date');
          }
        }
      });
    }
  }

  public updateTabelInfo() {
    const startRange = this.totalRecords
      ? this.limitRows * (this.p - 1) + 1
      : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.totalRecords ? this.totalRecords : endRange;
    this.tableInfo =
      "Showing " +
      startRange +
      " to " +
      endRange +
      " of " +
      this.totalRecords +
      " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTable();
  }

  public getHoverTitle(prop, item) {
    if (
      prop == "instanceUIId" &&
      !this.isWorkflowClosed(item) &&
      this.perm_accessCamunda
    ) {
      return "Click to open " + this.stixConfig.coa_singular + " Current Task";
    }
    if (prop == "viewHistory") {
      return "Click to view " + this.stixConfig.coa_singular + " History";
    }
    if (
      prop == "coaDueDate" &&
      !this.isWorkflowClosed(item) &&
      this.perm_update_dueDate
    ) {
      return "Click to edit " + this.stixConfig.coa_singular + " Due Date";
    }
    if (prop == "taId") {
      return "Click to open " + this.stixConfig.threatActor_singular;
    }
  }

  public updateTable() {
    this.coaService.critiria.page = Number(this.p);
    this.coaService.critiria.count = Number(this.limitRows);
    this.coaService.critiria.sort = null;
    const sortObj = {};
    sortObj[this.sortKey] = this.asc ? "asc" : "desc";
    this.coaService.critiria.sort = sortObj;
    this.loadCoursesOfAction();
  }

  public bulkReAssignCOA() {
    if (this.coaService.critiria.filter) {
      this.bulkAssignService.selectedCOAs = this.coaService.selected_COAs.filter(
        (id) => this.allFilteredIDs.includes(id),
      );
    } else {
      this.bulkAssignService.selectedCOAs = this.coaService.selected_COAs;
    }

    this.bulkAssignService.coaView = true;
    if (this.bulkAssignService.selectedCOAs.length)
      this.router.navigate(["bulkassignment"], { relativeTo: this.route });
  }

  public filterChange(prop, string) {
    if (this.searcher && Object.keys(this.searcher).length <= 4) {
      this.searcherSubject.next({ prop, string });
    }
    if (!string) {
      delete this.searcher[prop];
    }
  }

  public filterClick(prop) {
    /* lock other filter fields if exceeded 4 filters at a time. */
    if (!this.searcher[prop] && Object.keys(this.searcher).length >= 4) {
      this.lockFilters = true;
      alert("You have exceeded the maximum number of filters");
    } else {
      this.lockFilters = false;
    }
  }

  public selectAllChange() {
    this.rows.forEach((element) => {
      /* If its closed COA; remove it from all Filtered IDs */
      const id_index = this.allFilteredIDs.indexOf(element.instanceId);
      if (!this.isWorkflowClosed(element)) {
        id_index == -1 && this.allFilteredIDs.push(element.instanceId);
        element.selected = this.selectAll || false;
      } else {
        id_index > -1 && this.allFilteredIDs.splice(id_index, 1);
      }
    });

    if (this.selectAll) {
      let selected_COAs = this.coaService.selected_COAs;
      selected_COAs = selected_COAs.concat(this.allFilteredIDs);
      this.coaService.selected_COAs = [...new Set(selected_COAs)];
    } else {
      this.allFilteredIDs.forEach((instanceId) => {
        const index = this.coaService.selected_COAs.indexOf(instanceId);
        if (index !== -1) {
          this.coaService.selected_COAs.splice(index, 1);
        }
      });
    }

    this.disableBulkAssign = !this.coaService.selected_COAs.some((coaId) =>
      this.allFilteredIDs.includes(coaId),
    );
  }

  public selectChange(item) {
    if (item.selected == true) {
      this.coaService.selected_COAs.push(item.instanceId);
    } else {
      const index = this.coaService.selected_COAs.indexOf(item.instanceId);
      if (index !== -1) {
        this.coaService.selected_COAs.splice(index, 1);
      }
    }
    this.disableBulkAssign = !this.coaService.selected_COAs.some((coaId) =>
      this.allFilteredIDs.includes(coaId),
    );
  }

  public adjustSightingCritiria() {
    /* re-apply the same filter critiria if the same tab */
    if (this.coaService.prevTab == "allCOAs") {
      this.p = this.coaService.critiria.page;
      this.limitRows = this.coaService.critiria.count;
      const sortObj = this.coaService.critiria.sort || {};
      this.sortKey = Object.getOwnPropertyNames(sortObj)[0] || this.sortKey;
      this.asc = sortObj[this.sortKey] == "asc";
      if (this.coaService.critiria.filter) {
        const filter = this.coaService.critiria.filter;
        Object.keys(filter).forEach((prop) => {
          this.searcher[prop] = filter[prop];
        });
      }
    } else {
      /* reset the filter */
      this.coaService.resetCritiria();
      this.coaService.prevTab = "allCOAs";
    }
  }

  public resetCheckboxes_refreshList() {
    this.coaService.selected_COAs = [];
    this.disableBulkAssign = true;
    this.selectAll = false;
    this.loadCoursesOfAction();
  }

  public isWorkflowClosed(coa: any) {
    return coa.workflowComplete;
  }

  public getAggregateFilterByText() {
    if (this.coaService.critiria.filter) {
      const filter = this.coaService.critiria.filter;
      if (filter.hasOwnProperty("overDueDate")) {
        return "(Filtered by Overdue Date)";
      }
      if (filter.hasOwnProperty("workflowComplete")) {
        return (
          "(Filtered by " +
          (filter.workflowComplete ? "Closed " : "Open ") +
          "Status)"
        );
      }
    }
    return "";
  }
}