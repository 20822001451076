import { Component, Input, ViewChild, ElementRef } from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { merge } from "rxjs";
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

import { 
  DataEntryListService,
  DataEntryListDataSource,
  DataEntry
 } from '../data-entry-list/data-entry-list.service';
import { StixConfigService } from "../shared/services/stixConfig.service";
import { SearchService } from '../search/search.service';
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'data-entry-grid',
  templateUrl: './data-entry-grid.component.html',
  styleUrl: './data-entry-grid.component.css'
})
export class DataEntryGridComponent {

  @Input() formName: string;

  private columnSort: any;
  private resultsPerPage: number;
  private pageNumber: number;
  private fields = { dataentryform : 0 }

  public paginationLength: number;
  public dataSource: DataEntryListDataSource;
  public dataEntries : DataEntry[];

  public columnsToDisplay: string[];
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild('input', {static: false}) input: ElementRef;
  public dataEntryOrderDesc: boolean;
  public dataEntrySortColumn: string;
  public customColumns : Array<any> = [];

  searchControl = new FormControl('');

  constructor( private dataEntryListService: DataEntryListService, 
    public stixConfig: StixConfigService,
    private router: Router,
    private searchService: SearchService

  ) { 
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        // Perform desired action or API call with the debounced and distinct search term
        this.paginator.pageIndex = 0;
        this.loadDataEntryList();
      });
  }

  async ngOnInit(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const urlSort = urlParams.get('sort');
    const urlResultsPerPage = urlParams.get('resultsPerPage')
    const urlPageNumber = urlParams.get('pageNumber');


    this.sort = urlSort && this.dataEntryListService.IsJsonString( urlSort ) ? JSON.parse( urlSort ) : {};
    this.resultsPerPage =  urlResultsPerPage && !Number.isNaN( urlResultsPerPage ) ? parseInt( urlResultsPerPage ) : 10;
    this.pageNumber = urlPageNumber && !Number.isNaN( urlPageNumber ) ? parseInt( urlPageNumber ) : 0;

    this.customColumns = await this.searchService.filterObservableMetaData( {type: this.formName, displayOnDataEntry : true }).toPromise();
    const customColumnNames = this.customColumns.map( x => x.name );
    this.columnsToDisplay = [ "dataEntryId", "taId", "threatActorName"].concat(customColumnNames).concat(["status", "formattedUpdatedDateTime", "updatedByFullName" ]);

    this.dataSource = new DataEntryListDataSource( this.dataEntryListService );
    this.dataSource.loadingDataEntryList( this.formName, "", customColumnNames.join(","), this.columnSort, this.pageNumber, this.resultsPerPage, this.fields );
    this.dataSource.count$.subscribe( count => this.paginationLength = count );
  }

  public ngAfterViewInit(): void {

    // Whenver there is a sort set the back to 0
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // When the user changes the sort or the page load cases
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {

          this.loadDataEntryList();
        })
      )
      .subscribe();

  }

  public refreshList() {
    this.input.nativeElement.value = null;
    this.loadDataEntryList();
  }

  /**
   * Run the search for cases based on the selected criteria
   */
  public loadDataEntryList(): void {
    const columnSort = {};
    columnSort [ this.sort.active ] = this.sort.direction === 'asc' ? 1 : -1;
    this.columnSort = columnSort;

    const matchString = this.searchControl.value ? this.searchControl.value.toLowerCase() : this.searchControl.value ;

    this.dataSource.loadingDataEntryList( this.formName,
                                            matchString, 
                                            this.customColumns && this.customColumns.length ? this.customColumns.map( x => x.name ).join(',') : "",
                                            this.columnSort, 
                                            this.paginator.pageIndex, 
                                            this.paginator.pageSize, 
                                            this.fields );
  }

  public getDataEntryListColumnName(column: string) {
  
    let descriptiveColumnName = "";

    // Check to see if the column is one of the custom columns 
    const foundCustomColumn = this.customColumns.find( x => x.name === column );
    if ( foundCustomColumn )
    {
      descriptiveColumnName = foundCustomColumn.description ?  foundCustomColumn.description : foundCustomColumn.name;
    }
    else {
      switch (column) {
        case "dataEntryId": 
          descriptiveColumnName = `${this.stixConfig.dataEntry_singular} ID`;
          break;
        case "name": 
          descriptiveColumnName = `${this.stixConfig.dataEntry_singular} Name`;
          break;
        case "taId": 
          descriptiveColumnName = `${this.stixConfig.threatActor_singular} ID`;
          break;
        case "threatActorName": 
          descriptiveColumnName = `${this.stixConfig.threatActor_singular} Name`;
          break;
        case "status": 
          descriptiveColumnName = "Status";
          break;
        case "formattedUpdatedDateTime": 
          descriptiveColumnName = "Last Modified";
          break;
        case "updatedByFullName": 
          descriptiveColumnName = "Entered By";
          break;
        // TODO These fields are Silver Stay specific, need to be updated to be dynamic
        // case "priorityscore": return "Priority";
        // case "userBin" : return "Bin";
        // case "primaryReferralSourceFacilityName": return "Referral Facility";
      }
    }

    return descriptiveColumnName;
  }

  public getDataEntryColumnType(column: string): string {
    switch (column) {
      case "dataEntryId":
      case "taId":
        return "link";
      default:
        return "text";
    }
  }

  public getGridLink(column: string, row: DataEntry) {
    switch( column ){
      case "taId": 
        return `/threatActor/${row.threatActorId}`
      case "dataEntryId":
        return `/data-entry/${row.name}/${row.dataEntryId}`;
    }
  }


  IsJsonString(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  redirectToAddNewDataEntry(){
    this.router.navigate(["/data-entry" ]);
  }
}
