
<div class="container-fluid">
    <!-- <div class="row data-entry-add-new-row">
        <div class="col-md-4">
            
        </div>
    </div> -->
    <div class="row data-entry-tab-row">
        <div class="col-md-10">
            <ul class="nav nav-tabs" *ngIf="dataEntryTabs.length > 0">
                <li class="nav-item" *ngFor="let dataEntryTab of dataEntryTabs; let i = index;" [ngClass]="{'active' : dataEntryTab.isActive}">
                    <a class="nav-link" (click)="getPage($event, i);">{{ dataEntryTab.formName }}</a>
                </li>
            </ul>
        </div>
        <div class="col-md-2 col-add-data-entry">
            <button class="btn btn-primary btn-add-data-entry" (click)="redirectToAddNewDataEntry()"><mat-icon class="data-entry-add" > add </mat-icon><span class="data-entry-add-new">Add New {{stixConfig.dataEntry_singular}}</span></button>
        </div>
    </div>
    <div *ngFor="let dataEntryTab of dataEntryTabs" > 
        @if( dataEntryTab.isActive ){
            <data-entry-grid formName="{{dataEntryTab.formName}}"></data-entry-grid>
        }
    </div>
</div>