<div *ngIf="error">
  <div class="alert alert-danger" role="alert">
    {{ error }}
  </div>
</div>

<div *ngIf="!error">
  <div style="text-align:center" *ngIf="isUploading">
    <h1> Uploading...</h1>
    <div style="padding:10px 0 0 45px;">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isUploading">
    <h1 *ngIf="!data.doc" mat-dialog-title>Upload File</h1>
    <h1 *ngIf="data.doc" mat-dialog-title>Update 
      <a href="{{data.doc.downloadUrl}}" target="_blank" class="coa-file-upload-link">
        {{ data.doc.name}}</a>
    </h1>

    <div *ngIf="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <div class="container coa-file-upload-container">
        <div class="row">
            <div class="col-md-3">
                File Description:
            </div>
            <div class="col-md-9">
                <textarea class="form-control" placeholder="Document Description" title="Document description" [(ngModel)]="uploadDescription" row="3"></textarea>
            </div>
        </div>


        <div class="row" style="margin-top: 20px;">
            <div class="col-md-3">
              Upload File:
            </div>
            <div class="col-md-9">
              <button class="btn btn-primary" (click)="csvInput.click()" style="float:left;width:20%;">Choose</button>
                <input class="form-control" placeholder="Click button to upload a file" title="Click button to upload a file" [(ngModel)]="uploadFileName"
                  disabled="true" (click)="csvInput.click()" style="float:left;width:80%;">
                <input #csvInput hidden="true" style="display:none" type="file" onclick="this.value=null" aria-label=" " aria-hidden="true"
                  (change)="fileSelected($event)"/>
                  <div *ngIf="data.doc">Please upload a file with the same name.</div>
              </div>
        </div>

        <div class="row" style="margin-top: 20px;">
          <div class="col-md-6"></div>
          <div class="col-md-3">
            <button class="btn btn-default" style="width:80%; float:right;" (click)="closeDialog()">Cancel</button>
          </div>
          <div class="col-md-3">
            <button class="btn btn-primary" style="width:80%; float:right;" (click)="fileUpload()" *ngIf="!hideUploadButton">Upload</button>
            <div *ngIf="hideUploadButton">Please wait...</div>
          </div>
        </div>
        
    </div>
  </div>
</div>