<h1 mat-dialog-title>
    Schedule {{data.indicator.name}} to execute
</h1>
<div class="schedule-view-container">
    <form name="form" (ngSubmit)="fm.form.valid" class="darker-form" #fm="ngForm" novalidate>
        <fieldset class="col-sm-10 m-l-xs" style="font-size: 15px;">
            <div class="form-group m-t-md">
                <div class="col-sm-5">
                    <label>Frequency:</label>
                </div>
                <div class="input-group col-sm-4">
                    <div *ngFor="let freq of frequencyValues">
                        <label for="frequency_{{freq}}">
                            <input id="frequency_{{freq}}" type="radio" name="frequency" required
                                [(ngModel)]="frequency" [value]="freq"> {{freq}}
                        </label>
                    </div>
                    <div *ngIf="fm.submitted && !frequency" class="alert alert-danger">
                        Frequency is required
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">
                <div class="col-sm-5">
                    <label for="setStartDate" title="Start date cannot be a past date.">Starting at Date:</label>
                </div>
                <div class="input-group col-sm-5">
                    <input class="form-control date-input" type="date" [(ngModel)]="startDate" #startDateIn="ngModel"
                        name="startDate" min="{{todaysDate}}" id="setStartDate"
                        title="Start date cannot be a past date."
                        [ngClass]="{ 'is-invalid': fm.submitted && (startDateIn.invalid || !startDate_valid)}" required
                        (change)="datesChanged()"/>
                    <div *ngIf="fm.submitted && (startDateIn.invalid || !startDate_valid)" class="alert alert-danger"
                        title="Start date cannot be a past date."
                        style="padding-top: 38px;padding-bottom: 7px;">
                        <div *ngIf="startDateIn.errors && startDateIn.errors.required">Start Date is required</div>
                        <div *ngIf="!startDate_valid">Start Date must be today or later</div>
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">
                <div class="col-sm-5">
                    <label for="setStartDate" title="Start time must be at least 10 minutes from now.">Starting at Time:</label>
                </div>
                <div class="input-group col-offset-sm-3 col-sm-6 time-pick">
                    <input type="time" id="appt" name="appt" [(ngModel)]="startTime" 
                        title="Start time must be at least 10 minutes from now."
                        style="height: 30px; width: 162px; margin-left:16px; border-color: #cfdadd; border-style:solid; border-width:1px; text-indent: 7px; padding-right:12px;">
                    <div *ngIf="fm.submitted && !(this.startTime)"
                        class="alert alert-danger" style="margin-left: 15px;margin-right: 15px;">
                        <div style="padding-top: 30px;">Start Time is required</div>
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">
                <div class="col-sm-5">
                    <label for="setUntilDate" title="End date must be the same as start date or later.">Until (End Date):</label>
                </div>
                <div class="input-group col-sm-5">
                    <input class="form-control date-input" type="date" [(ngModel)]="untilDate" #untilDateIn="ngModel"
                        name="untilDate" min="{{todaysDate}}" id="setUntilDate"
                        title="End date must be the same as start date or later."
                        [ngClass]="{ 'is-invalid': fm.submitted && (untilDateIn.invalid || !untilDate_valid) }"
                        (change)="datesChanged()"/>
                    <div *ngIf="fm.submitted && !untilDate_valid" class="alert alert-danger" 
                        title="End date must be the same as start date or later."
                        style="padding-top: 38px;padding-bottom: 7px;">
                        <div *ngIf="!untilDate_valid">End Date cannot be earlier than Start Date</div>
                    </div>
                </div>
            </div>
            <div class="form-group m-t-md">
                <div class="col-sm-5">
                    <label for="setUntilTime" title="End time must be at least 10 minutes from now if ending today.">Ending at Time:</label>
                </div>
                <div class="input-group col-offset-sm-3 col-sm-6 time-pick">
                    <input type="time" id="setUntilTime" name="untilTime" [(ngModel)]="endTime" 
                        title="End time must be at least 10 minutes from now if ending today."
                        style="height: 30px; width: 162px; margin-left:16px; border-color: #cfdadd; border-style:solid; border-width:1px; text-indent: 7px; padding-right:12px;">
                    <div *ngIf="fm.submitted && !(this.endTime)"
                        class="alert alert-danger" style="margin-left: 15px;margin-right: 15px;">
                        <div style="padding-top: 30px;">End Time is required</div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>

<mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary btn-algorithm schedule-cancel" [mat-dialog-close]="true">Cancel</button>
    <button type="submit" class="btn btn-primary btn-algorithm" (click)="saveSchedule()" [disabled]="!datesChanged()">Save</button>
</mat-dialog-actions>