
import { Component, Input, OnChanges } from "@angular/core";

import { coa } from "../../shared/models/coa";
import { EnvService } from "../../../app/env.service";
import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { UserProfileService } from "../../core/userProfile.service";
import { TABLE_COLUMNS_TA_COA_LIST } from "../../threat-actor/threat-actor-details/threat-actor-detail-columns";
import { CoAService } from "../coa.service";
import { MatDialogCoaExecuteComponent } from "../../mat-dialog-coa-execute/mat-dialog-coa-execute.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-coa-list-threat-actor",
  templateUrl: "./coa-list-threat-actor.component.html",
  styleUrls: ["./coa-list-threat-actor.component.css"],
})
export class CoaListThreatActorComponent implements OnChanges {

  public pageTitle: "Courses of Action";
  public coursesOfActions: coa[] = [];
  public rows: coa[] = [];
  public columns = [];
  public tableTitle: string;
  public p: number = 1;
  public limitRows: number = 5;
  public tableInfo: string = "";
  public sortKey = "UpdatedDateTime";
  public asc = false;
  public loaded = false;
  public sideTable = true;
  public term: string = "";
  public perm_accessCamunda = false;
  public perm_update_dueDate = false;
  public limitRowsOptions = [5, 10, 25, 50];
  public viewOnlyMyCoas: boolean = false;

  @Input() public threatActor;
  @Input() public sighting;

  constructor(private coaService: CoAService, 
              private userService: UserProfileService,
              private permissions: PermissionService,
              public stixConfig: StixConfigService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private env: EnvService) {
    this.tableTitle = this.stixConfig.coa_plural;
  }

  public ngOnChanges() {
    this.permissions.getPermission("Access Camunda").then((p) => { this.perm_accessCamunda = p; });
    this.permissions.getPermission("Update Course of Action Due Date").then((p) => { this.perm_update_dueDate = p; });

    this.fetchCoursesOfActions();
  }

  public async fetchCoursesOfActions() {
    this.loaded = false;
    this.columns = this.stixConfig.stixLabeling(TABLE_COLUMNS_TA_COA_LIST);
    await this.checkCoaVisibility();

    this.coaService.getCoursesOfActionByThreatActor_Sighting(this.threatActor.id, this.sighting.name).subscribe((s) => {
      const formattedCoursesOfAction = s.map((t) => {
        t.instance_Id = t.instanceId.substring(0, 8).toUpperCase(); /// <-- temp
        t.status = t.workflowComplete ? "Closed" : "Open";
        this.userService.addUserEmailToUsersListing(t.UpdatedBy);

        // Check to see we should get the status from the reference data in the course
        // of action instead of top-level status
        t.rivirStatus = t.status;
        if (this.env.courseOfActionStatus && t.tasks && t.tasks.length > 0) {
          t.tasks = t.tasks.sort( (b,a) => new Date(b.UpdatedDateTime).getTime()  - new Date(a.UpdatedDateTime).getTime()  );
          const lastTask = t.tasks[t.tasks.length - 1];
          
          // Set the status and Last Updated by from the last task because force close can cause the course of action
          // to have different values
          t.status = lastTask[this.env.courseOfActionStatus] ? lastTask[this.env.courseOfActionStatus] : t.status;
          t.UpdatedBy = lastTask.assigneeFullName;
        }
        return t;
      });

      this.coursesOfActions = formattedCoursesOfAction;
      if (this.viewOnlyMyCoas){
        this.rows = formattedCoursesOfAction.filter(item => (item.UpdatedBy == this.userService.fullName));
      }
      else{
        this.rows = formattedCoursesOfAction;
      }
      this.updateTabelInfo();

      this.tableTitle = this.stixConfig.coa_plural ? this.stixConfig.coa_plural : "Courses of Action";

      if (this.rows.length > 0) {
        this.tableTitle = this.tableTitle + " (" + this.rows.length + ")";
      }

      this.loaded = true;
    });
  }

  public itemClick(columnName, item) {

    if (columnName == "coaDueDate" && !item.workflowComplete && this.perm_update_dueDate) {

      const dialogRef = this.dialog.open(MatDialogCoaExecuteComponent, {
        data: {
          courseOfAction: item
        }
      });

      dialogRef.afterClosed().subscribe( dueDateResponse => {

        if ( dueDateResponse ){
          if (dueDateResponse.status === "SUCCESS"){
            item.coaDueDate = dueDateResponse.coaDueDate;
            let date = new Date(dueDateResponse.coaDueDate);
            let datePipe = new DatePipe("en-US");
            let formattedDate = datePipe.transform(date.toISOString().substring(0, date.toISOString().length - 1), 'M/dd/yyyy');
            this.toastr.success(  `Due Date successfully updated to ${formattedDate}` );
          }
          else {
            this.toastr.error('Failed to update due date');
          }
        }
      });
    }
  }

  public updateTabelInfo() {
    const startRange = this.rows.length ? this.limitRows * (this.p - 1) + 1 : 0;
    let endRange = this.limitRows * this.p;
    endRange = endRange > this.rows.length ? this.rows.length : endRange;
    this.tableInfo = "Showing " + startRange + " to " + endRange + " of " + this.rows.length + " entries";
  }

  public columnClick(columnName) {
    if (this.sortKey === columnName) {
      this.asc = !this.asc;
    }
    this.sortKey = columnName;
    this.p = 1;
    this.updateTabelInfo();
  }

  public getHoverTitle(prop, item) {
    if (prop == "instance_Id" && !item.workflowComplete && this.perm_accessCamunda) {
      return "Click to open " + this.stixConfig.coa_singular + " Current Task";
    }
    if (prop == "viewHistory") {
      return "Click to view " + this.stixConfig.coa_singular + " History";
    }
    if (prop == "coaDueDate" && !item.workflowComplete && this.perm_update_dueDate) {
      return "Click to edit " + this.stixConfig.coa_singular + " Due Date";
    }
  }

  public checkCoaVisibility = async (): Promise<any> => {
    this.permissions.getPermission("View my Courses of Action")
    .then((permission) => {
        this.viewOnlyMyCoas = permission;
    })
  }

  public formatDateForTZ(dateStr) {
    // CoaDueDate is saved as ISO String and needs to be converted to date object and normalized for local Time Zone
    let date = new Date(dateStr);
    return date.toISOString().substring(0, date.toISOString().length - 1);
  }

}