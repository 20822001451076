<div class="panel panel-default">
  <div class="panel-heading">
    {{tableTitle}}
    <a (click)="fetchThreatActorList()" class="refresh-list">
      <i class="fa fa-refresh"></i> Refresh List</a>
  </div>
  <div *ngIf="!loaded" class="loader">Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div *ngIf="loaded" class="table-responsive">
    <div class="dataTables_wrapper form-inline dt-bootstrap no-footer">
      <div class="row">
        <div class="col-sm-9">
          <div class="dataTables_length">
            <label>Show
              <select [(ngModel)]="limitRows" class="form-control input-sm" (change)="updateTable()">
                <option *ngFor="let num of limitRowsOptions" [value]="num">{{num}}</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="dataTables_filter pull-right m-r-sm">
            <label>Filter:
              <input id="filter_term" [(ngModel)]="term" class="form-control input-sm" type="search" (ngModelChange)="filterColumns()">
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table class="table table-striped b-t b-b dataTable no-footer" role="grid">
            <thead>
              <tr role="row">
                <th *ngFor="let column of columns" (click)="columnClick(column.prop)" class="{{'th-'+column.prop}} sorting{{sortKey == column.prop ? asc == true ? '_asc' : '_desc' : ''}}">
                  {{column.name}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of rows | paginate: { itemsPerPage: limitRows, currentPage: p, totalItems: totalRecords }"
                class="ignore-{{item['ignore']}}" role="row">
                <td *ngFor="let column of columns" class="{{'td-'+column.prop}}">
                  <span *ngIf="(item[column.prop] || item[column.prop] === 0 || column.prop == 'ignore')" (click)="itemClick(column.prop, item)"
                    title="{{getHoverTitle(column.prop, item)}}">{{column.prop == 'ignore'? '':item[column.prop]}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="dataTables_info">
            {{tableInfo}} </div>
        </div>
        <div class="col-sm-6">
          <pagination-controls (pageChange)="p = $event;updateTable()" class=" pull-right"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>