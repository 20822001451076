import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { StixConfigService } from "../../shared/services/stixConfig.service";
import { PermissionService } from "../../core/permission.service";
import { EnvService } from "../../env.service";

import { Indicator } from "../indicators-list/indicator";
import { CaseService } from "../../case/case-service";
import { IndicatorService } from "../indicators.service";

import { MatDialogCoaExecuteComponent  } from "../../mat-dialog-coa-execute/mat-dialog-coa-execute.component";
import { MatDialog } from "@angular/material/dialog";




@Component({
  selector: "app-indicators-details-threat-actor",
  templateUrl: "./indicators-details-threat-actor.component.html",
  styleUrls: ["./indicators-details-threat-actor.component.css"],
})
export class IndicatorsDetailsThreatActorComponent implements OnInit {

  public loaded = false;
  public selectedSighting;
  public indicators: Indicator[];
  public coa_flag: boolean;
  public permission_execute_CoA = false;
  @Input() public threatActor;
  @Input() public sightingFromRoute;
  public label_indicator: string;
  public label_coa: string;
  public label_sighting: string;
  public hasCaseManagement: boolean;

  constructor(
    private indicatorService: IndicatorService,
    private router: Router,
    private permissions: PermissionService,
    public stixConfig: StixConfigService,
    private env: EnvService,
    private caseService: CaseService,
    private dialog: MatDialog) { }

  public ngOnInit() {

    this.label_indicator = this.stixConfig.indicator_singular;
    this.label_coa = this.stixConfig.coa_singular;
    this.label_sighting = this.stixConfig.sighting_singular;

    this.hasCaseManagement = this.env.hasCaseManagement;


    // Get threat Actor's Indicators
    this.indicators = this.threatActor.indicators;
    if (this.indicators.length > 0) {
      this.indicators.forEach((indicator) => {
        indicator.sightings.forEach((sighting) => {
          sighting.runDate = this.displaySightingRunDate(sighting.name);
          sighting.indicatorTypes = sighting.types || indicator.types;
          sighting.indicatorId = indicator.name;
          sighting.indicatorName = sighting.description || indicator.description;
          sighting.recommendedCOAs = sighting.recommendedCOAs || indicator.coursesOfAction || [];
          sighting.classification = sighting.classifications || indicator.classifications;
        });
        indicator.sightings = indicator.sightings.reverse();
        indicator.collapse = true;
        indicator.lastRunDate = indicator.sightings && indicator.sightings.length ? indicator.sightings[0].CreatedDateTime : null;
      });

      // Sort Indicators from latest run dates.
      this.indicators.sort(function (a, b) {
        return new Date(b.lastRunDate).getTime() - new Date(a.lastRunDate).getTime();
      });

      this.selectSingleSighting(this.sightingFromRoute);
      this.fetchIndicatorsDetails();
    } else {
      this.loaded = true;
    }
    this.permissions.getPermission("Start Course Of Action").then((p) => { this.permission_execute_CoA = p; });
  }

  public fetchIndicatorsDetails() {
    this.indicatorService
      .getIndicatorDetail(this.selectedSighting.indicatorId)
      .subscribe(indicator => {
        this.selectedSighting.algoViewable = !!indicator[0];
      });
  }

  public sightingSelected(sighting) {
    this.loaded = false;
    this.selectedSighting = sighting;
    this.loaded = true;
  }

  public selectSingleSighting(sightingFromRoute) {
    // Select the first sighting as default
    if (sightingFromRoute) {
      let sighting = null;
      let index = 0;
      for (let i = 0; i < this.indicators.length; i++) {
        sighting = this.indicators[i].sightings.find((s) => s.name == sightingFromRoute);
        index = i;
        i = sighting ? this.indicators.length : i;
      }
      this.indicators[sighting ? index : 0].collapse = false;
      this.sightingSelected(sighting || this.indicators[0].sightings[0]);
    } else {
      this.indicators[0].collapse = false;
      this.sightingSelected(this.indicators[0].sightings[0]);
    }

  }

  public startCaseCOA() {
    this.router.navigate(["/case/courseofaction", this.threatActor.taId, this.selectedSighting.name]);
  }

  public async startCOA(coaName) {

    const payload = await this.caseService.getPayload( this.threatActor, coaName, this.selectedSighting.name );

    const dialogRef = this.dialog.open(MatDialogCoaExecuteComponent, {
        data: {
          recommendedCoa : this.selectedSighting.recommendedCOAs,
          payload
        }
      });

      dialogRef.afterClosed().subscribe( ()=> {
        // Force refresh by cloning the selected sighting
        this.selectedSighting = { ...this.selectedSighting };
      });
  }

  public goToIndicatorPage(indicatorId) {
    this.router.navigate(["/indicators", indicatorId]);
  }

  public displaySightingRunDate(sighting) {
    const i = sighting.indexOf("--");
    return i ? sighting.substring(i + 2) : sighting;
  }
}
