  
  <div class="row nav-button-row form-group" *ngIf="isWorkBookSelected && !showSelected">
    <div class="col-sm-5 col-12">
        <span class="indicator-title">{{ model.indicator.name }}</span>
        <span class="indicator-title-stepof-label">Selected {{ selectLabel }}: </span>
        <span class="indicator-title-label">{{workBookTitle}}</span>
    </div>
    <div class="col-sm-7 col-12 action-btn-col">
      <button type="button" (click)="clearSelectedClick()" class="btn btn-outline-secondary btn-indicator-nav">Close</button>
      <button type="button" (click)="selectClick()" class="btn btn-success btn-indicator-nav">Set Visual</button>
    </div>
  </div>

  <div *ngIf="!foundSelectedWorkbook && !noVisualSelected && model.indicator.visualizations">
    <div class="alert alert-danger" role="alert">
      Unable to find selected {{stixConfig.visualization_singular}} in Tableau
    </div>
  </div>


  <div *ngIf="isWorkBookSelected && !showSelected" class="content">
    <div id="vizContainer" style="display: flex; justify-content: center"></div>
  </div>

  <div class="container-fluid" *ngIf="model && !isWorkBookSelected">
    <indicator-wiz-navigation [showChangeButton]="!searchInitiated && selectedWorkBook && showSelected" [stepNumber]="3"  [parentLocalSaveFunction]="saveBoundCallback" [parentLocalChangeFunction]="changeBoundCallback"></indicator-wiz-navigation>
    <div *ngIf="!searchInitiated && selectedWorkBook && showSelected" class="row" style=" border-bottom: 1px solid #DCDCDC; padding-bottom: 17px;">

      <div class="col-md-2">Selected {{stixConfig.visualization_singular}}:</div>

      <div class="col-md-6"> 
          <mat-card class="example-card" style="width:325px; border: 2px solid #27b7e0" >
            <mat-card-header>
                <mat-card-title class="mat-card-size"></mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
             </mat-card-header>
            <mat-card-content>
                <div>
                    <div style="font-weight:bold;">{{this.selectedWorkBook.workbookName}} </div>
                    <img *ngIf="this.selectedWorkBook.previewImage" [src]="this.selectedWorkBook.previewImage" lt="Workbook Image" style="max-width: 300px;">
                    <div *ngIf="!this.selectedWorkBook.previewImage" class="preview-default-icons">
                        <div class="nw_icon">
                            <mat-icon fontSet="material-icons-outlined" >insights</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" >account_tree</mat-icon>
                        </div>
                        <div class="nw_icon">
                            <mat-icon fontSet="material-icons-outlined" >bar_chart</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" >pie_chart</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="this.selectedWorkBook.defaultView" style="padding-top: 10px;">
                        <div style="font-weight:bold;">Selected View:</div>
                        {{this.selectedWorkBook.defaultView}}
                    </div>
                </div>
            </mat-card-content>
          </mat-card>
      </div>

    </div>
  </div>

  <div class="row form-group justify-content-center header-label" *ngIf="searchInitiated || (!isWorkBookSelected && !showSelected)">
    <div class="container" style="display:flex">
        <div class="col-sm-11 text-center">
          <label class="header-label" for="description">Optional step. Search and choose a workbook as a {{label_visualization}} for this {{label_indicator}}.</label>
        </div>
    </div>
  </div>

  <div class="content add-margin" *ngIf="searchInitiated || (!isWorkBookSelected && !showSelected)">
    <div class="row form-group">
      <label class="control-label col-md-1" for="userSearch">Search:</label>
      <div class="col-md-11">
        <input type="text" id="userSearch" class="form-control input-styles" [(ngModel)]="searchItem" (keydown.enter)="getWorkbooks()" placeholder="Enter keywords"/>
      </div>
    </div>
  </div>
  
  <div *ngIf="!loaded" class="loader">
    Loading
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
    <span class="loader__dot">.</span>
  </div>
  <div class="container-fluid" *ngIf="searchInitiated || (!isWorkBookSelected && !showSelected)">
    <div class="row" *ngIf="loaded">
      <div class="col-sm-3" (click)="select(null);">
      <!-- <div class="col-sm-3" (click)="selected=selected==-1?0:-1"> -->
          <mat-card class="example-card no-viz" [ngClass]="{'selected': noVisualSelected}" > 
          <div class="icon-with-text">
            <mat-icon fontSet="material-icons-outlined">image_not_supported</mat-icon>
            <span>No Visual</span>
          </div>
        </mat-card>
        <br/>
        <br/>

      </div>
      <div class="col-sm-3" *ngFor="let i of allWorkBooks;let index = index">
        <mat-card [ngClass]="{ 'example-card': true, 'selected': isThisWorkbookSelected(i.workbookName)}" style="height:335px;" (click)="select(i)">
          <mat-card-header>
            <mat-card-title class="mat-card-size">

            </mat-card-title>
            <mat-card-content class="reporting-mat-content">
              <div >
                <img class="card-img-top dashboard-image" [src]="i.previewImage" lt="Workbook Image">
                <br/><br/>
                <b>{{i.workbookName}}</b>
                <br/>
                {{i.parentProject.name}}
                <br/>
                {{i.lastUpdatedDate}}
              </div>

            </mat-card-content>
            <mat-card-subtitle>
          </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <br/>
          </mat-card-content>
        </mat-card>
        <br/>
          <br/>
      </div>
    </div>
  </div>
