<ng-container *ngIf="caseNotFound">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="alert alert-warning" role="alert">
                Case <b>{{caseId}}</b> could not be found
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</ng-container>

<ng-container *ngIf="rivirCase">
    <mat-card>
        <mat-card-content>
            <ng-container>
                <div class="row case-detail-header-row">
                    <div
                        [ngClass]="hasLongThreatActorName ? 'col-md-4 case-detail-left-header' : 'col-md-5 case-detail-left-header'">
                        <span class="case-detail-header-text">Date Opened: {{ dateOpened }}</span>
                    </div>
                    <div [ngClass]="hasLongThreatActorName ? 'col-md-4' : 'col-md-2'">
                        <div class="case-detail-center-header">
                            {{stixConfig.threatActor_singular}}:
                            <span class="case-detail-header-text">
                                {{rivirCase.threatActorName}}
                            </span>
                        </div>
                        <div class="case-detail-center-header">
                            {{stixConfig.threatActor_singular}} Id:
                            <a routerLink="/threatActor/{{rivirCase.threatActorId}}" class="case-detail-header-link">
                                {{rivirCase.taId}}</a>
                        </div>
                    </div>
                    <div
                        [ngClass]="hasLongThreatActorName ? 'col-md-4 case-detail-right-header' : 'col-md-5 case-detail-right-header'">
                        <span class="case-detail-header-text">Case ID: {{rivirCase.caseId}}</span>
                    </div>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <label style="margin-bottom: 5px;" class="control-label">Due Date:
                            <input class="form-control date-input" type="date" [ngModel]="dueDate | date:'yyyy-MM-dd'"
                                (ngModelChange)="dueDate = $event" [disabled]="currentUserIsNotAssignee">
                            </label>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="margin-top:10px;">
                            <label style="margin-bottom: 5px;" class="control-label">Status:</label>
                            <select [(ngModel)]="rivirCase.status" class="form-control case-detail-status"
                                (change)="saveCase($event)" [disabled]="currentUserIsNotAssignee">
                                <option *ngFor="let status of caseStatuses" [ngValue]="status">
                                    {{status}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6" style="margin-top:10px;">
                            <label style="margin-bottom: 5px;" class="control-label">Priority:</label>
                            <select [(ngModel)]="rivirCase.priority" class="form-control case-detail-status"
                                (change)="saveCase($event)" [disabled]="currentUserIsNotAssignee">
                                <option *ngFor="let priority of priorities" [ngValue]="priority">
                                    {{priority}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="margin-top:10px;">
                            <label style="margin-bottom: 5px;" class="control-label">Assigned To:</label>
                            <select [(ngModel)]="rivirCase.assignee" class="form-control case-detail-status"
                                (change)="saveCase($event)">
                                <option *ngFor="let caseUser of activeUsers" [ngValue]="caseUser.id">
                                    {{caseUser.name}} -
                                    ({{caseUser.email}}) </option>
                            </select>
                        </div>
                        <div class="col-md-6" style="margin-top:10px;">
                            <label style="margin-bottom: 5px;" class="ng-select"><span style="display: hidden; visibility: hidden;">Select Types</span>
                            <ng-select [items]="typesList" [(ngModel)]="selectedTypes" placeholder="Select Types"
                                [multiple]="true" (change)="typeChanged()"
                                aria-label="Select types">
                            </ng-select>
                            </label>
                        </div>
                    </div>

                </div>
                <div class="row" style="margin-top:10px;">
                    <ul class="nav nav-tabs">
                        <li *ngIf="hasCoas" class="active case-detail-first-link">
                            <a data-toggle="tab" href="#caseDetailCourseOfActions">{{stixConfig.coa_plural}}</a>
                        </li>
                        <li [ngClass]="!hasCoas ? 'active case-detail-first-link' : ''">
                            <a data-toggle="tab" href="#caseDetailHistory" (click)="caseHistoryTabClicked($event)">Case
                                History</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#caseCOAHistory">{{stixConfig.coa_singular}} History</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div id="caseDetailCourseOfActions" *ngIf="hasCoas" class="tab-pane fade in active">
                            <case-coas [caseId]="rivirCase.caseId" [courseOfActions]="rivirCase.courseOfActions">
                            </case-coas>
                        </div>
                        <div id="caseDetailHistory" [ngClass]="!hasCoas ? 'tab-pane fade in active' : 'tab-pane fade'">
                            <case-history [caseId]="rivirCase.caseId" [caseSubject]="caseSubject"></case-history>
                        </div>
                        <div id="caseCOAHistory" class="tab-pane fade">
                            <case-coa-history [caseId]="rivirCase.caseId"></case-coa-history>
                        </div>
                    </div>

                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
</ng-container>